/* External dependencies */
import gql from 'graphql-tag';

export const createServiceMutation = gql`
  mutation createService($input: CreateServiceInput!) {
    createService(input: $input) {
      currency
      fees {
        flatFee {
          amount
        }
      }
      maxAmount
      minAmount
      logo {
        url
      }
      name_en
      name_ru
      name_ky
      parentId
      parents {
        id
        name_en
        name_ru
        name_ky
      }
      status
      id
      requiredFields {
        fieldId
        inputMask
        label_en
        label_ky
        label_ru
        maxLength
        minLength
        prefix
        keyboardType
        value
        orderNumber
      }
      serviceEndpoint {
        id
      }
      supportsDecimals
    }
  }
`;
