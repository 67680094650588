/* External dependencies */
import gql from 'graphql-tag';

export const blockAccountMutation = gql`
  mutation blockAccount($input: BlockAccountInput!) {
    blockAccount(input: $input) {
      id
    }
  }
`;
