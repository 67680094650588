/* Local dependencies */
import { TransactionsQueueMetricsQueueType } from './types';

export enum QueueMetricsActionTypes {
  QUEUE_METRICS_REQUEST = 'QUEUE_METRICS_REQUEST',
  QUEUE_METRICS_SUCCESS = 'QUEUE_METRICS_SUCCESS',
  QUEUE_METRICS_ERROR = 'QUEUE_METRICS_ERROR',
  QUEUE_METRICS_RESET_STATE = 'QUEUE_METRICS_RESET_STATE',
  QUEUE_METRICS_RESET_ERROR = 'QUEUE_METRICS_RESET_ERROR',
  REDRIVE_QUEUE_METRICS_REQUEST = 'REDRIVE_QUEUE_METRICS_REQUEST',
  REDRIVE_QUEUE_METRICS_SUCCESS = 'REDRIVE_QUEUE_METRICS_SUCCESS',
  REDRIVE_QUEUE_METRICS_ERROR = 'REDRIVE_QUEUE_METRICS_ERROR',
}

export interface QueueMetricsRequest {
  type: QueueMetricsActionTypes.QUEUE_METRICS_REQUEST;
}

export interface QueueMetricsSuccess {
  type: QueueMetricsActionTypes.QUEUE_METRICS_SUCCESS;
  values: TransactionsQueueMetricsResponse;
}

export interface TransactionsQueueMetricsResponse {
  dlqInProgressMessageCount?: string;
  dlqPendingMessageCount?: string;
  sqsInProgressMessageCount?: string;
  sqsPendingMessageCount?: string;
}

export interface QueueMetricsError {
  type: QueueMetricsActionTypes.QUEUE_METRICS_ERROR;
  error: Error;
}

export interface ResetQueueMetricsState {
  type: QueueMetricsActionTypes.QUEUE_METRICS_RESET_STATE;
}

export interface ResetQueueMetricsError {
  type: QueueMetricsActionTypes.QUEUE_METRICS_RESET_ERROR;
}

export interface RedriveQueueMetricsRequest {
  type: QueueMetricsActionTypes.REDRIVE_QUEUE_METRICS_REQUEST;
  queueType: TransactionsQueueMetricsQueueType;
}

export interface RedriveQueueMetricsSuccess {
  type: QueueMetricsActionTypes.REDRIVE_QUEUE_METRICS_SUCCESS;
  values: RedriveTransactionQueueResponse;
}

export interface RedriveQueueMetricsError {
  type: QueueMetricsActionTypes.REDRIVE_QUEUE_METRICS_ERROR;
  error: Error;
}

export interface RedriveTransactionQueueResponse {
  id: string;
}

export type QueueMetricsAction =
  | QueueMetricsRequest
  | QueueMetricsSuccess
  | QueueMetricsError
  | ResetQueueMetricsState
  | ResetQueueMetricsError
  | RedriveQueueMetricsRequest
  | RedriveQueueMetricsSuccess
  | RedriveQueueMetricsError;

export function getQueueMetrics(): QueueMetricsRequest {
  return {
    type: QueueMetricsActionTypes.QUEUE_METRICS_REQUEST,
  };
}

export function getQueueMetricsSucceeded(values: TransactionsQueueMetricsResponse): QueueMetricsSuccess {
  return {
    type: QueueMetricsActionTypes.QUEUE_METRICS_SUCCESS,
    values,
  };
}

export function getQueueMetricsFailed(error: Error): QueueMetricsError {
  return {
    type: QueueMetricsActionTypes.QUEUE_METRICS_ERROR,
    error,
  };
}

export function resetQueueMetrics(): ResetQueueMetricsState {
  return {
    type: QueueMetricsActionTypes.QUEUE_METRICS_RESET_STATE,
  };
}

export function resetQueueMetricsError(): ResetQueueMetricsError {
  return {
    type: QueueMetricsActionTypes.QUEUE_METRICS_RESET_ERROR,
  };
}

export function redriveQueueMetrics(queueType: TransactionsQueueMetricsQueueType): RedriveQueueMetricsRequest {
  return {
    type: QueueMetricsActionTypes.REDRIVE_QUEUE_METRICS_REQUEST,
    queueType,
  };
}

export function redriveQueueMetricsSucceeded(values: RedriveTransactionQueueResponse): RedriveQueueMetricsSuccess {
  return {
    type: QueueMetricsActionTypes.REDRIVE_QUEUE_METRICS_SUCCESS,
    values,
  };
}

export function redriveQueueMetricsFailed(error: Error): RedriveQueueMetricsError {
  return {
    type: QueueMetricsActionTypes.REDRIVE_QUEUE_METRICS_ERROR,
    error,
  };
}
