export type DistributionFeesActions =
  | ResetDistributionFees
  | ResetDistributionFeesError
  | ChangeDistributionFee
  | ValidateAllFields
  | SetDistributionFeesRequest
  | SetDistributionFeesFailure
  | SetDistributionFeesSuccesss;

export enum DistributionFeesActionTypes {
  CHANGE_DISTRIBUTION_FEE = 'CHANGE_DISTRIBUTION_FEE',
  VALIDATE_DISTRIBUTION_FEES_ALL_FIELDS = 'VALIDATE_DISTRIBUTION_FEES_ALL_FIELDS',
  SET_DISTRIBUTION_FEES_REQUEST = 'SET_DISTRIBUTION_FEES_REQUEST',
  SET_DISTRIBUTION_FEES_SUCCESS = 'SET_DISTRIBUTION_FEES_SUCCESS',
  SET_DISTRIBUTION_FEES_FAILURE = 'SET_DISTRIBUTION_FEES_FAILURE',
  RESET_DISTRIBUTION_FEE = 'RESET_DISTRIBUTION_FEE',
  RESET_DISTRIBUTION_FEE_ERROR = 'RESET_DISTRIBUTION_FEE_ERROR',
}

export interface ResetDistributionFees {
  type: DistributionFeesActionTypes.RESET_DISTRIBUTION_FEE;
}

export interface ResetDistributionFeesError {
  type: DistributionFeesActionTypes.RESET_DISTRIBUTION_FEE_ERROR;
}

export interface ChangeDistributionFee {
  type: DistributionFeesActionTypes.CHANGE_DISTRIBUTION_FEE;
  data: [string, any];
}

export interface ValidateAllFields {
  type: DistributionFeesActionTypes.VALIDATE_DISTRIBUTION_FEES_ALL_FIELDS;
}

export interface SetDistributionFeesRequest {
  type: DistributionFeesActionTypes.SET_DISTRIBUTION_FEES_REQUEST;
}

export interface SetDistributionFeesSuccesss {
  type: DistributionFeesActionTypes.SET_DISTRIBUTION_FEES_SUCCESS;
}

export interface SetDistributionFeesFailure {
  type: DistributionFeesActionTypes.SET_DISTRIBUTION_FEES_FAILURE;
  error: Error;
}

export function resetDistributionFees(): ResetDistributionFees {
  return {
    type: DistributionFeesActionTypes.RESET_DISTRIBUTION_FEE,
  };
}

export function resetDistributionFeesError(): ResetDistributionFeesError {
  return {
    type: DistributionFeesActionTypes.RESET_DISTRIBUTION_FEE_ERROR,
  };
}

export function setDistributionFeesFailed(error: Error): SetDistributionFeesFailure {
  return {
    type: DistributionFeesActionTypes.SET_DISTRIBUTION_FEES_FAILURE,
    error,
  };
}

export function setDistributionFeesSucceeded(): SetDistributionFeesSuccesss {
  return {
    type: DistributionFeesActionTypes.SET_DISTRIBUTION_FEES_SUCCESS,
  };
}

export function setDistributionFeesRequest(): SetDistributionFeesRequest {
  return {
    type: DistributionFeesActionTypes.SET_DISTRIBUTION_FEES_REQUEST,
  };
}

export function validateAllFields(): ValidateAllFields {
  return {
    type: DistributionFeesActionTypes.VALIDATE_DISTRIBUTION_FEES_ALL_FIELDS,
  };
}

export function changeDistributionFee(data: [string, any]): ChangeDistributionFee {
  return {
    type: DistributionFeesActionTypes.CHANGE_DISTRIBUTION_FEE,
    data,
  };
}
