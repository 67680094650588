// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { Currency, MoneyInput, ServiceFeeStepInput } from '../types';
import { ReducerType, ServiceFeesActionTypes, ServiceFeesActions } from './actions';
import { validateServiceFees } from './validation';

export interface ServiceFeesState {
  flatFee?: MoneyInput;
  flatFeePercent?: number;
  dynamicFeeSteps?: ServiceFeeStepInput[];
  errors: any;
  validationPassed: boolean;
  serviceType?: string;
}

export const feeTypes = {
  flatFee: {
    amount: null,
    currency: Currency.KGS,
  },
  flatFeePercent: '',
  dynamicFeeSteps: [
    {
      from: null,
      fee: { flatFee: { amount: null, currency: Currency.KGS } },
    },
  ],
};

export const serviceFeesInitialState: ServiceFeesState = {
  flatFee: feeTypes.flatFee,
  flatFeePercent: null,
  dynamicFeeSteps: null,
  errors: {
    flatFee: null,
    flatFeePercent: null,
    dynamicFeeSteps: [
      {
        from: null,
        fee: null,
      },
    ],
  },
  validationPassed: false,
};

function serviceFeesClosure(reducerType) {
  return function serviceFeesReducer(
    state: ServiceFeesState = {
      ...serviceFeesInitialState,
      errors: {
        ...serviceFeesInitialState.errors,
        dynamicFeeSteps: [...serviceFeesInitialState.errors.dynamicFeeSteps],
      },
    },
    action: ServiceFeesActions,
  ) {
    if (reducerType === action.reducerType) {
      switch (action.type) {
        case ServiceFeesActionTypes.SELECT_SERVICE_FEE_TYPE: {
          const { serviceType } = action;

          return update(state, {
            flatFee: { $set: null },
            flatFeePercent: { $set: null },
            dynamicFeeSteps: { $set: null },
            serviceType: { $set: serviceType },
            [serviceType]: { $set: feeTypes[serviceType] },
          });
        }

        case ServiceFeesActionTypes.ADD_DYNAMIC_FEE: {
          return validateServiceFees(
            update(state, {
              dynamicFeeSteps: { $push: feeTypes.dynamicFeeSteps },
            }),
          );
        }

        case ServiceFeesActionTypes.ADD_DYNAMIC_FEE_LIST: {
          return validateServiceFees(
            update(state, {
              dynamicFeeSteps: { $set: action.list },
            }),
          );
        }

        case ServiceFeesActionTypes.REMOVE_DYNAMIC_FEE: {
          if (state.dynamicFeeSteps.length > 1) {
            const dynamicFeeSteps = state.dynamicFeeSteps.filter((_, index) => {
              if (index !== action.index) {
                return true;
              }

              return false;
            });

            const dynamicFeeStepsErrors = state.errors.dynamicFeeSteps.filter((_, index) => {
              if (index !== action.index) {
                return true;
              }

              return false;
            });

            return validateServiceFees({
              ...state,
              dynamicFeeSteps,
              errors: { ...state.errors, dynamicFeeSteps: dynamicFeeStepsErrors },
            });
          }

          return state;
        }

        case ServiceFeesActionTypes.CHANGE_FLAT_FEE: {
          return validateServiceFees(
            update(state, {
              flatFee: { $set: action.flatFee },
              errors: { flatFee: { $set: null } },
            }),
          );
        }

        case ServiceFeesActionTypes.CHANGE_FLAT_FEE_PERCENT: {
          const { flatFeePercent } = action;

          return validateServiceFees(
            update(state, {
              flatFeePercent: { $set: flatFeePercent },
              errors: {
                flatFeePercent: {
                  $set: null,
                },
              },
            }),
          );
        }

        case ServiceFeesActionTypes.CHANGE_DYNAMIC_FEE: {
          const { fees, index } = action;
          const [updatePropertyName, updatePropertyValue] = fees;

          return validateServiceFees(
            update(state, {
              dynamicFeeSteps: {
                [index]: {
                  $set: update(state.dynamicFeeSteps[index], {
                    [updatePropertyName]: { $set: updatePropertyValue },
                  }),
                },
              },
              errors: {
                dynamicFeeSteps: {
                  [index]: {
                    $set: update(state.errors.dynamicFeeSteps[0], {
                      [updatePropertyName]: { $set: null },
                    }),
                  },
                },
              },
            }),
          );
        }

        case ServiceFeesActionTypes.VALIDATE_ALL_FIELDS: {
          const { errors, validationPassed } = validateServiceFees(state);

          return { ...state, errors, validationPassed };
        }

        case ServiceFeesActionTypes.RESET_SERVICE_FEES: {
          return {
            ...serviceFeesInitialState,
            errors: {
              ...serviceFeesInitialState.errors,
              dynamicFeeSteps: [...serviceFeesInitialState.errors.dynamicFeeSteps],
            },
          };
        }

        default: {
          return state;
        }
      }
    }

    return state;
  };
}

export const serviceUpperFees = serviceFeesClosure(ReducerType.UPPER_FEE);
export const serviceLowerFees = serviceFeesClosure(ReducerType.LOWER_FEE);
