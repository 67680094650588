// Local dependencies
import { Terminal, UpdateTerminalFields } from '../../types';

export enum UpdateTerminalActionType {
  UPDATE_TERMINAL_REQUEST = 'UPDATE_TERMINAL_REQUEST',
  UPDATE_TERMINAL_SUCCESS = 'UPDATE_TERMINAL_SUCCESS',
  UPDATE_TERMINAL_ERROR = 'UPDATE_TERMINAL_ERROR',
  UPDATE_TERMINAL_FIELDS = 'UPDATE_TERMINAL_FIELDS',
  UPDATE_TERMINAL_RESET = 'UPDATE_TERMINAL_RESET',
  UPDATE_TERMINAL_RESET_ERROR = 'UPDATE_TERMINAL_RESET_ERROR',
  GET_TERMINAL_REQUEST = 'GET_TERMINAL_REQUEST',
  GET_TERMINAL_SUCCESS = 'GET_TERMINAL_SUCCESS',
  GET_TERMINAL_ERROR = 'GET_TERMINAL_ERROR',
  SHOW_CONFIRMATION_POPUP = 'SHOW_CONFIRMATION_POPUP',
  HIDE_CONFIRMATION_POPUP = 'HIDE_CONFIRMATION_POPUP',
}

export interface GetTerminalId {
  id: string;
}

export interface GetTerminalRequest {
  type: UpdateTerminalActionType.GET_TERMINAL_REQUEST;
  id: string;
}

export interface GetTerminalSuccess {
  type: UpdateTerminalActionType.GET_TERMINAL_SUCCESS;
  terminal: Terminal;
}

export interface GetTerminalError {
  type: UpdateTerminalActionType.GET_TERMINAL_ERROR;
  error: Error;
}

export interface UpdateTerminalRequest {
  type: UpdateTerminalActionType.UPDATE_TERMINAL_REQUEST;
  terminal: Terminal;
}

export interface UpdateTerminalSuccess {
  type: UpdateTerminalActionType.UPDATE_TERMINAL_SUCCESS;
  terminal: Terminal;
}

export interface UpdateTerminalError {
  type: UpdateTerminalActionType.UPDATE_TERMINAL_ERROR;
  error: Error;
}

export interface UpdateTerminalFieldsInput {
  type: UpdateTerminalActionType.UPDATE_TERMINAL_FIELDS;
  updates: UpdateTerminalFields;
}

export interface UpdateTerminalReset {
  type: UpdateTerminalActionType.UPDATE_TERMINAL_RESET;
}

export interface UpdateTerminalResetError {
  type: UpdateTerminalActionType.UPDATE_TERMINAL_RESET_ERROR;
}

export interface ShowConfirmationPopup {
  type: UpdateTerminalActionType.SHOW_CONFIRMATION_POPUP;
}

export interface HideConfirmationPopup {
  type: UpdateTerminalActionType.HIDE_CONFIRMATION_POPUP;
}

export type UpdateTerminalActions =
  | UpdateTerminalRequest
  | UpdateTerminalSuccess
  | UpdateTerminalError
  | UpdateTerminalFieldsInput
  | UpdateTerminalReset
  | UpdateTerminalResetError
  | GetTerminalRequest
  | GetTerminalSuccess
  | GetTerminalError
  | ShowConfirmationPopup
  | HideConfirmationPopup;

export function updateTerminalRequest(terminal: Terminal): UpdateTerminalRequest {
  return {
    type: UpdateTerminalActionType.UPDATE_TERMINAL_REQUEST,
    terminal,
  };
}

export function updateTerminalSucceeded(terminal: Terminal): UpdateTerminalSuccess {
  return {
    type: UpdateTerminalActionType.UPDATE_TERMINAL_SUCCESS,
    terminal,
  };
}

export function updateTerminalFailed(error: Error): UpdateTerminalError {
  return {
    type: UpdateTerminalActionType.UPDATE_TERMINAL_ERROR,
    error,
  };
}

export function updateTerminalFields(updates: UpdateTerminalFields): UpdateTerminalFieldsInput {
  return {
    type: UpdateTerminalActionType.UPDATE_TERMINAL_FIELDS,
    updates,
  };
}

export function updateTerminalReset(): UpdateTerminalReset {
  return {
    type: UpdateTerminalActionType.UPDATE_TERMINAL_RESET,
  };
}

export function updateTerminalResetError(): UpdateTerminalResetError {
  return {
    type: UpdateTerminalActionType.UPDATE_TERMINAL_RESET_ERROR,
  };
}

export function getTerminal(id: string): GetTerminalRequest {
  return {
    type: UpdateTerminalActionType.GET_TERMINAL_REQUEST,
    id,
  };
}

export function getTerminalSucceeded(terminal: Terminal): GetTerminalSuccess {
  return {
    type: UpdateTerminalActionType.GET_TERMINAL_SUCCESS,
    terminal,
  };
}

export function getTerminalFailed(error: Error): GetTerminalError {
  return {
    type: UpdateTerminalActionType.GET_TERMINAL_ERROR,
    error,
  };
}

export function showConfirmationPopupTerminal(): ShowConfirmationPopup {
  return {
    type: UpdateTerminalActionType.SHOW_CONFIRMATION_POPUP,
  };
}

export function hideConfirmationPopupTerminal(): HideConfirmationPopup {
  return {
    type: UpdateTerminalActionType.HIDE_CONFIRMATION_POPUP,
  };
}
