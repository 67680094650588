/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { ValidationMessages } from '../helper';
import { CreateAnOrderValidRequiredException } from './exceptions';
import { CreateAnOrderState } from './reducer';

export function validateCreateAnOrderFields(state: CreateAnOrderState, action) {
  const { updates, helpersObj } = action;
  const { email, note, transactionId, totalWithoutComission, cardType, ...rest } = updates;

  const change: any = {
    isFormChanged: {
      $set: true,
    },
  };

  if (updates.hasOwnProperty('email')) {
    if (!email || (helpersObj && helpersObj?.error === ValidationMessages.NOT_VALID)) {
      change.emailError = {
        $set: {
          error: new CreateAnOrderValidRequiredException(),
          min: null,
          max: null,
        },
      };
    } else {
      change['$unset'] = ['emailError'];
    }

    return update(state, {
      ...change,
      servicePaymentPayload: {
        $set: update(state.servicePaymentPayload, {
          email: { $set: email },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('totalWithoutComission')) {
    return update(state, {
      ...change,
      totalWithoutComission: { $set: totalWithoutComission },
    });
  }

  if (updates.hasOwnProperty('transactionId')) {
    return update(state, {
      ...change,
      servicePaymentPayload: {
        $set: update(state.servicePaymentPayload, {
          transactionId: { $set: transactionId },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('cardType')) {
    return update(state, {
      ...change,
      servicePaymentPayload: {
        $set: update(state.servicePaymentPayload, {
          cardType: { $set: cardType },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('note')) {
    return update(state, {
      ...change,
      servicePaymentPayload: {
        $set: update(state.servicePaymentPayload, {
          note: { $set: note },
        }),
      },
    });
  }

  for (const key in rest) {
    let value = rest[key];

    const dynamicErrorName = key + 'Error';

    if (helpersObj && helpersObj?.error === ValidationMessages.NOT_VALID) {
      change[dynamicErrorName] = {
        $set: {
          error: new CreateAnOrderValidRequiredException(),
          min: helpersObj?.minLength,
          max: helpersObj?.maxLength,
        },
      };
    } else {
      change['$unset'] = [dynamicErrorName];
    }

    return update(state, {
      ...change,
      servicePaymentPayload: {
        $set: update(state.servicePaymentPayload, {
          [key]: { $set: value },
        }),
      },
    });
  }

  return state;
}
