/*External dependencies*/
import { debounceTime, filter, switchMap } from 'rxjs/operators';

/*Local dependencies*/
import { getClient } from '../../../clients/averspay';
import { cleanPayload } from '../../common/helpers';
import {
  ListServiceFees,
  ListServiceFeesAction,
  ListServiceFeesActionTypes,
  ListServiceFeesSuccess,
  listServiceFeesFailed,
  listServiceFeesSucceeded,
} from './actions';
import { listServiceFeesQuery } from './queries';

export default function listServiceFeesEpic(action$, state$) {
  return action$.pipe(
    filter((action: ListServiceFeesAction) => action.type === ListServiceFeesActionTypes.LIST_SERVICE_FEES_REQUEST),
    debounceTime(500),
    switchMap((action: ListServiceFees) => {
      return listServiceFees(action, state$.value.listServiceFees.size).catch(listServiceFeesFailed);
    }),
  );
}

export async function listServiceFees(
  { filter = {}, currentPage }: ListServiceFees,
  size: number,
): Promise<ListServiceFeesSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listServiceFees: { serviceFees, total },
    },
  } = await graphQLClient.query({
    query: listServiceFeesQuery,
    variables: {
      input: {
        filter: cleanPayload(filter),
        from: (currentPage - 1) * size,
        size,
      },
    },
  });

  return listServiceFeesSucceeded(total, serviceFees);
}
