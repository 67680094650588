/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { ListAccountsFilter } from '../listAccounts/actions';
import { Account } from '../listAccounts/types';
import { ListRootAccountsAction, ListRootAccountsActionTypes, ROOT_ACCOUNTS_FILTER } from './actions';

export interface RootAccountsState {
  currentPage: number;
  error?: Error;
  loading?: boolean;
  rootAccounts: Account[];
  filter?: ListAccountsFilter;
  searchString: string;
  selectedRootAccount?: any;
  size: number;
  total: number;
}

const size: number = 20;

export const initialRootAccountsState: RootAccountsState = {
  rootAccounts: [],
  currentPage: 1,
  filter: ROOT_ACCOUNTS_FILTER,
  loading: true,
  searchString: '',
  size,
  total: 0,
};

export default function rootAccountsReducer(state = initialRootAccountsState, action: ListRootAccountsAction) {
  switch (action.type) {
    case ListRootAccountsActionTypes.LIST_ROOT_ACCOUNTS_REQUEST:
      const { currentPage, searchString, filter } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        currentPage: { $set: currentPage },
        filter: { $set: filter },
        searchString: { $set: searchString },
      });

    case ListRootAccountsActionTypes.LIST_ROOT_ACCOUNTS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        rootAccounts: { $set: action.rootAccounts },
        total: { $set: action.total },
      });

    case ListRootAccountsActionTypes.LIST_ROOT_ACCOUNTS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case ListRootAccountsActionTypes.SELECT_ROOT_ACCOUNT:
      return update(state, {
        selectedRootAccount: { $set: action.selectedRootAccount },
      });

    case ListRootAccountsActionTypes.RESET_ROOT_ACCOUNTS_STATE:
      return update(state, { $set: initialRootAccountsState });

    case ListRootAccountsActionTypes.RESET_ERROR:
      return update(state, { $unset: ['error'] });

    default:
      return state;
  }
}
