// External dependencies
import { filter, switchMap } from 'rxjs/operators';

// Local dependencies
import { getClient } from '../../../../clients/averspay';
import { getItemFromLocalStorage } from '../../../common/helpers';
import { FeeEntity } from '../../serviceFees/types';
import {
  DistributionFeesActionTypes,
  DistributionFeesActions,
  setDistributionFeesFailed,
  setDistributionFeesSucceeded,
} from './actions';
import { setDistributionFeesMutation } from './mutation';

export default function setDistributionFeesEpic(actions$, state$) {
  return actions$.pipe(
    filter(
      (action: DistributionFeesActions) => action.type === DistributionFeesActionTypes.SET_DISTRIBUTION_FEES_REQUEST,
    ),
    switchMap(() => {
      const { selectedUsers } = state$.value.users;
      const { selectedTerminals, entityType } = state$.value.terminals;
      const {
        distributionFees: { clientFeePercentage, serviceRewardPercentage },
      } = state$.value.distributionFees;

      const services = JSON.parse(getItemFromLocalStorage('selectedServices'));

      const feeEntity = {
        [FeeEntity.DEALER]: selectedUsers,
        [FeeEntity.TERMINAL]: selectedTerminals,
        [FeeEntity.SERVICE]: [],
      };

      const selectedItems = feeEntity[entityType];

      const distributionFees = selectedItems?.reduce((acc, item) => {
        return acc.concat(
          services.map((service) => {
            return {
              id: item.id,
              serviceId: service.id,
              clientFeePercentage,
              serviceRewardPercentage,
              entityType,
            };
          }),
        );
      }, []);

      if (!distributionFees?.length) {
        const distributionFees = services.map((service) => {
          return {
            id: service.feeId || 'default',
            serviceId: service.id,
            clientFeePercentage,
            serviceRewardPercentage,
            entityType,
          };
        });

        return setDistributionFees(distributionFees)
          .then(setDistributionFeesSucceeded)
          .catch(setDistributionFeesFailed);
      }

      return setDistributionFees(distributionFees).then(setDistributionFeesSucceeded).catch(setDistributionFeesFailed);
    }),
  );
}

async function setDistributionFees(data) {
  const graphQLClient = await getClient();

  await graphQLClient.mutate({
    mutation: setDistributionFeesMutation,
    variables: {
      input: data,
    },
  });

  return {};
}
