// Local dependencies
import { ServiceFields, ServiceRecipientInput } from './types';

export enum GetServiceRecipientActionTypes {
  GET_SERVICE_RECIPIENT_REQUEST = 'GET_SERVICE_RECIPIENT_REQUEST',
  GET_SERVICE_RECIPIENT_SUCCESS = 'GET_SERVICE_RECIPIENT_SUCCESS',
  GET_SERVICE_RECIPIENT_ERROR = 'GET_SERVICE_RECIPIENT_ERROR',
  RESET_GET_SERVICE_RECIPIENT = 'RESET_GET_SERVICE_RECIPIENT',
}

export type GetServiceRecipientAction =
  | GetServiceRecipientRequest
  | GetServiceRecipientSuccess
  | GetServiceRecipientError
  | ResetGetServiceRecipient;

export interface GetServiceRecipientRequest {
  type: GetServiceRecipientActionTypes.GET_SERVICE_RECIPIENT_REQUEST;
  service: string;
  fields: ServiceFields;
}

export interface GetServiceRecipientSuccess {
  type: GetServiceRecipientActionTypes.GET_SERVICE_RECIPIENT_SUCCESS;
  serviceRecipientData: ServiceRecipientInput;
}

export interface GetServiceRecipientError {
  type: GetServiceRecipientActionTypes.GET_SERVICE_RECIPIENT_ERROR;
  error: Error;
}

export interface ResetGetServiceRecipient {
  type: GetServiceRecipientActionTypes.RESET_GET_SERVICE_RECIPIENT;
}

export function getServiceRecipient(service: string, fields: ServiceFields): GetServiceRecipientRequest {
  return {
    type: GetServiceRecipientActionTypes.GET_SERVICE_RECIPIENT_REQUEST,
    service,
    fields,
  };
}

export function getServiceRecipientSucceeded(serviceRecipientData: ServiceRecipientInput): GetServiceRecipientSuccess {
  return {
    type: GetServiceRecipientActionTypes.GET_SERVICE_RECIPIENT_SUCCESS,
    serviceRecipientData,
  };
}

export function getServiceRecipientFailed(error: Error): GetServiceRecipientError {
  return {
    type: GetServiceRecipientActionTypes.GET_SERVICE_RECIPIENT_ERROR,
    error,
  };
}

export function resetGetServiceRecipient(): ResetGetServiceRecipient {
  return {
    type: GetServiceRecipientActionTypes.RESET_GET_SERVICE_RECIPIENT,
  };
}
