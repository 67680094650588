// Local dependencies
import { Category, CreateCategory, CreateCategoryFields } from '../../types';

export enum CreateCategoryActionTypes {
  CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST',
  CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR',
  UPDATE_CREATE_CATEGORY_FIELDS = 'UPDATE_CREATE_CATEGORY_FIELDS',
  RESET_CREATE_CATEGORY = 'RESET_CREATE_CATEGORY',
  IS_CONFIRMATION_POPUP_OPEN_CATEGORY = 'IS_CONFIRMATION_POPUP_OPEN_CATEGORY',
}

export type CreateCategoryAction =
  | CreateCategoryRequest
  | CreateGategorySuccess
  | CreateCategoryError
  | UpdateCreateCategoryFields
  | UpdateCreateCategoryOpenPopup
  | ResetCreateCategory;

export interface CreateCategoryRequest {
  type: CreateCategoryActionTypes.CREATE_CATEGORY_REQUEST;
  category: CreateCategory;
}

export interface CreateGategorySuccess {
  type: CreateCategoryActionTypes.CREATE_CATEGORY_SUCCESS;
  category: Category;
}

export interface CreateCategoryError {
  type: CreateCategoryActionTypes.CREATE_CATEGORY_ERROR;
  error: Error;
}

export interface ResetCreateCategory {
  type: CreateCategoryActionTypes.RESET_CREATE_CATEGORY;
}

export interface UpdateCreateCategoryFields {
  type: CreateCategoryActionTypes.UPDATE_CREATE_CATEGORY_FIELDS;
  updates: CreateCategoryFields;
}

export interface UpdateCreateCategoryOpenPopup {
  type: CreateCategoryActionTypes.IS_CONFIRMATION_POPUP_OPEN_CATEGORY;
  value: boolean;
}

export function createCategoryRequest(category: CreateCategory): CreateCategoryRequest {
  return {
    type: CreateCategoryActionTypes.CREATE_CATEGORY_REQUEST,
    category,
  };
}

export function createCategorySucceeded(category: Category): CreateGategorySuccess {
  return {
    type: CreateCategoryActionTypes.CREATE_CATEGORY_SUCCESS,
    category,
  };
}

export function createCategoryFailed(error: Error): CreateCategoryError {
  return {
    type: CreateCategoryActionTypes.CREATE_CATEGORY_ERROR,
    error,
  };
}

export function updateCreateCategoryFields(updates: CreateCategoryFields): UpdateCreateCategoryFields {
  return {
    type: CreateCategoryActionTypes.UPDATE_CREATE_CATEGORY_FIELDS,
    updates,
  };
}

export function resetCreateCategory(): ResetCreateCategory {
  return {
    type: CreateCategoryActionTypes.RESET_CREATE_CATEGORY,
  };
}

export function updateCreateCategoryOpenPopup(value: boolean): UpdateCreateCategoryOpenPopup {
  return {
    type: CreateCategoryActionTypes.IS_CONFIRMATION_POPUP_OPEN_CATEGORY,
    value,
  };
}
