/* External dependencies */
import gql from 'graphql-tag';

export const getCategoryQuery = gql`
  query getCategory($input: ServiceInput!) {
    getCategory(input: $input) {
      id
      name_en
      name_ru
      name_ky
      status
      logo {
        url
      }
      parents {
        id
      }
      parentId
      popularityScore
    }
  }
`;
