/* External dependencies */
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */
import { TransactionStatusType } from './listTransactions/types';
import { DEFAULT_PAYMENT_TYPE, DEBIT_PAYMENT_TYPE } from './listTransactions/actions';

export function getOperatorFields() {
  return [
    {
      field: 'id',
      title: <Trans>ID</Trans>,
    },
    {
      field: 'transactionId',
      title: <Trans>transactionId</Trans>,
    },
    {
      field: 'transactionDate',
      title: <Trans>transactionDate</Trans>,
    },
    {
      field: 'receiptNumber',
      title: <Trans>receiptNumber</Trans>,
    },
    {
      field: 'recipient',
      title: <Trans>recipient</Trans>,
    },
    {
      field: 'transactionType',
      title: <Trans>transactionType</Trans>,
    },
    {
      field: 'service',
      title: <Trans>service</Trans>,
    },
    {
      field: 'amount',
      title: <Trans>amount</Trans>,
    },
    {
      field: 'status',
      title: <Trans>paymentStatus</Trans>,
    },
  ];
}

export const dealerFilters = [
  {
    label: 'Payments',
    value: JSON.stringify(DEFAULT_PAYMENT_TYPE),
  },
  {
    label: 'deposit',
    value: JSON.stringify(DEBIT_PAYMENT_TYPE),
  },
];

export function getPaymentStatusColor(status) {
  let statusColor = {
    bgColor: '',
    textColor: '',
  };

  switch (status) {
    case TransactionStatusType.CANCELED:
      return (statusColor = { bgColor: 'cancel_bg', textColor: 'cancel_text_color' });
    case TransactionStatusType.FAILED:
      return (statusColor = { bgColor: 'failed_bg', textColor: 'failed_text_color' });
    case TransactionStatusType.PENDING:
      return (statusColor = { bgColor: 'pending_bg', textColor: 'pending_text_color' });
    case TransactionStatusType.PROCESSING:
      return (statusColor = { bgColor: 'processing_bg', textColor: 'processing_text_color' });
    case TransactionStatusType.SUCCEEDED:
      return (statusColor = { bgColor: 'succeeded_bg', textColor: 'succeeded_text_color' });

    default:
      return statusColor;
  }
}

export enum ActionsPaymentMenuList {
  CANCEL = 'cancel',
  REWIRE = 'rewire',
}

export enum ActionsAccountMenuList {
  TOP_UP_BALANCE = 'topUp',
  BLOCK_ACCOUNT = 'block',
}

export function getEndOfDay(date: Date) {
  date.setHours(23, 59, 59, 999);

  return date;
}
