/* Local dependencies */
import { ServiceEndpoint } from '../types';

export enum ListServiceEndpointsActionTypes {
  LIST_SERVICE_ENDPOINTS_REQUEST = 'LIST_SERVICE_ENDPOINTS_REQUEST',
  LIST_SERVICE_ENDPOINTS_SUCCESS = 'LIST_SERVICE_ENDPOINTS_SUCCESS',
  LIST_SERVICE_ENDPOINTS_ERROR = 'LIST_SERVICE_ENDPOINTS_ERROR',
  RESET_LIST_SERVICE_ENDPOINTS_STATE = 'RESET_LIST_SERVICE_ENDPOINTS_STATE',
}

export interface ListServiceEndpoints {
  type: ListServiceEndpointsActionTypes.LIST_SERVICE_ENDPOINTS_REQUEST;
  from?: number;
  searchString?: string;
  size?: number;
}

export interface ListServiceEndpointsSuccess {
  type: ListServiceEndpointsActionTypes.LIST_SERVICE_ENDPOINTS_SUCCESS;
  serviceEndpoints: ServiceEndpoint[];
  total: number;
}

export interface ListServiceEndpointsError {
  type: ListServiceEndpointsActionTypes.LIST_SERVICE_ENDPOINTS_ERROR;
  error: Error;
}

export interface ResetServiceEndpointsState {
  type: ListServiceEndpointsActionTypes.RESET_LIST_SERVICE_ENDPOINTS_STATE;
}

export type ListServiceEndpointsAction =
  | ListServiceEndpoints
  | ListServiceEndpointsSuccess
  | ListServiceEndpointsError
  | ResetServiceEndpointsState;

export function listServiceEndpoints(
  from: number = 0,
  searchString: string = '',
  size: number = 20,
): ListServiceEndpoints {
  return {
    type: ListServiceEndpointsActionTypes.LIST_SERVICE_ENDPOINTS_REQUEST,
    from,
    searchString,
    size,
  };
}

export function listServiceEndpointsSucceeded(total, serviceEndpoints): ListServiceEndpointsSuccess {
  return {
    type: ListServiceEndpointsActionTypes.LIST_SERVICE_ENDPOINTS_SUCCESS,
    serviceEndpoints,
    total,
  };
}

export function listServiceEndpointsFailed(error: Error): ListServiceEndpointsError {
  return {
    type: ListServiceEndpointsActionTypes.LIST_SERVICE_ENDPOINTS_ERROR,
    error,
  };
}

export function resetListServiceEndpointsState(): ResetServiceEndpointsState {
  return {
    type: ListServiceEndpointsActionTypes.RESET_LIST_SERVICE_ENDPOINTS_STATE,
  };
}
