/* External dependencies */
import gql from 'graphql-tag';

export const deleteDistributionFeesMutation = gql`
  mutation deleteDistributionFees($input: [DeleteDistributionFeesInput!]!) {
    deleteDistributionFees(input: $input) {
      statusMessage
    }
  }
`;
