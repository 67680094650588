/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/averspay';
import {
  BlockAccountAction,
  BlockAccountActionTypes,
  blockAccountError,
  BlockAccountSuccess,
  blockAccountSuccessed,
} from './actions';
import { blockAccountMutation } from './mutation';

export default function blockAccountEpic(action$) {
  return action$.pipe(
    filter(
      (action: BlockAccountAction) =>
        action.type === BlockAccountActionTypes.BLOCK_ACCOUNT_REQUEST,
    ),
    switchMap((action: BlockAccountAction) =>
      blockAccount(action)
        .then(blockAccountSuccessed)
        .catch((error) => blockAccountError(error)),
    ),
  );
}

export async function blockAccount(action): Promise<BlockAccountSuccess> {
  const graphQLClient = await getClient();
  const { id } = action;

  const {
    data: { blockAccount },
  } = await graphQLClient.mutate({
    mutation: blockAccountMutation,
    variables: {
      input: {
        id,
      },
    },
  });

  return blockAccount;
}
