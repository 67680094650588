/*External dependencies*/
import { filter, switchMap } from 'rxjs/operators';

/*Local dependencies*/
import { getClient } from '../../../../clients/averspay';
import { DistributionFee } from '../../types';
import {
  GetDistributionFeesActionTypes,
  DistributionFeesActions,
  GetDistributionFeesRequest,
  getDistributionFeesFailed,
  getDistributionFeesSucceeded,
} from './actions';
import { getDistributionFeeQuery } from './queries';

export default function getDistributionFeeEpic(action$) {
  return action$.pipe(
    filter(
      (action: DistributionFeesActions) => action.type === GetDistributionFeesActionTypes.GET_DISTRIBUTION_FEES_REQUEST,
    ),
    switchMap((action: GetDistributionFeesRequest) => {
      return getDistributionFee(action).then(getDistributionFeesSucceeded).catch(getDistributionFeesFailed);
    }),
  );
}

export async function getDistributionFee({ input }: GetDistributionFeesRequest): Promise<DistributionFee> {
  const graphQLClient = await getClient();

  const {
    data: { getDistributionFee },
  } = await graphQLClient.query({
    query: getDistributionFeeQuery,
    variables: {
      input,
    },
  });

  return getDistributionFee;
}
