/* External dependencies */
import gql from 'graphql-tag';

export const listAccountsQuery = gql`
  query listAccounts($input: ListAccountsInput!) {
    listAccounts(input: $input) {
      total
      accounts {
        id
        name
        user {
          id
          lastName
          firstName
          identificationStatus
          joinDate
          phone
          groupName
          status
        }
        balance {
          amount
          currency
        }
        registrationDate
      }
    }
  }
`;
