// External dependencies
import { filter, switchMap } from 'rxjs';

// Local dependencies
import { getClient } from '../../../../../clients/averspay';
import { cleanPayload } from '../../../../common/helpers';
import { Terminal } from '../../types';
import {
  GetTerminalRequest,
  UpdateTerminalActionType,
  UpdateTerminalActions,
  UpdateTerminalRequest,
  getTerminalFailed,
  getTerminalSucceeded,
  updateTerminalFailed,
  updateTerminalSucceeded,
} from './action';
import { updateTerminalMutation } from './mutation';
import { getTerminalQuery } from './queries';

export function updateTerminalEpic(action$) {
  return action$.pipe(
    filter((action: UpdateTerminalActions) => action.type === UpdateTerminalActionType.UPDATE_TERMINAL_REQUEST),
    switchMap((action: UpdateTerminalRequest) =>
      updateTerminal(action).then(updateTerminalSucceeded).catch(updateTerminalFailed),
    ),
  );
}

export async function updateTerminal({ terminal }: UpdateTerminalRequest): Promise<Terminal> {
  const graphQLClient = await getClient();
  const { id, name, status, address } = terminal;

  const {
    data: { changeDevice },
  } = await graphQLClient.mutate({
    mutation: updateTerminalMutation,
    variables: {
      input: cleanPayload({ id, name, status, address }),
    },
  });

  return changeDevice as Terminal;
}

export function getTerminalEpic(action$) {
  return action$.pipe(
    filter((action: UpdateTerminalActions) => action.type === UpdateTerminalActionType.GET_TERMINAL_REQUEST),
    switchMap((action: GetTerminalRequest) =>
      getTerminal(action)
        .then(getTerminalSucceeded)
        .catch((error: Error) => getTerminalFailed(error)),
    ),
  );
}

export async function getTerminal(action): Promise<Terminal> {
  const graphQLClient = await getClient();
  const { id } = action;

  const {
    data: { getDevice },
  } = await graphQLClient.query({
    query: getTerminalQuery,
    variables: { input: { id } },
  });

  return getDevice as Terminal;
}
