/* Local dependencies */
import { SelectedServiceFees, DeleteServiceFeesResponse } from './types';

export enum DeleteServiceFeesActionTypes {
  DELETE_SERVICE_FEES_ERROR = 'DELETE_SERVICE_FEES_ERROR',
  DELETE_SERVICE_FEES_ERROR_RESET = 'DELETE_SERVICE_FEES_ERROR_RESET',
  DELETE_SERVICE_FEES_REQUEST = 'DELETE_SERVICE_FEES_REQUEST',
  DELETE_SERVICE_FEES_RESET = 'DELETE_SERVICE_FEES_RESET',
  DELETE_SERVICE_FEES_SUCCESS = 'DELETE_SERVICE_FEES_SUCCESS',
  CONFIRMATION_SERVICE_FEES_POPUP = 'CONFIRMATION_SERVICE_FEES_POPUP',
}

export interface DeleteServiceFees {
  type: DeleteServiceFeesActionTypes.DELETE_SERVICE_FEES_REQUEST;
  selectedServiceFees: SelectedServiceFees[];
}

export interface DeleteServiceFeesSuccess {
  type: DeleteServiceFeesActionTypes.DELETE_SERVICE_FEES_SUCCESS;
  payload: DeleteServiceFeesResponse;
}

export interface DeleteServiceFeesError {
  type: DeleteServiceFeesActionTypes.DELETE_SERVICE_FEES_ERROR;
  error: Error;
}

export interface DeleteServiceFeesReset {
  type: DeleteServiceFeesActionTypes.DELETE_SERVICE_FEES_RESET;
}

export interface DeleteServiceFeesErrorReset {
  type: DeleteServiceFeesActionTypes.DELETE_SERVICE_FEES_ERROR_RESET;
}

export interface ToggleConfirmationDeleteServiceFeePopup {
  type: DeleteServiceFeesActionTypes.CONFIRMATION_SERVICE_FEES_POPUP;
  state: boolean;
}

export type DeleteServiceFeesActions =
  | DeleteServiceFees
  | DeleteServiceFeesSuccess
  | DeleteServiceFeesError
  | DeleteServiceFeesErrorReset
  | ToggleConfirmationDeleteServiceFeePopup
  | DeleteServiceFeesReset;

export function deleteServiceFees(selectedServiceFees: SelectedServiceFees[]): DeleteServiceFees {
  return {
    type: DeleteServiceFeesActionTypes.DELETE_SERVICE_FEES_REQUEST,
    selectedServiceFees,
  };
}

export function deleteServiceFeesSuccess(response: DeleteServiceFeesResponse): DeleteServiceFeesSuccess {
  return {
    type: DeleteServiceFeesActionTypes.DELETE_SERVICE_FEES_SUCCESS,
    payload: response,
  };
}

export function deleteServiceFeesFailed(error: Error): DeleteServiceFeesError {
  return {
    type: DeleteServiceFeesActionTypes.DELETE_SERVICE_FEES_ERROR,
    error,
  };
}

export function deleteServiceFeesReset(): DeleteServiceFeesReset {
  return {
    type: DeleteServiceFeesActionTypes.DELETE_SERVICE_FEES_RESET,
  };
}

export function deleteServiceFeesErrorReset(): DeleteServiceFeesErrorReset {
  return {
    type: DeleteServiceFeesActionTypes.DELETE_SERVICE_FEES_ERROR_RESET,
  };
}

export function toggleConfirmationDeleteServiceFeePopup(state: boolean): ToggleConfirmationDeleteServiceFeePopup {
  return {
    type: DeleteServiceFeesActionTypes.CONFIRMATION_SERVICE_FEES_POPUP,
    state,
  };
}
