/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/averspay';
import {
  ActiveServiceAction,
  ActiveServiceActionTypes,
  ActiveServiceSuccess,
  activeServiceError,
  activeServiceSuccessed,
} from './actions';
import { activeServiceMutation } from './mutation';

export default function activeServiceEpic(action$) {
  return action$.pipe(
    filter((action: ActiveServiceAction) => action.type === ActiveServiceActionTypes.ACTIVE_SERVICE_REQUEST),
    switchMap((action: ActiveServiceAction) =>
      activeService(action)
        .then(activeServiceSuccessed)
        .catch((error) => activeServiceError(error)),
    ),
  );
}

export async function activeService(action): Promise<ActiveServiceSuccess> {
  const graphQLClient = await getClient();
  const { id } = action;

  const {
    data: { activeService },
  } = await graphQLClient.mutate({
    mutation: activeServiceMutation,
    variables: {
      input: {
        id,
      },
    },
  });

  return activeService;
}
