/* External dependencies */
import { fetchAndActivate, getAll } from 'firebase/remote-config';
import OneSignal from 'react-onesignal';

/* Local dependencies */
import { remoteConfig } from './firebase.config';
import { setAnonymousClient } from './src/clients/averspay';
import { getItemFromLocalStorage, setLocalStorage } from './src/components/common/helpers';
import wrapWithProvider from './src/redux/wrap-with-provider';

export const wrapRootElement = wrapWithProvider;

export const onClientEntry = async () => {
  const localStorateItem = 'one-signal-connection';

  const connectionStatus = getItemFromLocalStorage(localStorateItem);
  setAnonymousClient();

  if (!connectionStatus) {
    setLocalStorage(localStorateItem, 'connected');

    OneSignal.init({ appId: process.env.GATSBY_ONESIGNAL_APP_ID }).then(() => {
      OneSignal.showSlidedownPrompt().then(() => {});
    });
  }

  remoteConfig.settings.minimumFetchIntervalMillis = 0;

  let remoteConfigValues;

  try {
    await fetchAndActivate(remoteConfig);

    remoteConfigValues = getAll(remoteConfig);

    setLocalStorage('isAcquiringActive', remoteConfigValues.isAcquiringActive['_value']);
  } catch (error) {
    console.log('Failed to fetch remote config', error);
  }

  let userIPAddress;
  const allowedIPAdress = '31.192.253.68';
  try {
    const response = await fetch('https://ipinfo.io/json?token=d8273f5ff3b940');
    const data = await response.json();
    userIPAddress = data.ip;
  } catch (error) {
    console.log('Failed to fetch IP', error);
  }

  if (userIPAddress === allowedIPAdress) {
    setLocalStorage('QRCode', remoteConfigValues.isShowQRCodeWithIPAdress._value);
  }

  if (userIPAddress !== allowedIPAdress) {
    setLocalStorage('QRCode', remoteConfigValues.isShowQRCode._value);
  }

  setLocalStorage('VISA', remoteConfigValues.isShowVISA._value);
};
