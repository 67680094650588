/* External dependencies */
import gql from 'graphql-tag';

export const createCategoryMutation = gql`
  mutation createCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      id
      logo {
        url
      }
      name_en
      name_ru
      name_ky
      parentId
      status
    }
  }
`;
