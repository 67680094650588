/* External dependencies */
import gql from 'graphql-tag';

export const updateUserForAdminMutation = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      lastName
      firstName
      patronymic
      gender
      birthDate
      identificationStatus
      identificationDocument {
        id
        documentIdentificationStatus
        faceIdentificationStatus
        personalData {
          pin
          documentNumber
          front
          back
          selfie
        }
      }
      phone
      groupName
      status
    }
  }
`;

export const updateUserForOperatorMutation = gql`
  mutation updateUserForOperator($input: UpdateUserForOperatorInput!) {
    updateUserForOperator(input: $input) {
      id
      status
      identificationDocument {
        id
        documentIdentificationStatus
        faceIdentificationStatus
        personalData {
          pin
          documentNumber
          front
          back
          selfie
        }
      }
      identificationStatus
      firstName
      lastName
      patronymic
      birthDate
      gender
    }
  }
`;

export const updateUserForManagerMutation = gql`
  mutation updateUserForManager($input: UpdateUserForManagerInput!) {
    updateUserForManager(input: $input) {
      id
      status
      identificationDocument {
        id
        documentIdentificationStatus
        faceIdentificationStatus
        personalData {
          pin
          documentNumber
          front
          back
          selfie
        }
      }
      identificationStatus
      firstName
      lastName
      patronymic
      birthDate
      gender
    }
  }
`;

export const updateUserForComplianceManagerMutation = gql`
  mutation updateUserForComplianceManager($input: UpdateUserForComplianceManagerInput!) {
    updateUserForComplianceManager(input: $input) {
      id
      status
      identificationDocument {
        id
        documentIdentificationStatus
        faceIdentificationStatus
        personalData {
          pin
          documentNumber
          front
          back
          selfie
        }
      }
      identificationStatus
      firstName
      lastName
      patronymic
      birthDate
      gender
    }
  }
`;
