// External dependencies
import { filter, switchMap } from 'rxjs/operators';

// Local dependencies
import { getClient } from '../../../clients/averspay';
import { AccountsBalance } from '../types';
import { GetBalanceActionType, GetBalanceActions, getAccountsBalanceError, getAccountsBalanceSuccess } from './actions';
import { getAccountsBalanceQuery } from './queries';

export default function getAccountsBalanceEpic(action$) {
  return action$.pipe(
    filter((action: GetBalanceActions) => action.type === GetBalanceActionType.GET_ACCOUNT_BALANCE_REQUEST),
    switchMap(() => getAccountsBalance().then(getAccountsBalanceSuccess).catch(getAccountsBalanceError)),
  );
}

export async function getAccountsBalance(): Promise<AccountsBalance> {
  const graphQLClient = await getClient();

  const {
    data: { getAccountsBalance },
  } = await graphQLClient.query({
    query: getAccountsBalanceQuery,
    variables: {},
  });

  return getAccountsBalance;
}
