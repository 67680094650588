/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/averspay';
import { cleanPayload } from '../../../common/helpers';
import { ServiceEndpoint } from '../../types';
import {
  CreateServiceEndpointAction,
  CreateServiceEndpointActionTypes,
  createServiceEndpointFailed,
  CreateServiceEndpointRequest,
  createServiceEndpointSucceeded,
} from './actions';
import { createServiceEndpointMutation } from './mutation';

export default function createServiceEndpointEpic(action$) {
  return action$.pipe(
    filter(
      (action: CreateServiceEndpointAction) =>
        action.type === CreateServiceEndpointActionTypes.CREATE_SERVICE_ENDPOINT_REQUEST,
    ),
    switchMap((action: CreateServiceEndpointRequest) =>
      createServiceEndpoint(action).then(createServiceEndpointSucceeded).catch(createServiceEndpointFailed),
    ),
  );
}

export async function createServiceEndpoint(action: CreateServiceEndpointRequest): Promise<ServiceEndpoint> {
  const graphQLClient = await getClient();
  const { serviceEndpoint } = action;

  const {
    data: { createServiceEndpoint },
  } = await graphQLClient.mutate({
    mutation: createServiceEndpointMutation,
    variables: {
      input: cleanPayload(serviceEndpoint),
    },
  });

  return createServiceEndpoint as ServiceEndpoint;
}
