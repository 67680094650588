// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { DistributionFees } from '../types';
import { DistributionFeesActionTypes, DistributionFeesActions } from './actions';
import { validateDistributionFees } from './validatation';

export interface DistributionFeesState {
  distributionFees: DistributionFees;
  errors: DistributionFees;
  requestData?: DistributionFees[];
  isSuccess: boolean;
  loading: boolean;
  error: Error | null;
  validationPassed: boolean;
}

export const distributionFeesInitialState = {
  distributionFees: {
    clientFeePercentage: null,
    serviceRewardPercentage: null,
  },
  errors: {
    clientFeePercentage: null,
    serviceRewardPercentage: null,
  },
  loading: false,
  isSuccess: false,
  error: null,
  validationPassed: false,
};

export default function distributionFeesReducer(
  state: DistributionFeesState = distributionFeesInitialState,
  action: DistributionFeesActions,
) {
  switch (action.type) {
    case DistributionFeesActionTypes.CHANGE_DISTRIBUTION_FEE: {
      const [propertyName, propertyValue] = action.data;

      return validateDistributionFees(
        update(state, {
          distributionFees: {
            [propertyName]: { $set: propertyValue },
          },
          errors: {
            [propertyName]: { $set: null },
          },
        }),
      );
    }

    case DistributionFeesActionTypes.VALIDATE_DISTRIBUTION_FEES_ALL_FIELDS: {
      return validateDistributionFees(state);
    }

    case DistributionFeesActionTypes.SET_DISTRIBUTION_FEES_REQUEST: {
      return update(state, {
        loading: { $set: true },
      });
    }

    case DistributionFeesActionTypes.SET_DISTRIBUTION_FEES_SUCCESS: {
      return update(state, {
        isSuccess: { $set: true },
        loading: { $set: false },
      });
    }

    case DistributionFeesActionTypes.SET_DISTRIBUTION_FEES_FAILURE: {
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });
    }

    case DistributionFeesActionTypes.RESET_DISTRIBUTION_FEE: {
      return distributionFeesInitialState;
    }

    case DistributionFeesActionTypes.RESET_DISTRIBUTION_FEE_ERROR: {
      return update(state, { $unset: ['error'] });
    }

    default: {
      return state;
    }
  }
}
