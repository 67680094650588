export enum BlockAccountActionTypes {
  BLOCK_ACCOUNT_REQUEST = 'BLOCK_ACCOUNT_REQUEST',
  BLOCK_ACCOUNT_SUCCESS = 'BLOCK_ACCOUNT_SUCCESS',
  BLOCK_ACCOUNT_ERROR = 'BLOCK_ACCOUNT_ERROR',
  RESET_BLOCK_ACCOUNT_STATE = 'RESET_BLOCK_ACCOUNT_STATE',
}

export interface BlockAccountRequest {
  type: BlockAccountActionTypes.BLOCK_ACCOUNT_REQUEST;
  id: string;
}

export interface BlockAccountSuccess {
  type: BlockAccountActionTypes.BLOCK_ACCOUNT_SUCCESS;
}

export interface BlockAccountError {
  type: BlockAccountActionTypes.BLOCK_ACCOUNT_ERROR;
  error: Error;
}

export interface ResetBlockAccountState {
  type: BlockAccountActionTypes.RESET_BLOCK_ACCOUNT_STATE;
}

export type BlockAccountAction =
  | BlockAccountRequest
  | BlockAccountSuccess
  | BlockAccountError
  | ResetBlockAccountState;

export function blockAccountRequest(id: string): BlockAccountRequest {
  return {
    type: BlockAccountActionTypes.BLOCK_ACCOUNT_REQUEST,
    id,
  };
}

export function blockAccountSuccessed(): BlockAccountSuccess {
  return {
    type: BlockAccountActionTypes.BLOCK_ACCOUNT_SUCCESS,
  };
}

export function blockAccountError(error: Error): BlockAccountError {
  return {
    type: BlockAccountActionTypes.BLOCK_ACCOUNT_ERROR,
    error,
  };
}

export function resetBlockAccountState(): ResetBlockAccountState {
  return {
    type: BlockAccountActionTypes.RESET_BLOCK_ACCOUNT_STATE,
  };
}
