/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/averspay';
import {
  QueueMetricsAction,
  QueueMetricsActionTypes,
  getQueueMetricsFailed,
  getQueueMetricsSucceeded,
  QueueMetricsSuccess,
  RedriveQueueMetricsRequest,
  RedriveQueueMetricsSuccess,
  redriveQueueMetricsSucceeded,
  redriveQueueMetricsFailed,
} from './action';
import { getTransactionsQueueMetricsQuery } from './query';
import { redriveTransactionQueueMutation } from './mutation';

export default function transactionsQueueMetricsEpic(action$) {
  return action$.pipe(
    filter((action: QueueMetricsAction) => action.type === QueueMetricsActionTypes.QUEUE_METRICS_REQUEST),
    switchMap((action: QueueMetricsAction) =>
      transactionsQueueMetrics().then(getQueueMetricsSucceeded).catch(getQueueMetricsFailed),
    ),
  );
}

export async function transactionsQueueMetrics(): Promise<QueueMetricsSuccess> {
  const graphQLClient = await getClient();

  const {
    data: { getTransactionsQueueMetrics },
  } = await graphQLClient.query({
    query: getTransactionsQueueMetricsQuery,
  });

  return getTransactionsQueueMetrics;
}

export function redriveQueueMetricsEpic(action$) {
  return action$.pipe(
    filter((action: QueueMetricsAction) => action.type === QueueMetricsActionTypes.REDRIVE_QUEUE_METRICS_REQUEST),
    switchMap((action: RedriveQueueMetricsRequest) =>
      redriveQueueMetrics(action).then(redriveQueueMetricsSucceeded).catch(redriveQueueMetricsFailed),
    ),
  );
}

export async function redriveQueueMetrics({
  queueType,
}: RedriveQueueMetricsRequest): Promise<RedriveQueueMetricsSuccess> {
  const graphQLClient = await getClient();

  const {
    data: { redriveTransactionQueue },
  } = await graphQLClient.mutate({
    mutation: redriveTransactionQueueMutation,
    variables: {
      input: {
        queueType,
      },
    },
  });

  return redriveTransactionQueue;
}
