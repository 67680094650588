/* External dependencies */
import gql from 'graphql-tag';

export const listServiceFeesQuery = gql`
  query listServiceFees($input: ListServiceFeesInput!) {
    listServiceFees(input: $input) {
      total
      serviceFees {
        id
        idTitle
        serviceId
        serviceTitle {
          ru
          ky
          en
        }
        entityType
        lower {
          flatFee {
            amount
            currency
          }
          flatFeePercent
          dynamicFeeSteps {
            fee {
              flatFee {
                amount
                currency
              }
              flatFeePercent
            }
            from {
              amount
              currency
            }
          }
        }
        upper {
          flatFee {
            amount
            currency
          }
          flatFeePercent
          dynamicFeeSteps {
            fee {
              flatFee {
                amount
                currency
              }
              flatFeePercent
            }
            from {
              amount
              currency
            }
          }
        }
      }
    }
  }
`;
