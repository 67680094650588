/* External dependencies */
import * as AWSCognito from 'amazon-cognito-identity-js';

/* Local dependencies */
import { logoutFailed, logoutSucceeded, SessionUser } from '../components/login/redux/actions';
import CustomCognitoUserSession from './cognitoUserSession';

export const config = {
  APPSYNC_ENDPOINT: process.env.GATSBY_APPSYNC_ENDPOINT!,
  AVERSPAY_USER_ID: process.env.GATSBY_AVERSPAY_USER_ID!,
  COGNITO_CLIENT_ID: process.env.GATSBY_COGNITO_CLIENT_ID!,
  COGNITO_IDENTITY_POOL_ID: process.env.GATSBY_COGNITO_IDENTITY_POOL_ID!,
  COGNITO_USER_POOL_ID: process.env.GATSBY_COGNITO_USER_POOL_ID!,
  REGION: process.env.GATSBY_REGION!,
};

export const COGNITO_USER_POOL_DATA = {
  UserPoolId: config.COGNITO_USER_POOL_ID,
  ClientId: config.COGNITO_CLIENT_ID,
};

interface Token {
  payload: SessionUser;
  jwtToken: string;
}

interface RefreshToken {
  token: string;
}
export interface Session {
  accessToken?: Token;
  email: string;
  email_verified: Boolean;
  family_name: string;
  given_name: string;
  idToken?: Token;
  newPasswordRequired?: Boolean;
  phone_number: Number;
  phone_number_verified: Boolean;
  refreshToken?: RefreshToken;
}

export function getSessionUser(idToken: Token | AWSCognito.CognitoIdToken) {
  return idToken.payload;
}

export async function cognitoSignOut() {
  try {
    const cognitoUserPool = new AWSCognito.CognitoUserPool(COGNITO_USER_POOL_DATA);
    const cognitoUser = cognitoUserPool.getCurrentUser();

    cognitoUser!.signOut();

    return logoutSucceeded();
  } catch (error) {
    return logoutFailed(error);
  }
}

export async function getSession(userPool = COGNITO_USER_POOL_DATA): Promise<CustomCognitoUserSession> {
  return new Promise((resolve, reject) => {
    const cognitoUserPool = new AWSCognito.CognitoUserPool(userPool);
    const cognitoUser = cognitoUserPool.getCurrentUser();

    cognitoUser.getSession(function (err, session) {
      if (err) {
        return reject(err);
      }

      resolve(session);
    });
  });
}
