/* External dependencies */
import gql from 'graphql-tag';

export const topUpBalanceMutation = gql`
  mutation topUpAccountBalance($input: TopUpAccountBalanceInput!) {
    topUpAccountBalance(input: $input) {
      balance {
        amount
        cuurency
      }
      destinationAccount
      sourceAccount
    }
  }
`;
