// Local dependencies
import { User, UserIdentificationStatus, UserGroup, UserStatus } from '../userTypes';

export enum ListUsersActionTypes {
  LIST_USERS_REQUEST = 'LIST_USERS_REQUEST',
  LIST_USERS_REQUEST_MANAGER = 'LIST_USERS_REQUEST_MANAGER',
  LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS',
  LIST_USERS_ERROR = 'LIST_USERS_ERROR',
  RESET_LIST_USERS = 'RESET_LIST_USERS',
  RESET_LIST_USERS_ERROR = 'RESET_LIST_USERS_ERROR',
  RESET_SELECTED_USER = 'RESET_SELECTED_USER',
  RESET_USERS_ERROR = 'RESET_USERS_ERROR',
  SORT_USERS_BY = 'SORT_USERS_BY',
  SELECT_USER = 'SELECT_USER',
  OPEN_IS_POPUP = 'OPEN_IS_POPUP',
}

export enum UserSelectionType {
  MULTI = 'MULTI',
  SINGLE = 'SINGLE',
}

export interface ListUsers {
  type: ListUsersActionTypes.LIST_USERS_REQUEST | ListUsersActionTypes.LIST_USERS_REQUEST_MANAGER;
  currentPage?: number;
  searchString: string;
  filter?: ListUsersFilter;
  size?: number;
}

export interface ListUsersFilter {
  groupName?: [UserGroup] | [];
  identificationStatus?: [UserIdentificationStatus] | [];
  status?: [UserStatus] | [];
}

export interface ListUsersSuccess {
  type: ListUsersActionTypes.LIST_USERS_SUCCESS;
  users: User[];
  total: number;
}

export interface ListUsersError {
  type: ListUsersActionTypes.LIST_USERS_ERROR;
  error: Error;
}

export const DEFAULT_USER_FILTER_MANAGER: ListUsersFilter = {
  status: [UserStatus.ENABLED],
};

export const DEFAULT_USER_FILTER: ListUsersFilter = {
  groupName: [],
  identificationStatus: [],
  status: [],
};

export interface ListUsersSort {
  type: ListUsersActionTypes.SORT_USERS_BY;
  field: string;
}

export interface ResetListUsers {
  type: ListUsersActionTypes.RESET_LIST_USERS;
}

export interface ResetListUsersError {
  type: ListUsersActionTypes.RESET_LIST_USERS_ERROR;
}

export interface SelectUser {
  type: ListUsersActionTypes.SELECT_USER;
  user: User;
  selectionType: UserSelectionType;
}

export interface ResetSelectedUser {
  type: ListUsersActionTypes.RESET_SELECTED_USER;
}

export interface ResetUsersError {
  type: ListUsersActionTypes.RESET_USERS_ERROR;
}

export interface OpenIsPopup {
  type: ListUsersActionTypes.OPEN_IS_POPUP;
  value: boolean;
}

export type ListUsersAction =
  | ListUsers
  | ListUsersSuccess
  | ListUsersError
  | ResetListUsers
  | SelectUser
  | OpenIsPopup
  | ResetUsersError
  | ResetSelectedUser
  | ResetListUsersError;

export function listUsers(searchString: string = '', currentPage: number = 1, filter, size = 20): ListUsers {
  return {
    type: ListUsersActionTypes.LIST_USERS_REQUEST,
    searchString,
    currentPage,
    filter,
    size,
  };
}

export function listUsersforManager(
  searchString: string = '',
  currentPage: number = 1,
  filter = DEFAULT_USER_FILTER_MANAGER,
  size = 20,
): ListUsers {
  return {
    type: ListUsersActionTypes.LIST_USERS_REQUEST_MANAGER,
    searchString,
    currentPage,
    filter,
    size,
  };
}

export function listUsersSucceeded(total: number, users): ListUsersSuccess {
  return {
    type: ListUsersActionTypes.LIST_USERS_SUCCESS,
    users,
    total,
  };
}

export function listUsersFailed(error: Error): ListUsersError {
  return {
    type: ListUsersActionTypes.LIST_USERS_ERROR,
    error,
  };
}

export function sortUsersBy(field: string) {
  return {
    type: ListUsersActionTypes.SORT_USERS_BY,
    field,
  };
}

export function resetListUsers(): ResetListUsers {
  return {
    type: ListUsersActionTypes.RESET_LIST_USERS,
  };
}

export function resetListUsersError(): ResetListUsersError {
  return {
    type: ListUsersActionTypes.RESET_LIST_USERS_ERROR,
  };
}

export function selectUser(user: User, selectionType = UserSelectionType.SINGLE): SelectUser {
  return {
    type: ListUsersActionTypes.SELECT_USER,
    user,
    selectionType,
  };
}

export function resetSelectedUser(): ResetSelectedUser {
  return {
    type: ListUsersActionTypes.RESET_SELECTED_USER,
  };
}

export function resetUsersError(): ResetUsersError {
  return {
    type: ListUsersActionTypes.RESET_USERS_ERROR,
  };
}

export function openIsPopup(value: boolean): OpenIsPopup {
  return {
    type: ListUsersActionTypes.OPEN_IS_POPUP,
    value,
  };
}
