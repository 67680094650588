/* External dependencies */
import { of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { initClient } from '../../../clients/averspay';
import { cognitoSignOut } from '../../../clients/cognito';
import { customAuthSignIn, sendVerificationCode } from '../../../clients/custom-auth';
import {
  LoginAction,
  LoginActionTypes,
  loginFailed,
  logoutSucceeded,
  logoutFailed,
  initClientFailed,
  LoginRequest,
  SendVerificationCodeRequest,
} from './actions';

export function initClientEpic(action$) {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.INIT_CLIENT),
    switchMap(initClient),
    catchError(() => of(initClientFailed())),
  );
}

export function loginEpic(action$) {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.LOGIN_REQUEST),
    switchMap((action: LoginRequest) => customAuthSignIn(action).catch(loginFailed)),
  );
}

export function logoutEpic(action$): LoginAction {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.LOGOUT_REQUEST),
    switchMap(cognitoSignOut),
  );
}

export function sendVerificationCodeEpic(action$, state$) {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.SEND_VERIFICATION_CODE_REQUEST),
    switchMap((action: SendVerificationCodeRequest) => {
      const { currentUserSession, verificationCode } = state$.value.login;
      const data = {
        ...action,
        currentUserSession,
        verificationCode,
      };

      return sendVerificationCode(data);
    }),
    catchError((error) => of(loginFailed(error))),
  );
}
