/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/averspay';
import {
  deleteCategoryFailed,
  deleteCategorySucceeded,
  DeleteCategorySuccess,
  DeleteServiceAction,
  DeleteServiceActionTypes,
  deleteServiceFailed,
  deleteServiceSucceeded,
  DeleteServiceSuccess,
} from './actions';
import { deleteCategoryMutation, deleteServiceMutation } from './mutation';

export function deleteServiceEpic(action$) {
  return action$.pipe(
    filter((action: DeleteServiceAction) => action.type === DeleteServiceActionTypes.DELETE_SERVICE_REQUEST),
    switchMap((action: DeleteServiceAction) => deleteService(action).catch((error) => deleteServiceFailed(error))),
  );
}

export async function deleteService(action): Promise<DeleteServiceSuccess> {
  const graphQLClient = await getClient();
  const { id } = action;

  const {
    data: {
      deleteService: { statusMessage },
    },
  } = await graphQLClient.mutate({
    mutation: deleteServiceMutation,
    variables: {
      input: { id },
    },
  });

  return deleteServiceSucceeded(statusMessage);
}

export function deleteCategoryEpic(action$) {
  return action$.pipe(
    filter((action: DeleteServiceAction) => action.type === DeleteServiceActionTypes.DELETE_CATEGORY_REQUEST),
    switchMap((action: DeleteServiceAction) => deleteCategory(action).catch((error) => deleteCategoryFailed(error))),
  );
}

export async function deleteCategory(action): Promise<DeleteCategorySuccess> {
  const graphQLClient = await getClient();
  const { id } = action;

  const {
    data: {
      deleteCategory: { statusMessage },
    },
  } = await graphQLClient.mutate({
    mutation: deleteCategoryMutation,
    variables: {
      input: { id },
    },
  });

  return deleteCategorySucceeded(statusMessage);
}
