/* External dependencies */
import gql from 'graphql-tag';

export const listServiceEndpointsQuery = gql`
  query listServiceEndpoints($input: ListServiceEndpointsInput!) {
    listServiceEndpoints(input: $input) {
      total
      serviceEndpoints {
        id
        name
      }
    }
  }
`;
