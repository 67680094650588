/* Local dependencies */
import { Currency, Money } from '../../services/types';

export enum TopUpBalanceActionTypes {
  TOP_UP_BALANCE_REQUEST = 'TOP_UP_BALANCE_REQUEST',
  TOP_UP_BALANCE_SUCCESS = 'TOP_UP_BALANCE_SUCCESS',
  TOP_UP_BALANCE_ERROR = 'TOP_UP_BALANCE_ERROR',
  UPDATE_TOP_UP_BALANCE_FIELDS = 'UPDATE_ACCOUNTS_FIELDS',
  UPDATE_SOURCE_ACCOUNT_WITH_NAME_FIELDS = 'UPDATE_SOURCE_ACCOUNT_WITH_NAME_FIELDS',
  IS_TOP_UP_BALANCE = 'IS_TOP_UP_BALANCE',
  RESET_TOP_UP_BALANCE_STATE = 'RESET_TOP_UP_BALANCE_STATE',
  RESET_ERROR = 'RESET_ERROR',
  SELECT_CURRENCY = 'SELECT_CURRENCY',
  SELECT_SOURCE_ACCOUNT = 'SELECT_SOURCE_ACCOUNT',
  SHOW_CONFIRMATION_POPUP = 'SHOW_CONFIRMATION_POPUP',
  HIDE_CONFIRMATION_POPUP = 'HIDE_CONFIRMATION_POPUP',
}

export interface SourceAccountWithName {
  id: string | undefined;
  name: string | undefined;
}

export interface UpdateTopUpBalance {
  amount?: number | undefined | null;
  destinationAccount?: string | undefined;
  sourceAccount?: string | undefined;
}

export interface TopUpAccountBalance {
  balance: Money | undefined;
  destinationAccount: string | undefined;
  sourceAccount: string | undefined;
}

export interface TopUpBalanceRequest {
  type: TopUpBalanceActionTypes.TOP_UP_BALANCE_REQUEST;
  topUpBalance: TopUpAccountBalance;
}

export interface TopUpBalanceSuccess {
  type: TopUpBalanceActionTypes.TOP_UP_BALANCE_SUCCESS;
}

export interface TopUpBalanceError {
  type: TopUpBalanceActionTypes.TOP_UP_BALANCE_ERROR;
  error: Error;
}

export interface UpdateTopUpBalanceFields {
  type: TopUpBalanceActionTypes.UPDATE_TOP_UP_BALANCE_FIELDS;
  updates: UpdateTopUpBalance;
}

export interface UpdateSourceAccountWithNameFields {
  type: TopUpBalanceActionTypes.UPDATE_SOURCE_ACCOUNT_WITH_NAME_FIELDS;
  sourceAccountWithName: SourceAccountWithName;
}

export interface TopUpBalanceOperation {
  type: TopUpBalanceActionTypes.IS_TOP_UP_BALANCE;
}

export interface ResetTopUpBalanceState {
  type: TopUpBalanceActionTypes.RESET_TOP_UP_BALANCE_STATE;
}

export interface ResetError {
  type: TopUpBalanceActionTypes.RESET_ERROR;
}

export interface SelectCurrency {
  type: TopUpBalanceActionTypes.SELECT_CURRENCY;
  currency: Currency;
}

export interface SelectSourceAccount {
  type: TopUpBalanceActionTypes.SELECT_SOURCE_ACCOUNT;
  sourceAccountId: string;
}

export interface ShowConfirmationPopup {
  type: TopUpBalanceActionTypes.SHOW_CONFIRMATION_POPUP;
}

export interface HideConfirmationPopup {
  type: TopUpBalanceActionTypes.HIDE_CONFIRMATION_POPUP;
}

export type TopUpBalanceAction =
  | TopUpBalanceRequest
  | TopUpBalanceSuccess
  | TopUpBalanceError
  | UpdateTopUpBalanceFields
  | TopUpBalanceOperation
  | ResetTopUpBalanceState
  | ResetError
  | SelectCurrency
  | UpdateSourceAccountWithNameFields
  | ShowConfirmationPopup
  | HideConfirmationPopup;

export function topUpBalanceRequest(topUpBalance: TopUpAccountBalance): TopUpBalanceRequest {
  return {
    type: TopUpBalanceActionTypes.TOP_UP_BALANCE_REQUEST,
    topUpBalance,
  };
}

export function topUpBalanceSucceeded(): TopUpBalanceSuccess {
  return {
    type: TopUpBalanceActionTypes.TOP_UP_BALANCE_SUCCESS,
  };
}

export function topUpBalanceError(error: Error): TopUpBalanceError {
  return {
    type: TopUpBalanceActionTypes.TOP_UP_BALANCE_ERROR,
    error,
  };
}

export function updatedAccountFields(updates: UpdateTopUpBalance): UpdateTopUpBalanceFields {
  return {
    type: TopUpBalanceActionTypes.UPDATE_TOP_UP_BALANCE_FIELDS,
    updates,
  };
}

export function updateSourceAccountWithNameFields(
  sourceAccountWithName: SourceAccountWithName,
): UpdateSourceAccountWithNameFields {
  return {
    type: TopUpBalanceActionTypes.UPDATE_SOURCE_ACCOUNT_WITH_NAME_FIELDS,
    sourceAccountWithName,
  };
}

export function topUpBalanceOperation(): TopUpBalanceOperation {
  return {
    type: TopUpBalanceActionTypes.IS_TOP_UP_BALANCE,
  };
}

export function resetTopUpBalanceState(): ResetTopUpBalanceState {
  return {
    type: TopUpBalanceActionTypes.RESET_TOP_UP_BALANCE_STATE,
  };
}

export function resetTopUpBalanceError() {
  return {
    type: TopUpBalanceActionTypes.RESET_ERROR,
  };
}

export function selecCurrency(currency: Currency): SelectCurrency {
  return {
    type: TopUpBalanceActionTypes.SELECT_CURRENCY,
    currency,
  };
}

export function showConfirmationPopupTopUpBalance(): ShowConfirmationPopup {
  return {
    type: TopUpBalanceActionTypes.SHOW_CONFIRMATION_POPUP,
  };
}

export function hideConfirmationPopupTopUpBalance(): HideConfirmationPopup {
  return {
    type: TopUpBalanceActionTypes.HIDE_CONFIRMATION_POPUP,
  };
}
