/*External dependencies*/
import { debounceTime, filter, switchMap } from 'rxjs/operators';

/*Local dependencies*/
import { getClient } from '../../../../clients/averspay';
import { cleanPayload } from '../../../common/helpers';
import {
  ListDistributionFees,
  ListDistributionFeesAction,
  ListDistributionFeesActionTypes,
  ListDistributionFeesSuccess,
  listDistributionFeesFailed,
  listDistributionFeesSucceeded,
} from './actions';
import { listDistributionFeesQuery } from './queries';

export default function listDistributionFeesEpic(action$) {
  return action$.pipe(
    filter(
      (action: ListDistributionFeesAction) =>
        action.type === ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: ListDistributionFees) => {
      return listDistributionFees(action).catch(listDistributionFeesFailed);
    }),
  );
}

export async function listDistributionFees({
  filter = {},
}: ListDistributionFees): Promise<ListDistributionFeesSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listDistributionFees: { distributionFees, total },
    },
  } = await graphQLClient.query({
    query: listDistributionFeesQuery,
    variables: {
      input: {
        filter: cleanPayload(filter),
      },
    },
  });

  return listDistributionFeesSucceeded(total, distributionFees);
}
