// External dependencies
import { filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { getClient } from '../../../../clients/averspay';
import {
  ListTerminals,
  ListTerminalsAction,
  ListTerminalsActionTypes,
  listTerminalsFailed,
  listTerminalsSucceeded,
  ListTerminalsSuccess,
} from './actions';
import { listTerminalsQuery } from './queries';

export default function listTerminalsEpic(action$, state$) {
  return action$.pipe(
    filter((action: ListTerminalsAction) => action.type === ListTerminalsActionTypes.LIST_TERMINALS_REQUEST),
    switchMap((action: ListTerminals) =>
      listTerminals(action, state$?.value?.terminals?.size).catch((error) => listTerminalsFailed(error)),
    ),
  );
}

export async function listTerminals(
  { currentPage, searchString, filter }: ListTerminals,
  size: number,
): Promise<ListTerminalsSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listAdminDevices: { devices, total },
    },
  } = await graphQLClient.query({
    query: listTerminalsQuery,
    variables: {
      input: {
        from: (currentPage - 1) * size,
        query: searchString,
        size,
        filter,
      },
    },
  });
  return listTerminalsSucceeded(total, devices);
}
