// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { AccountsBalance } from '../types';
import { GetBalanceActionType, GetBalanceActions } from './actions';

export interface GetBalanceInitialState {
  error?: Error;
  loading: boolean;
  data: AccountsBalance | null;
}

export const getBalanceInitialState: GetBalanceInitialState = {
  error: null,
  loading: false,
  data: null,
};

export default function getAccountBalanceReducer(state = getBalanceInitialState, action: GetBalanceActions) {
  switch (action.type) {
    case GetBalanceActionType.GET_ACCOUNT_BALANCE_REQUEST:
      return update(state, {
        error: { $set: null },
        loading: { $set: true },
      });

    case GetBalanceActionType.GET_ACCOUNT_BALANCE_SUCCESS:
      return update(state, {
        data: { $set: action.data },
        loading: { $set: false },
      });

    case GetBalanceActionType.GET_ACCOUNT_BALANCE_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case GetBalanceActionType.GET_ACCOUNT_BALANCE_ERROR_RESET:
      return update(state, {
        error: { $set: null },
      });

    case GetBalanceActionType.GET_ACCOUNT_BALANCE_RESET:
      return getBalanceInitialState;

    default:
      return state;
  }
}
