/* External dependencies */
import gql from 'graphql-tag';

export const listTerminalsQuery = gql`
  query listAdminDevices($input: ListAdminDevicesInput!) {
    listAdminDevices(input: $input) {
      total
      devices {
        typename: __typename
        ... on AdminTerminal {
          address
          id
          location {
            lat
            lon
          }
          name
          owner {
            id,
            firstName,
            lastName
          }
          registrationDate
          status
          technicalDetails
        }
      }
    }
  }
`;
