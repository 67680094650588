/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { NON_BREAKING_SPACE } from '../../../common/helpers';
import { validateComission, validateServiceFields } from '../../createService/redux/validate';
import { validateRequiredFieldsInputs } from '../../createService/redux/validateRequiredFields';
import { Currency, DynamicFeeType, FeesTypes, Service, UpdateServiceFields } from '../../types';
import { UpdateServiceActionTypes, UpdateServiceActions } from './action';

export interface UpdateServiceState {
  amountError?: Error;
  currency?: Currency;
  currencyError?: Error;
  dynamicFeeType?: string;
  error?: Error;
  flatFeePercentError?: Error;
  feeType?: string;
  isSuccess?: boolean;
  loading?: boolean;
  maxAmountError?: Error;
  minAmountError?: Error;
  nameEnError?: Error;
  nameKyError?: Error;
  nameRuError?: Error;
  logoError?: Error;
  serverError?: Error;
  service?: UpdateServiceFields | Service;
  statusError?: Error;
  shouldShowConfirmationPopup?: boolean;
  popularityScoreError?: Error;
  statusMessageError?: Error;
  dynamicFeeStepsError?: Error;
  serviceEndpointError?: Error;
  selectedServiceEndpoint?: string;
  selectedParentIdLabel?: string;
  parentIdError?: Error;
  isFormChanged?: boolean;
}

export const initialUpdateServiceState: UpdateServiceState = {
  currency: Currency.KGS,
  dynamicFeeType: DynamicFeeType.AMOUNT,
  isSuccess: false,
  shouldShowConfirmationPopup: false,
  selectedParentIdLabel: '',
  selectedServiceEndpoint: '',
};

export default function updateServiceReducer(state = initialUpdateServiceState, action: UpdateServiceActions) {
  switch (action.type) {
    case UpdateServiceActionTypes.UPDATE_SERVICE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case UpdateServiceActionTypes.UPDATE_SERVICE_SUCCESS:
      return update(state, {
        isSuccess: { $set: true },
        loading: { $set: false },
        shouldShowConfirmationPopup: { $set: true },
        service: { $set: action.service },
      });

    case UpdateServiceActionTypes.UPDATE_SERVICE_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
        shouldShowConfirmationPopup: { $set: true },
      });

    case UpdateServiceActionTypes.UPDATE_RESET_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    case UpdateServiceActionTypes.RESET_UPDATE_SERVICE_STATE:
      return update(state, { $set: initialUpdateServiceState });

    case UpdateServiceActionTypes.UPDATE_SERVICE_FIELDS:
      return validateServiceFields(state, action.updates);

    case UpdateServiceActionTypes.UPDATE_SELECT_FEE_TYPE:
      return validateComission(state, action.fee);

    case UpdateServiceActionTypes.UPDATE_RESET_FIXED_COMMISSION:
      return update(state, {
        service: {
          $set: update(state.service, {
            fees: { $set: action.values },
          }),
        },
      });

    case UpdateServiceActionTypes.UPDATE_SET_FEE_TYPE:
      return validateServiceFields(state, action.fee);

    case UpdateServiceActionTypes.SHOW_CONFIRMATION_POPUP:
      return update(state, {
        shouldShowConfirmationPopup: { $set: true },
      });

    case UpdateServiceActionTypes.HIDE_CONFIRMATION_POPUP:
      return update(state, {
        shouldShowConfirmationPopup: { $set: false },
      });

    case UpdateServiceActionTypes.UPDATE_RESET_FEE_VALUES:
      return update(state, {
        service: {
          $set: update(state.service, {
            fees: { $set: initialUpdateServiceState?.service?.fees },
          }),
        },
      });

    case UpdateServiceActionTypes.UPDATE_REQUIREDFIELDS_INPUT:
      return validateRequiredFieldsInputs(state, action);

    case UpdateServiceActionTypes.UPDATE_SERVICE_REQUIREDFIELDS_ADD:
      const data = [...state.service.requiredFields, ...action.requiredFields].filter((item, index, array) => {
        const lastIndex = array.map((obj) => obj.fieldId).lastIndexOf(item.fieldId);

        return index === lastIndex;
      });

      return update(state, {
        service: {
          $set: update(state.service, {
            requiredFields: {
              $set: data,
            },
          }),
        },
      });

    case UpdateServiceActionTypes.UPDATE_SERVICE_REQUIREDFIELDS_ADD_INPUT:
      return update(state, {
        service: {
          $set: update(state.service, {
            requiredFields: {
              $set: update(state.service.requiredFields, {
                $push: [
                  {
                    fieldId: null,
                    inputMask: null,
                    keyboardType: null,
                    keyboardChek: false,
                    label_en: null,
                    label_ky: null,
                    label_ru: null,
                    maxLength: null,
                    minLength: null,
                    prefix: null,
                    value: null,
                    hasSpaceAfterPrefix: false,
                  },
                ],
              }),
            },
          }),
        },
      });

    case UpdateServiceActionTypes.UPDATE_SERVICE_REQUIREDFIELDS_REMOVE_INPUT:
      return update(state, {
        service: {
          $set: update(state.service, {
            requiredFields: {
              $set: update(state.service.requiredFields, {
                $splice: [[action.index, 1]],
              }),
            },
          }),
        },
      });

    case UpdateServiceActionTypes.UPDATE_ADD_DYNAMIC_FEE_VALUE:
      return update(state, {
        service: {
          $set: update(state.service, {
            fees: {
              $set: update(state.service.fees, {
                dynamicFeeSteps: { $set: action.values },
              }),
            },
          }),
        },
      });

    case UpdateServiceActionTypes.UPDATE_ADD_ROW_INPUTS:
      return update(state, {
        service: {
          $set: update(state.service, {
            fees: {
              $set: update(state.service.fees, {
                dynamicFeeSteps: {
                  $set: update(state.service.fees.dynamicFeeSteps, {
                    $push: [
                      {
                        from: {
                          amount: null,
                          currency: Currency.KGS,
                        },
                        fee: {
                          flatFee: {
                            amount: null,
                            currency: Currency.KGS,
                          },
                        },
                        dynamicFeeType: DynamicFeeType.AMOUNT,
                      },
                    ],
                  }),
                },
              }),
            },
          }),
        },
      });

    case UpdateServiceActionTypes.UPDATE_REMOVE_ROW_INPUTS:
      return update(state, {
        service: {
          $set: update(state?.service, {
            fees: {
              $set: update(state?.service?.fees, {
                dynamicFeeSteps: {
                  $set: update(state?.service?.fees?.dynamicFeeSteps, {
                    $splice: [[action.index, 1]],
                  }),
                },
              }),
            },
          }),
        },
      });

    case UpdateServiceActionTypes.UPDATE_SELECT_DYNAMIC_FEE_TYPE:
      return update(state, {
        service: {
          $set: update(state.service, {
            fees: {
              $set: update(state?.service?.fees, {
                dynamicFeeSteps: {
                  $set: update(state.service?.fees?.dynamicFeeSteps, {
                    [action.index]: {
                      dynamicFeeType: {
                        $set: action.feeType,
                      },
                    },
                  }),
                },
              }),
            },
          }),
        },

        dynamicFeeType: {
          $set: update(state.dynamicFeeType, {
            $set: action.feeType,
          }),
        },
      });

    case UpdateServiceActionTypes.RESET_UPDATE_ALL_DYNAMIC_INPUTS:
      return update(state, {
        service: {
          $set: update(state.service, {
            fees: {
              $set: update(state?.service?.fees, {
                dynamicFeeSteps: {
                  $set: initialUpdateServiceState.service?.fees.dynamicFeeSteps,
                },
              }),
            },
          }),
        },
      });

    case UpdateServiceActionTypes.RESET_UPDATE_DYNAMIC_FEE_VALUE:
      return update(state, {
        service: {
          $set: update(state.service, {
            fees: {
              $set: update(state.service?.fees, {
                dynamicFeeSteps: {
                  $set: update(state.service?.fees?.dynamicFeeSteps, {
                    [action.index]: {
                      fee: { $set: action.value },
                    },
                  }),
                },
              }),
            },
          }),
        },
      });

    case UpdateServiceActionTypes.UPDATE_GET_SERVICE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case UpdateServiceActionTypes.UPDATE_GET_SERVICE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        service: { $set: addFalseFieldToServiceFields(action.service) },
        feeType: { $set: setFeeType(action.service) },
        selectedServiceEndpoint: { $set: setServiceEndpointName(action.service) },
      });

    case UpdateServiceActionTypes.UPDATE_GET_SERVICE_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case UpdateServiceActionTypes.UPDATE_SERVICE_PARENT_ID_LABEL:
      return update(state, {
        selectedParentIdLabel: {
          $set: action.label,
        },
      });

    default:
      return state;
  }
}

function addFalseFieldToServiceFields(service) {
  const requiredFields = service?.requiredFields?.map((field) => {
    let hasSpaceAfterPrefix = false;

    if (field.prefix) {
      hasSpaceAfterPrefix = field.prefix.endsWith(NON_BREAKING_SPACE);
    }

    return {
      ...field,
      keyboardChek: false,
      hasSpaceAfterPrefix: hasSpaceAfterPrefix,
    };
  });

  const fee = service?.fees?.dynamicFeeSteps?.map((item) => {
    let dynamicFeeType = !item?.fee?.flatFee ? 'percent' : !item?.fee?.flatFeePercent ? 'amount' : null;

    return {
      dynamicFeeType,
      ...item,
    };
  });

  const flatFee = service?.fees?.flatFee;
  const flatFeePercent = service?.fees?.flatFeePercent;
  const serviceEndpointId = service?.serviceEndpoint?.id;
  const supportsDecimals = Boolean(service?.supportsDecimals);

  return {
    ...service,
    fees: { dynamicFeeSteps: fee, flatFee, flatFeePercent },
    requiredFields: requiredFields,
    serviceEndpoint: { id: serviceEndpointId },
    supportsDecimals: supportsDecimals,
  };
}

export function setFeeType(service) {
  const { fees } = service;

  let feeType = '';

  if (!fees) {
    return feeType;
  } else if (fees.hasOwnProperty('flatFee') && fees.flatFee) {
    feeType = FeesTypes.FLAT_FEE;
  } else if (fees.hasOwnProperty('flatFeePercent') && fees.flatFeePercent) {
    feeType = FeesTypes.FLAT_FEE_PERCENT;
  } else if (fees.hasOwnProperty('dynamicFeeSteps') && fees.dynamicFeeSteps) {
    feeType = FeesTypes.DYNAMIC_FEE_STEPS;
  }

  return feeType;
}

export function setServiceEndpointName(service) {
  const { serviceEndpoint } = service;

  let name = '';

  switch (true) {
    case serviceEndpoint && serviceEndpoint.hasOwnProperty('name'):
      return (name = serviceEndpoint.name);

    default:
      return name;
  }
}
