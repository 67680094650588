// Local dependencies
import { SetServiceFees } from '../types';

export type SetServiceFeesActions =
  | CreateServiceFeesRequest
  | CreateServiceFeesSuccess
  | CreateServiceFeesFailure
  | ChangeServiceFeesFields
  | ResetServiceFees
  | ResetServiceFeesError;

export enum ServiceFeesActionTypes {
  CREATE_SERVICE_FEES_REQUEST = 'CREATE_SERVICE_FEES_REQUEST',
  CREATE_SERVICE_FEES_SUCCESS = 'CREATE_SERVICE_FEES_SUCCESS',
  CREATE_SERVICE_FEES_FAILURE = 'CREATE_SERVICE_FEES_FAILURE',
  CHANGE_SERVICE_FEES_FIELDS = 'CHANGE_SERVICE_FEES_FIELDS',
  RESET_SERVICE_FEES = 'RESET_SERVICE_FEES',
  RESET_SERVICE_FEES_ERROR = 'RESET_SERVICE_FEES_ERROR',
}

export interface CreateServiceFeesRequest {
  type: ServiceFeesActionTypes.CREATE_SERVICE_FEES_REQUEST;
}

export interface CreateServiceFeesSuccess {
  type: ServiceFeesActionTypes.CREATE_SERVICE_FEES_SUCCESS;
  result: SetServiceFees;
}

export interface CreateServiceFeesFailure {
  type: ServiceFeesActionTypes.CREATE_SERVICE_FEES_FAILURE;
  error: Error;
}

export interface ChangeServiceFeesFields {
  type: ServiceFeesActionTypes.CHANGE_SERVICE_FEES_FIELDS;
  data: [string, any];
}

export interface ResetServiceFees {
  type: ServiceFeesActionTypes.RESET_SERVICE_FEES;
}

export interface ResetServiceFeesError {
  type: ServiceFeesActionTypes.RESET_SERVICE_FEES_ERROR;
}

export function createServiceFeesRequest(): CreateServiceFeesRequest {
  return {
    type: ServiceFeesActionTypes.CREATE_SERVICE_FEES_REQUEST,
  };
}

export function createServiceFeesSucceeded(result: SetServiceFees): CreateServiceFeesSuccess {
  return {
    type: ServiceFeesActionTypes.CREATE_SERVICE_FEES_SUCCESS,
    result,
  };
}

export function createServiceFeesFailed(error: Error): CreateServiceFeesFailure {
  return {
    type: ServiceFeesActionTypes.CREATE_SERVICE_FEES_FAILURE,
    error,
  };
}

export function changeServiceFeesFields(data: [string, any]): ChangeServiceFeesFields {
  return {
    type: ServiceFeesActionTypes.CHANGE_SERVICE_FEES_FIELDS,
    data,
  };
}

export function resetServiceFees(): ResetServiceFees {
  return {
    type: ServiceFeesActionTypes.RESET_SERVICE_FEES,
  };
}

export function resetServiceFeesError(): ResetServiceFeesError {
  return {
    type: ServiceFeesActionTypes.RESET_SERVICE_FEES_ERROR,
  };
}
