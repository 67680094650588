// External dependencies
import { filter, switchMap } from 'rxjs/operators';
import {
  GetAppClient,
  GetAppClientActions,
  GetAppClientActionTypes,
  getAppClientFailed,
  getAppClientSucceeded,
} from './actions';

// Local depedencies
import { setAnonymousClient } from '../../../../clients/averspay';
import { getClientQuery } from './query';

export default function getAppClientEpic(action$) {
  return action$.pipe(
    filter((action: GetAppClientActions) => action.type === GetAppClientActionTypes.GET_APP_CLIENT_REQUEST),
    switchMap((action: GetAppClient) => getAppClient(action).then(getAppClientSucceeded).catch(getAppClientFailed)),
  );
}

export async function getAppClient({ id }: GetAppClient) {
  const anonymousClient = setAnonymousClient();

  const {
    data: { getClient },
  } = await anonymousClient.query({
    query: getClientQuery,
    variables: {
      input: {
        id,
      },
    },
  });

  return getClient;
}
