// External dependencies
import { filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { getClient } from '../../../clients/averspay';
import { User } from '../userTypes';
import { GetProfile, GetProfileAction, GetProfileActionTypes, getProfileFailure } from './actions';
import { getProfileSucceeded } from './actions';
import { getProfileQuery } from './queries';

export default function getProfileEpic(action$) {
  return action$.pipe(
    filter((action: GetProfileAction) => action.type === GetProfileActionTypes.GET_PROFILE_REQUEST),
    switchMap((action: GetProfile) =>
      getProfile(action)
        .then(getProfileSucceeded)
        .catch((error: Error) => getProfileFailure(error)),
    ),
  );
}

export async function getProfile({ id }: GetProfile): Promise<User> {
  const graphQLClient = await  getClient();

  const {
    data: { getProfile },
  } = await graphQLClient.query({
    query: getProfileQuery,
    variables: {
      input: {
        id,
      },
    },
  });

  return getProfile;
}
