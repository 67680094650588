// External depedencies
import update from 'immutability-helper';

// Local dependencies
import { TerminalTechnician } from '../../types';
import { ListTechniciansActions, ListTechniciansFilter, TechnicianActionType } from './actions';

export interface UpdateTerminalTechniciansInitialState {
  error?: Error;
  filter?: ListTechniciansFilter;
  loading: boolean;
  terminalTechnicians: TerminalTechnician[];
}

const updateTerminalTechniciansInitialState: UpdateTerminalTechniciansInitialState = {
  loading: false,
  terminalTechnicians: [],
};

export default function listTerminalTechniciansReducer(
  state = updateTerminalTechniciansInitialState,
  action: ListTechniciansActions,
) {
  switch (action.type) {
    case TechnicianActionType.LIST_TERMINAL_TECHNICIANS_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        filter: { $set: action.filter },
      });

    case TechnicianActionType.LIST_TERMINAL_TECHNICIANS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        terminalTechnicians: { $set: action.terminalTechnicians },
      });

    case TechnicianActionType.LIST_TERMINAL_TECHNICIANS_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case TechnicianActionType.LIST_TERMINAL_TECHNICIANS_RESET:
      return update(state, { $set: updateTerminalTechniciansInitialState });

    default:
      return state;
  }
}
