/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

/* Local dependencies */
import { getClient } from '../../../clients/averspay';
import { TopUpBalanceAction, TopUpBalanceActionTypes, topUpBalanceSucceeded, topUpBalanceError } from './actions';
import { topUpBalanceMutation } from './mutations';
import { TopUpBalanceRequest } from './actions';

export default function rewirePaymentEpic(action$) {
  return action$.pipe(
    filter((action: TopUpBalanceAction) => action.type === TopUpBalanceActionTypes.TOP_UP_BALANCE_REQUEST),
    switchMap((action: TopUpBalanceAction) =>
      topUpBalance(action)
        .then(topUpBalanceSucceeded)
        .catch((error) => topUpBalanceError(error)),
    ),
  );
}

export async function topUpBalance(action): Promise<TopUpBalanceRequest> {
  const graphQLClient = await getClient();
  const { topUpBalance } = action;

  const {
    data: { topUpAccountBalance },
  } = await graphQLClient.mutate({
    mutation: topUpBalanceMutation,
    variables: {
      input: topUpBalance,
    },
  });

  return topUpAccountBalance;
}
