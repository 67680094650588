/* External dependencies */
import gql from 'graphql-tag';

export const updateTerminalMutation = gql`
  mutation updateDevice($input: UpdateDeviceInput!) {
    updateDevice(input: $input) {
      id
      address
      name
      status
    }
  }
`;
