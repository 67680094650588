/* External dependencies */
import gql from 'graphql-tag';

export const getServiceRecipientQuery = gql`
  query getServiceRecipient($input: GetServiceRecipientInput!) {
    getServiceRecipient(input: $input) {
      name
      phone
      errorMessage
      statusCode
    }
  }
`;
