// Local dependencies
import { Gender } from 'aws-sdk/clients/polly';
import { Account } from '../../../accounts/listAccounts/types';
import { UserRole } from '../../../common/roles';
import { User, UserDocumentStatus, UserGroup, UserIdentificationStatus, UserStatus } from '../../userTypes';

export enum GetUserActionTypes {
  GET_USER_REQUEST = 'GET_USER_REQUEST',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  GET_USER_ERROR = 'GET_USER_ERROR',
  SELECTED_ACCOUNT = 'SELECTED_ACCOUNT',
  GET_ROOT_USER_REQUEST = 'GET_ROOT_USER_REQUEST',
  GET_ROOT_USER_SUCCESS = 'GET_ROOT_USER_SUCCESS',
  GET_ROOT_USER_ERROR = 'GET_ROOT_USER_ERROR',
  GET_USER_UPDATE = 'GET_USER_UPDATE',
  RESET_GET_USER_STATE = 'RESET_GET_USER_STATE',
  RESET_USER_ERROR = 'RESET_USER_ERROR',
  OPERATOR_UPDATE_USER_REQUEST = 'OPERATOR_UPDATE_USER_REQUEST',
  ADMIN_UPDATE_USER_REQUEST = 'ADMIN_UPDATE_USER_REQUEST',
  MANAGER_UPDATE_USER_REQUEST = 'MANAGER_UPDATE_USER_REQUEST',
  COMPLIANCE_MANAGER_UPDATE_USER_REQUEST = 'COMPLIANCE_MANAGER_UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR = 'UPDATE_USER_ERROR',
  UPDATE_USER_RESET = 'UPDATE_USER_RESET',
  UPDATE_USER_FIELDS = 'UPDATE_USER_FIELDS',
  SET_USER_IMAGE = 'SET_USER_IMAGE',
}

export interface GetUser {
  type: GetUserActionTypes.GET_USER_REQUEST;
  id: string;
}

export interface GetUserSuccess {
  type: GetUserActionTypes.GET_USER_SUCCESS;
  user: User;
}

export interface GetUserUpdate {
  type: GetUserActionTypes.GET_USER_UPDATE;
  personalData: any;
}

export interface GetUserError {
  type: GetUserActionTypes.GET_USER_ERROR;
  error: Error;
}

export interface SelectAccount {
  type: GetUserActionTypes.SELECTED_ACCOUNT;
  account: Account;
}

export interface GetRootUser {
  type: GetUserActionTypes.GET_ROOT_USER_REQUEST;
}

export interface GetRootUserSuccess {
  type: GetUserActionTypes.GET_ROOT_USER_SUCCESS;
  rootUser: User;
}

export interface GetRootUserError {
  type: GetUserActionTypes.GET_ROOT_USER_ERROR;
  error: Error;
}

export interface ResetGetUserState {
  type: GetUserActionTypes.RESET_GET_USER_STATE;
}

export interface ResetUserError {
  type: GetUserActionTypes.RESET_USER_ERROR;
}

export interface SetUserImage {
  type: GetUserActionTypes.SET_USER_IMAGE;
  image: string;
}

export type GetUserAction =
  | GetUser
  | GetUserSuccess
  | GetUserError
  | SelectAccount
  | GetRootUser
  | GetRootUserSuccess
  | GetRootUserError
  | ResetGetUserState
  | ResetUserError
  | OperatorUpdateUserRequest
  | AdminUpdateUserRequest
  | UpdateUserSuccess
  | UpdateUserError
  | ManagerUpdateUserRequest
  | ComplianceManagerUpdateUserRequest
  | SetUserImage
  | UpdateUserReset;

export function getUser(id: string): GetUser {
  return {
    type: GetUserActionTypes.GET_USER_REQUEST,
    id,
  };
}

export function getUserSucceeded(user: User): GetUserSuccess {
  return {
    type: GetUserActionTypes.GET_USER_SUCCESS,
    user,
  };
}

export function getUserFailure(error: Error): GetUserError {
  return {
    type: GetUserActionTypes.GET_USER_ERROR,
    error,
  };
}

export function selectAccount(account: Account): SelectAccount {
  return {
    type: GetUserActionTypes.SELECTED_ACCOUNT,
    account,
  };
}

export function getRootUser(): GetRootUser {
  return {
    type: GetUserActionTypes.GET_ROOT_USER_REQUEST,
  };
}

export function getRootUserSucceeded(rootUser: User): GetRootUserSuccess {
  return {
    type: GetUserActionTypes.GET_ROOT_USER_SUCCESS,
    rootUser,
  };
}

export function getRootUserFailure(error: Error): GetRootUserError {
  return {
    type: GetUserActionTypes.GET_ROOT_USER_ERROR,
    error,
  };
}

export function resetGetUserState(): ResetGetUserState {
  return {
    type: GetUserActionTypes.RESET_GET_USER_STATE,
  };
}

export function resetUserError(): ResetUserError {
  return {
    type: GetUserActionTypes.RESET_USER_ERROR,
  };
}

export function setUserImage(image: string): SetUserImage {
  return {
    type: GetUserActionTypes.SET_USER_IMAGE,
    image,
  };
}

interface UpdatePersonalDataInput {
  pin?: string;
  documentNumber?: string;
  front?: string;
  back?: string;
  selfie?: string;
}

export interface UpdateIdentificationDocumentInput {
  id?: string;
  comment?: string;
  documentIdentificationStatus?: UserDocumentStatus;
  faceIdentificationStatus?: UserDocumentStatus;
  personalData?: UpdatePersonalDataInput;
}

export interface UpdateUserFields {
  birthDate?: number;
  firstName?: string;
  groupName?: UserGroup;
  gender?: Gender;
  identificationDocument?: UpdateIdentificationDocumentInput;
  identificationStatus?: UserIdentificationStatus;
  lastName?: string;
  patronymic?: string;
  phone?: string;
  status?: UserStatus;
}

export interface UpdateUserForManager {
  id: string;
  birthDate?: string;
  identificationDocument?: UpdateIdentificationDocumentInput;
  identificationStatus?: UserIdentificationStatus;
  firstName?: string;
  gender?: string;
  lastName?: string;
  patronymic?: string;
  status?: UserStatus;
}

export interface UpdateUserForOperator {
  id: string;
  birthDate?: string;
  identificationDocument?: UpdateIdentificationDocumentInput;
  identificationStatus?: UserIdentificationStatus;
  firstName?: string;
  gender?: string;
  lastName?: string;
  patronymic?: string;
  status?: UserStatus;
}

export interface UpdateUserForComplianceManager {
  id: string;
  birthDate?: string;
  identificationDocument?: UpdateIdentificationDocumentInput;
  identificationStatus?: UserIdentificationStatus;
  firstName?: string;
  gender?: string;
  lastName?: string;
  patronymic?: string;
  status?: UserStatus;
}

export interface UpdateUserForAdmin
  extends UpdateUserForOperator,
    UpdateUserForManager,
    UpdateUserForComplianceManager {
  groupName?: UserGroup;
}

export interface OperatorUpdateUserRequest {
  type: GetUserActionTypes.OPERATOR_UPDATE_USER_REQUEST;
  updateUserInput: UpdateUserForOperator;
}

export interface AdminUpdateUserRequest {
  type: GetUserActionTypes.ADMIN_UPDATE_USER_REQUEST;
  updateUserInput: UpdateUserForAdmin;
}

export interface ManagerUpdateUserRequest {
  type: GetUserActionTypes.MANAGER_UPDATE_USER_REQUEST;
  updateUserInput: UpdateUserForManager;
}

export interface ComplianceManagerUpdateUserRequest {
  type: GetUserActionTypes.COMPLIANCE_MANAGER_UPDATE_USER_REQUEST;
  updateUserInput: UpdateUserForComplianceManager;
}

export interface UpdateUserSuccess {
  type: GetUserActionTypes.UPDATE_USER_SUCCESS;
  user: any;
}

export interface UpdateUserError {
  type: GetUserActionTypes.UPDATE_USER_ERROR;
  error: Error;
}

export interface UpdateUserReset {
  type: GetUserActionTypes.UPDATE_USER_RESET;
}

export interface UpdatedUserFields {
  type: GetUserActionTypes.UPDATE_USER_FIELDS;
  updates: UpdateUserFields;
  userRole: UserRole;
}

export function operatorUpdateUserRequest(updateUserInput: UpdateUserForOperator): OperatorUpdateUserRequest {
  return {
    type: GetUserActionTypes.OPERATOR_UPDATE_USER_REQUEST,
    updateUserInput,
  };
}

export function adminUpdateUserRequest(updateUserInput: UpdateUserForAdmin): AdminUpdateUserRequest {
  return {
    type: GetUserActionTypes.ADMIN_UPDATE_USER_REQUEST,
    updateUserInput,
  };
}

export function managerUpdateUserRequest(updateUserInput: UpdateUserForManager): ManagerUpdateUserRequest {
  return {
    type: GetUserActionTypes.MANAGER_UPDATE_USER_REQUEST,
    updateUserInput,
  };
}

export function complianceManagerUpdateUserRequest(
  updateUserInput: UpdateUserForComplianceManager,
): ComplianceManagerUpdateUserRequest {
  return {
    type: GetUserActionTypes.COMPLIANCE_MANAGER_UPDATE_USER_REQUEST,
    updateUserInput,
  };
}

export function updateUserSucceeded(user: User): UpdateUserSuccess {
  return {
    type: GetUserActionTypes.UPDATE_USER_SUCCESS,
    user,
  };
}

export function updateUserFailed(error: Error): UpdateUserError {
  return {
    type: GetUserActionTypes.UPDATE_USER_ERROR,
    error,
  };
}

export function updateUserFields(updates: UpdateUserFields, userRole: UserRole): UpdatedUserFields {
  return {
    type: GetUserActionTypes.UPDATE_USER_FIELDS,
    updates,
    userRole,
  };
}

export function updateUserReset(): UpdateUserReset {
  return {
    type: GetUserActionTypes.UPDATE_USER_RESET,
  };
}
