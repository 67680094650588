/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/averspay';
import { addWhiteSpaceToPrefix, cleanPayload, removeSpaces } from '../../../common/helpers';
import { CreateService } from './../../types';
import { CreateServiceAction, CreateServiceActionTypes, createServiceFailed, createServiceSucceeded } from './actions';
import { createServiceMutation } from './mutation';

export default function createServiceEpic(action$) {
  return action$.pipe(
    filter((action: CreateServiceAction) => action.type === CreateServiceActionTypes.CREATE_SERVICE_REQUEST),
    switchMap((action: CreateServiceAction) =>
      createService(action)
        .then(createServiceSucceeded)
        .catch((error) => createServiceFailed(error)),
    ),
  );
}

export async function createService(action): Promise<CreateService> {
  const graphQLClient = await getClient();
  const { service } = action;

  const input = Object.keys(service).reduce((input, key) => {
    if (service.hasOwnProperty(key) && typeof service[key] !== 'undefined') {
      input[key] = service[key];
    }

    return input;
  }, {});

  input.fees?.dynamicFeeSteps?.forEach((item) => {
    delete item.dynamicFeeType;
  });

  const cleanedService = cleanPayload(input);
  const serviceWithoutSpaces = removeSpaces(cleanedService);

  const { fees, ...serviceInput } = addWhiteSpaceToPrefix(serviceWithoutSpaces);

  serviceInput.requiredFields?.forEach((item) => {
    delete item.checkedBox;
    delete item.keyboardChek;
    delete item.fieldIdError;
    delete item.labelKyError;
    delete item.labelRuError;
    delete item.labelEnError;
    delete item.valueError;
    delete item.keyboardTypeError;
    delete item.hasSpaceAfterPrefix;
  });

  const {
    data: { createService },
  } = await graphQLClient.mutate({
    mutation: createServiceMutation,
    variables: {
      input: serviceInput,
    },
  });

  return createService as CreateService;
}
