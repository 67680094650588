/* Local dependencies */

export enum RewirePaymentActionTypes {
  REWIRE_PAYMENT_REQUEST = 'REWIRE_PAYMENT_REQUEST',
  REWIRE_PAYMENT_SUCCESS = 'REWIRE_PAYMENT_SUCCESS',
  REWIRE_PAYMENT_ERROR = 'REWIRE_PAYMENT_ERROR',
  UPDATE_REWIRE_FIELDS = 'UPDATE_REWIRE_FIELDS',
  IS_REWIRE_PAYMENT = 'IS_REWIRE_PAYMENT',
  RESET_REWIRE_PAYMENT_STATE = 'RESET_REWIRE_PAYMENT_STATE',
}

export interface UpdatePaymentField {
  newRecipient?: number;
}

export interface RewirePaymentRequest {
  type: RewirePaymentActionTypes.REWIRE_PAYMENT_REQUEST;
  id: string;
  newRecipient: number;
}

export interface RewirePaymentSuccess {
  type: RewirePaymentActionTypes.REWIRE_PAYMENT_SUCCESS;
}

export interface RewirePaymentError {
  type: RewirePaymentActionTypes.REWIRE_PAYMENT_ERROR;
  error: Error;
}

export interface UpdatePaymentInputRequest {
  type: RewirePaymentActionTypes.UPDATE_REWIRE_FIELDS;
  updates: UpdatePaymentField;
}

export interface RewirePaymentOperation {
  type: RewirePaymentActionTypes.IS_REWIRE_PAYMENT;
}

export interface ResetRewirePaymentState {
  type: RewirePaymentActionTypes.RESET_REWIRE_PAYMENT_STATE;
}

export type RewirePaymentAction =
  | RewirePaymentRequest
  | RewirePaymentSuccess
  | RewirePaymentError
  | UpdatePaymentInputRequest
  | RewirePaymentOperation
  | ResetRewirePaymentState;

export function rewirePayment(id: string, newRecipient: number): RewirePaymentRequest {
  return {
    type: RewirePaymentActionTypes.REWIRE_PAYMENT_REQUEST,
    id,
    newRecipient,
  };
}

export function rewirePaymentSucceeded(): RewirePaymentSuccess {
  return {
    type: RewirePaymentActionTypes.REWIRE_PAYMENT_SUCCESS,
  };
}

export function rewirePaymentError(error: Error): RewirePaymentError {
  return {
    type: RewirePaymentActionTypes.REWIRE_PAYMENT_ERROR,
    error,
  };
}

export function updateRewirePaymentField(updates: UpdatePaymentField): UpdatePaymentInputRequest {
  return {
    type: RewirePaymentActionTypes.UPDATE_REWIRE_FIELDS,
    updates,
  };
}

export function rewirePaymentOperation(): RewirePaymentOperation {
  return {
    type: RewirePaymentActionTypes.IS_REWIRE_PAYMENT,
  };
}

export function resetRewirePaymentState(): ResetRewirePaymentState {
  return {
    type: RewirePaymentActionTypes.RESET_REWIRE_PAYMENT_STATE,
  };
}
