/*External dependencies*/
import { filter, switchMap } from 'rxjs/operators';

/*Local dependencies*/
import { getClient } from '../../../../clients/averspay';
import { ServiceFee } from '../../listServiceFees/types';
import {
  GetServiceFees,
  GetServiceFeesAction,
  GetServiceFeesActionTypes,
  getServiceFeesFailed,
  getServiceFeesSuccess,
} from './actions';
import { getServiceFeesQuery } from './query';

export default function getServiceFeesEpic(action$) {
  return action$.pipe(
    filter((action: GetServiceFeesAction) => action.type === GetServiceFeesActionTypes.GET_SERVICE_FEES_REQUEST),
    switchMap((action: GetServiceFees) => {
      return getServiceFees(action).then(getServiceFeesSuccess).catch(getServiceFeesFailed);
    }),
  );
}

export async function getServiceFees({ payload }: GetServiceFees): Promise<ServiceFee> {
  const graphQLClient = await getClient();

  const {
    data: { getServiceFees },
  } = await graphQLClient.query({
    query: getServiceFeesQuery,
    variables: {
      input: payload,
    },
  });

  return getServiceFees;
}
