/* External dependencies */
import update from 'immutability-helper';

/*Local dependencies */
import { DeleteDistributionFeesInput } from '../../types';
import { DeleteDistributionFeesActionTypes, DeleteDistributionFeesActions } from './actions';

export interface DeleteDistributionFeesState {
  error?: Error;
  isConfirmationPopupOpen: boolean;
  isSuccess?: boolean;
  loading: boolean;
  response?: string;
  selectedDistributionFees: DeleteDistributionFeesInput[];
}

export const initialState: DeleteDistributionFeesState = {
  loading: false,
  selectedDistributionFees: [],
  isConfirmationPopupOpen: false,
};

export default function deleteDistributionFeesReducer(state = initialState, action: DeleteDistributionFeesActions) {
  switch (action.type) {
    case DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_REQUEST:
      return update(state, {
        $unset: ['error', 'response'],
        loading: { $set: true },
        selectedDistributionFees: { $set: action.selectedDistributionFees },
      });

    case DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_SUCCESS:
      return update(state, {
        response: { $set: action.response },
        loading: { $set: false },
        isSuccess: { $set: true },
        isConfirmationPopupOpen: { $set: false },
      });

    case DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
        isSuccess: { $set: false },
        isConfirmationPopupOpen: { $set: false },
      });

    case DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_ERROR_RESET:
      return update(state, {
        $unset: ['error'],
      });

    case DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_RESET:
      return initialState;

    case DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_TOGGLE_CONFIRMATION_POPUP:
      return update(state, {
        isConfirmationPopupOpen: { $set: action.value },
      });

    default:
      return state;
  }
}
