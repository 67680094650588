/* Local dependencies */
import { DeleteDistributionFeesInput, DeleteDistributionFeesResponse } from '../../types';

export enum DeleteDistributionFeesActionTypes {
  DELETE_DISTRIBUTION_FEES_ERROR = 'DELETE_DISTRIBUTION_FEES_ERROR',
  DELETE_DISTRIBUTION_FEES_ERROR_RESET = 'DELETE_DISTRIBUTION_FEES_ERROR_RESET',
  DELETE_DISTRIBUTION_FEES_REQUEST = 'DELETE_DISTRIBUTION_FEES_REQUEST',
  DELETE_DISTRIBUTION_FEES_RESET = 'DELETE_DISTRIBUTION_FEES_RESET',
  DELETE_DISTRIBUTION_FEES_SUCCESS = 'DELETE_DISTRIBUTION_FEES_SUCCESS',
  DELETE_DISTRIBUTION_FEES_TOGGLE_CONFIRMATION_POPUP = 'DELETE_DISTRIBUTION_FEES_TOGGLE_CONFIRMATION_POPUP',
}

export interface DeleteDistributionFees {
  type: DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_REQUEST;
  selectedDistributionFees: DeleteDistributionFeesInput[];
}

export interface DeleteDistributionFeesSuccess {
  type: DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_SUCCESS;
  response: string;
}

export interface DeleteDistributionFeesError {
  type: DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_ERROR;
  error: Error;
}

export interface DeleteDistributionFeesReset {
  type: DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_RESET;
}

export interface DeleteDistributionFeesErrorReset {
  type: DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_ERROR_RESET;
}

export interface ToggleConfirmationPopup {
  type: DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_TOGGLE_CONFIRMATION_POPUP;
  value: boolean;
}

export type DeleteDistributionFeesActions =
  | DeleteDistributionFees
  | DeleteDistributionFeesSuccess
  | DeleteDistributionFeesError
  | DeleteDistributionFeesErrorReset
  | ToggleConfirmationPopup
  | DeleteDistributionFeesReset;

export function deleteDistributionFees(
  selectedDistributionFees: DeleteDistributionFeesInput[],
): DeleteDistributionFees {
  return {
    type: DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_REQUEST,
    selectedDistributionFees,
  };
}

export function deleteDistributionFeesSuccess(response: string): DeleteDistributionFeesSuccess {
  return {
    type: DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_SUCCESS,
    response,
  };
}

export function deleteDistributionFeesFailed(error: Error): DeleteDistributionFeesError {
  return {
    type: DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_ERROR,
    error,
  };
}

export function deleteDistributionFeesReset(): DeleteDistributionFeesReset {
  return {
    type: DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_RESET,
  };
}

export function deleteDistributionFeesErrorReset(): DeleteDistributionFeesErrorReset {
  return {
    type: DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_ERROR_RESET,
  };
}

export function toggleConfirmationPopup(value: boolean): ToggleConfirmationPopup {
  return {
    type: DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_TOGGLE_CONFIRMATION_POPUP,
    value,
  };
}
