/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { Currency } from '../listAccounts/types';
import { SourceAccountWithName, TopUpAccountBalance, TopUpBalanceAction, TopUpBalanceActionTypes } from './actions';
import { validateTopUpBalanceFields } from './validateTopUpBalanceFields';

export interface TopUpBalanceState {
  amountError?: Error;
  error?: Error;
  isTopUpBalanceOperation?: boolean;
  isTopUpBalanceSuccess?: boolean;
  loading?: boolean;
  shouldShowConfirmationPopup?: boolean;
  topUpBalance?: TopUpAccountBalance;
  sourceAccountWithName?: SourceAccountWithName;
}

const initialTopUPBalanceState: TopUpBalanceState = {
  isTopUpBalanceSuccess: false,
  topUpBalance: {
    balance: {
      amount: 0,
      currency: Currency.KGS,
    },
    destinationAccount: '',
    sourceAccount: '',
  },
  shouldShowConfirmationPopup: false,
};

export default function topUpBalanceReducer(state = initialTopUPBalanceState, action: TopUpBalanceAction) {
  switch (action.type) {
    case TopUpBalanceActionTypes.TOP_UP_BALANCE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case TopUpBalanceActionTypes.TOP_UP_BALANCE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        isTopUpBalanceSuccess: { $set: true },
        shouldShowConfirmationPopup: { $set: true },
      });

    case TopUpBalanceActionTypes.TOP_UP_BALANCE_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
        shouldShowConfirmationPopup: { $set: true },
      });

    case TopUpBalanceActionTypes.UPDATE_TOP_UP_BALANCE_FIELDS:
      return validateTopUpBalanceFields(state, action.updates);

    case TopUpBalanceActionTypes.UPDATE_SOURCE_ACCOUNT_WITH_NAME_FIELDS:
      return update(state, {
        sourceAccountWithName: { $set: action.sourceAccountWithName },
      });

    case TopUpBalanceActionTypes.IS_TOP_UP_BALANCE:
      return update(state, {
        isTopUpBalanceOperation: { $set: true },
        loading: { $set: false },
      });

    case TopUpBalanceActionTypes.RESET_TOP_UP_BALANCE_STATE:
      return update(state, { $set: initialTopUPBalanceState });

    case TopUpBalanceActionTypes.RESET_ERROR:
      return update(state, { $unset: ['error'] });

    case TopUpBalanceActionTypes.SELECT_CURRENCY:
      return update(state, {
        topUpBalance: {
          $set: update(state.topUpBalance, {
            balance: {
              $set: update(state.topUpBalance?.balance, {
                currency: { $set: action.currency },
              }),
            },
          }),
        },
      });

    case TopUpBalanceActionTypes.SHOW_CONFIRMATION_POPUP:
      return update(state, {
        shouldShowConfirmationPopup: { $set: true },
      });

    case TopUpBalanceActionTypes.HIDE_CONFIRMATION_POPUP:
      return update(state, {
        shouldShowConfirmationPopup: { $set: false },
      });

    default:
      return state;
  }
}
