/* External dependencies */
import { debounceTime, filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/averspay';
import { listAccountsQuery } from '../listAccounts/queries';
import {
  ListRootAccounts,
  ListRootAccountsAction,
  ListRootAccountsSuccess,
  ListRootAccountsActionTypes,
  listRootAccountsFailed,
  listRootAccountsSucceeded,
} from './actions';

export default function listRootAccountsEpic(action$, state$) {
  return action$.pipe(
    filter((action: ListRootAccountsAction) => action.type === ListRootAccountsActionTypes.LIST_ROOT_ACCOUNTS_REQUEST),
    debounceTime(500),
    switchMap((action: ListRootAccounts) =>
      listRootAccounts(action, state$.value.rootAccounts.size).catch((error) => listRootAccountsFailed(error)),
    ),
  );
}

export async function listRootAccounts(
  { currentPage, searchString, filter }: ListRootAccounts,
  size: number,
): Promise<ListRootAccountsSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listAccounts: { total, accounts },
    },
  } = await graphQLClient.query({
    query: listAccountsQuery,
    variables: {
      input: {
        filter,
        from: (currentPage - 1) * size,
        query: searchString,
        size,
      },
    },
  });

  return listRootAccountsSucceeded(total, accounts);
}
