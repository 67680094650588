/* External dependencies */
import gql from 'graphql-tag';

export const getTerminalQuery = gql`
  query getDevice($input: GetDeviceInput!) {
    getDevice(input: $input) {
      address
      balance
      id
      deviceType
      location {
        lat
        lon
      }
      name
      operation
      operator {
        id
      }
      operatorRole
      owner {
        id
        firstName
        lastName
      }
      parentId
      parentRole
      registrationDate
      spareParts {
        deviceType
        id
        lastUpdatedDate
        status
      }
      status
      technicalDetails
      changeDate
    }
  }
`;
