/* Local dependencies */
import { SelectedServiceFees } from '../../deleteServiceFees/types';
import { ServiceFee } from '../../listServiceFees/types';

export enum GetServiceFeesActionTypes {
  GET_SERVICE_FEES_ERROR = 'GET_SERVICE_FEES_ERROR',
  GET_SERVICE_FEES_ERROR_RESET = 'GET_SERVICE_FEES_ERROR_RESET',
  GET_SERVICE_FEES_REQUEST = 'GET_SERVICE_FEES_REQUEST',
  GET_SERVICE_FEES_RESET = 'GET_SERVICE_FEES_RESET',
  GET_SERVICE_FEES_SUCCESS = 'GET_SERVICE_FEES_SUCCESS',
}

export interface GetServiceFees {
  type: GetServiceFeesActionTypes.GET_SERVICE_FEES_REQUEST;
  payload: SelectedServiceFees;
}

export interface GetServiceFeesSuccess {
  type: GetServiceFeesActionTypes.GET_SERVICE_FEES_SUCCESS;
  serviceFee: ServiceFee;
}

export interface GetServiceFeesError {
  type: GetServiceFeesActionTypes.GET_SERVICE_FEES_ERROR;
  error: Error;
}

export interface GetServiceFeesErrorReset {
  type: GetServiceFeesActionTypes.GET_SERVICE_FEES_ERROR_RESET;
}

export interface GetServiceFeesReset {
  type: GetServiceFeesActionTypes.GET_SERVICE_FEES_RESET;
}

export type GetServiceFeesAction =
  | GetServiceFees
  | GetServiceFeesSuccess
  | GetServiceFeesError
  | GetServiceFeesReset
  | GetServiceFeesErrorReset;

export function getServiceFees(input: SelectedServiceFees): GetServiceFees {
  return {
    type: GetServiceFeesActionTypes.GET_SERVICE_FEES_REQUEST,
    payload: input,
  };
}

export function getServiceFeesSuccess(serviceFee: ServiceFee): GetServiceFeesSuccess {
  return {
    type: GetServiceFeesActionTypes.GET_SERVICE_FEES_SUCCESS,
    serviceFee,
  };
}

export function getServiceFeesFailed(error: Error): GetServiceFeesError {
  return {
    type: GetServiceFeesActionTypes.GET_SERVICE_FEES_ERROR,
    error,
  };
}

export function getServiceFeesReset(): GetServiceFeesReset {
  return {
    type: GetServiceFeesActionTypes.GET_SERVICE_FEES_RESET,
  };
}

export function getServiceFeesFailedReset(): GetServiceFeesErrorReset {
  return {
    type: GetServiceFeesActionTypes.GET_SERVICE_FEES_ERROR_RESET,
  };
}
