/* Local dependencies */
import { Money } from '../../services/types';
import { UserGroup, UserIdentificationStatus, UserStatus } from '../../users/userTypes';

export enum Currency {
  KGS = 'KGS',
  RUB = 'RUB',
  USD = 'USD',
}

export enum AccountStatusType {
  BLOCKED = 'BLOCKED',
  SUCCEEDED = 'SUCCEEDED',
}

export interface AccountStatus {
  statusType: AccountStatusType;
  statusReason: string;
}

export interface User {
  firstName: string;
  lastName: string;
  phone: number;
}

export interface Balance {
  amount: number;
  currency: Currency;
}

export interface Account {
  id: string;
  balance: Balance;
  isDefault?: boolean;
  name: string;
  user?: AccountUser;
  registrationDate: number;
  blockDate?: number;
  __typename?: AccountType;
}

export interface AccountInput {
  id: string;
}

export interface AccountForServiceEndpoint {
  id: string;
  balance?: Money;
  isDefault?: boolean;
  name?: string;
  registrationDate?: number;
  user?: AccountUser;
  __typename?: AccountType;
}

export enum AccountType {
  CORPORATE = 'CORPORATE',
  PERSONAL = 'PERSONAL',
  ROOT = 'ROOT',
  SERVICE = 'SERVICE',
}

export interface AccountUser {
  id: string;
  lastName?: string;
  firstName?: string;
  birthDate?: number;
  disabledDate?: number;
  enabledDate?: number;
  identificationStatus: UserIdentificationStatus;
  joinDate?: number;
  phone: number;
  groupName?: UserGroup;
  status?: UserStatus;
}
