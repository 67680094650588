/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { UpdateTopUpBalance } from './actions';
import {
  AmountRequiredException,
  DestinationAccountRequiredException,
  SourceAccountRequiredException,
} from './exceptions';
import { TopUpBalanceState } from './reducer';

export function validateTopUpBalanceFields(state: TopUpBalanceState, updates: UpdateTopUpBalance) {
  const { amount, destinationAccount, sourceAccount, sourceAccountWithName } = updates;
  const change: any = {};

  if (updates.hasOwnProperty('amount')) {
    if (!amount) {
      change.amountError = {
        $set: new AmountRequiredException(),
      };
    } else {
      change['$unset'] = ['amountError'];
    }

    return update(state, {
      ...change,
      topUpBalance: {
        $set: update(state.topUpBalance, {
          balance: {
            $set: update(state.topUpBalance?.balance, {
              amount: { $set: amount },
            }),
          },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('destinationAccount')) {
    if (!destinationAccount) {
      change.destinationAccountError = {
        $set: new DestinationAccountRequiredException(),
      };
    } else {
      change['$unset'] = ['destinationAccountError'];
    }

    return update(state, {
      ...change,
      topUpBalance: {
        $set: update(state.topUpBalance, {
          destinationAccount: { $set: destinationAccount },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('sourceAccount')) {
    if (!sourceAccount) {
      change.sourceAccountError = {
        $set: new SourceAccountRequiredException(),
      };
    } else {
      change['$unset'] = ['sourceAccountError'];
    }

    return update(state, {
      ...change,
      topUpBalance: {
        $set: update(state.topUpBalance, {
          sourceAccount: { $set: sourceAccount },
        }),
      },
    });
  }

  return state;
}
