/* External dependencies */
import update from 'immutability-helper';

/*Local dependencies */
import { getId } from '../helper';
import {
  ListServiceFeesAction,
  ListServiceFeesActionTypes,
  ListServiceFeesFilter,
  ListServiceFeesFilterLabels,
} from './actions';
import { SelectedServiceFeeState, ServiceFee } from './types';

export interface ListServiceFeesStates {
  currentPage: number;
  error?: Error;
  filter?: ListServiceFeesFilter;
  filterLabels?: ListServiceFeesFilterLabels;
  from?: number;
  loading?: boolean;
  serviceFees: ServiceFee[];
  selectedFees: SelectedServiceFeeState[];
  size: number;
  total: number;
}

export const size: number = 20;

export const initialListServiceFeesStates: ListServiceFeesStates = {
  currentPage: 1,
  filter: {},
  loading: false,
  serviceFees: [],
  size,
  total: 0,
  selectedFees: [],
};

export default function listServiceFeesReducer(state = initialListServiceFeesStates, action: ListServiceFeesAction) {
  switch (action.type) {
    case ListServiceFeesActionTypes.LIST_SERVICE_FEES_REQUEST:
      const { filter, filterLabels, currentPage } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        filter: { $set: filter },
        currentPage: { $set: currentPage },
        filterLabels: { $set: filterLabels },
        selectedFees: { $set: [] },
      });

    case ListServiceFeesActionTypes.LIST_SERVICE_FEES_SUCCESS:
      const { serviceFees, total } = action;

      return update(state, {
        loading: { $set: false },
        total: { $set: total },
        serviceFees: { $set: serviceFees },
      });

    case ListServiceFeesActionTypes.LIST_SERVICE_FEES_ERROR:
      const { error } = action;

      return update(state, {
        loading: { $set: false },
        error: { $set: error },
      });

    case ListServiceFeesActionTypes.LIST_SERVICE_FEES_ERROR_RESET:
      return update(state, {
        $unset: ['error'],
      });

    case ListServiceFeesActionTypes.LIST_SERVICE_FEES_SET_SELECTED:
      const { id, originalIds } = action.selectedFee;

      const updatedList = state.selectedFees.filter((item) => item.id !== id);

      if (updatedList.length === state.selectedFees.length) {
        updatedList.push({ id, originalIds });
      }

      return update(state, {
        selectedFees: { $set: updatedList },
      });

    case ListServiceFeesActionTypes.LIST_SERVICE_FEES_SET_SELECT_ALL:
      const selectedFees = action.selectedFees.map(({ id, originalIds }) => ({ id, originalIds }));

      return update(state, {
        selectedFees: { $set: selectedFees },
      });

    case ListServiceFeesActionTypes.LIST_SERVICE_FEES_RESET:
      return update(state, { $set: initialListServiceFeesStates });

    default:
      return state;
  }
}
