/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { UpdatePaymentField } from './actions';
import { RecipientInvalidException } from './exceptions';
import { RewirePaymentState } from './reducer';

export function validateRewireFields(state: RewirePaymentState, updates: UpdatePaymentField) {
  const { newRecipient } = updates;
  const change: any = {};

  if (updates.hasOwnProperty('newRecipient')) {
    if (!newRecipient) {
      change.newRecipientError = {
        $set: new RecipientInvalidException(),
      };
    } else {
      change['$unset'] = ['newRecipientError'];
    }

    return update(state, {
      ...change,
      payment: {
        $set: update(state.payment, {
          newRecipient: { $set: newRecipient },
        }),
      },
    });
  }

  return state;
}
