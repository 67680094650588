/* External dependencies */
import gql from 'graphql-tag';

export const deleteServiceFeesMutation = gql`
  mutation deleteServiceFees($input: [DeleteServiceFeesInput!]!) {
    deleteServiceFees(input: $input) {
      statusMessage
    }
  }
`;
