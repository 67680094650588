// External dependencies
import { filter, switchMap } from 'rxjs/operators';
import { setAnonymousClient } from '../../../clients/averspay';

// Local dependencies
import {
  GetServiceRecipientAction,
  GetServiceRecipientActionTypes,
  getServiceRecipientFailed,
  GetServiceRecipientRequest,
  getServiceRecipientSucceeded,
} from './actions';
import { getServiceRecipientQuery } from './queries';
import { ServiceRecipientInput } from './types';

export function getServiceRecipientEpic(action$) {
  return action$.pipe(
    filter(
      (action: GetServiceRecipientAction) =>
        action.type === GetServiceRecipientActionTypes.GET_SERVICE_RECIPIENT_REQUEST,
    ),
    switchMap((action: GetServiceRecipientRequest) =>
      getServiceRecipient(action)
        .then(getServiceRecipientSucceeded)
        .catch((error: Error) => getServiceRecipientFailed(error)),
    ),
  );
}

export async function getServiceRecipient(action): Promise<ServiceRecipientInput> {
  const graphQLClient = await setAnonymousClient();
  const { service, fields } = action;

  const {
    data: { getServiceRecipient },
  } = await graphQLClient.query({
    query: getServiceRecipientQuery,
    variables: { input: { service, fields } },
  });

  return getServiceRecipient as ServiceRecipientInput;
}
