// External depedencies
import update from 'immutability-helper';

// Internal dependencies
import { UpdateTerminalFields } from '../../types';
import { AddressRequiredException, NameRequiredException } from './exceptions';
import { UpdateTerminalState } from './reducer';

export function validateTerminalFields(state: UpdateTerminalState, updates: UpdateTerminalFields) {
  const { address, name, status } = updates;

  const change: any = {
    isFormChanged: {
      $set: true,
    },
  };

  if (updates.hasOwnProperty('name')) {
    if (!name) {
      change.nameError = {
        $set: new NameRequiredException(),
      };
    } else {
      change['$unset'] = ['nameError'];
    }

    return update(state, {
      ...change,
      terminal: {
        $set: update(state.terminal, {
          name: { $set: name },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('address')) {
    if (!address) {
      change.addressError = {
        $set: new AddressRequiredException(),
      };
    } else {
      change['$unset'] = ['addressError'];
    }

    return update(state, {
      ...change,
      terminal: {
        $set: update(state.terminal, {
          address: { $set: address },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('status') && status) {
    return update(state, {
      ...change,
      terminal: {
        $set: update(state.terminal, {
          status: { $set: status },
        }),
      },
    });
  }

  return state;
}
