// Local dependencies
import { DistributionFee, GetDistributionFeeInput } from '../../types';

export type DistributionFeesActions =
  | ResetGetDistributionFees
  | ResetGetDistributionFeesError
  | GetDistributionFeesRequest
  | GetDistributionFeesFailure
  | GetDistributionFeesSuccess;

export enum GetDistributionFeesActionTypes {
  GET_DISTRIBUTION_FEES_FAILURE = 'GET_DISTRIBUTION_FEES_FAILURE',
  GET_DISTRIBUTION_FEES_REQUEST = 'GET_DISTRIBUTION_FEES_REQUEST',
  GET_DISTRIBUTION_FEES_SUCCESS = 'GET_DISTRIBUTION_FEES_SUCCESS',
  RESET_DISTRIBUTION_FEE = 'RESET_DISTRIBUTION_FEE',
  RESET_DISTRIBUTION_FEE_FAILURE = 'RESET_DISTRIBUTION_FEE_FAILURE',
}

export interface ResetGetDistributionFees {
  type: GetDistributionFeesActionTypes.RESET_DISTRIBUTION_FEE;
}

export interface ResetGetDistributionFeesError {
  type: GetDistributionFeesActionTypes.RESET_DISTRIBUTION_FEE_FAILURE;
}

export interface GetDistributionFeesRequest {
  type: GetDistributionFeesActionTypes.GET_DISTRIBUTION_FEES_REQUEST;
  input: GetDistributionFeeInput;
}

export interface GetDistributionFeesSuccess {
  type: GetDistributionFeesActionTypes.GET_DISTRIBUTION_FEES_SUCCESS;
  payload: DistributionFee;
}

export interface GetDistributionFeesFailure {
  type: GetDistributionFeesActionTypes.GET_DISTRIBUTION_FEES_FAILURE;
  error: Error;
}

export function resetGetDistributionFees(): ResetGetDistributionFees {
  return {
    type: GetDistributionFeesActionTypes.RESET_DISTRIBUTION_FEE,
  };
}

export function resetGetDistributionFeesError(): ResetGetDistributionFeesError {
  return {
    type: GetDistributionFeesActionTypes.RESET_DISTRIBUTION_FEE_FAILURE,
  };
}

export function getDistributionFeesFailed(error: Error): GetDistributionFeesFailure {
  return {
    type: GetDistributionFeesActionTypes.GET_DISTRIBUTION_FEES_FAILURE,
    error,
  };
}

export function getDistributionFeesSucceeded(payload: DistributionFee): GetDistributionFeesSuccess {
  return {
    type: GetDistributionFeesActionTypes.GET_DISTRIBUTION_FEES_SUCCESS,
    payload,
  };
}

export function getDistributionFees(input: GetDistributionFeeInput): GetDistributionFeesRequest {
  return {
    type: GetDistributionFeesActionTypes.GET_DISTRIBUTION_FEES_REQUEST,
    input,
  };
}
