/* Local dependencies */
import { TerminalTechnician, UserRole } from '../../types';

export interface ListTechniciansFilter {
  targetId?: string;
  roles?: UserRole[];
}

export enum TechnicianActionType {
  LIST_TERMINAL_TECHNICIANS_REQUEST = 'LIST_TERMINAL_TECHNICIANS_REQUEST',
  LIST_TERMINAL_TECHNICIANS_SUCCESS = 'LIST_TERMINAL_TECHNICIANS_SUCCESS',
  LIST_TERMINAL_TECHNICIANS_ERROR = 'LIST_TERMINAL_TECHNICIANS_ERROR',
  LIST_TERMINAL_TECHNICIANS_RESET = 'LIST_TERMINAL_TECHNICIANS_RESET',
}

export interface ListTechniciansRequest {
  filter?: ListTechniciansFilter;
  type: TechnicianActionType.LIST_TERMINAL_TECHNICIANS_REQUEST;
}

export interface ListTechniciansReset {
  type: TechnicianActionType.LIST_TERMINAL_TECHNICIANS_RESET;
}

export interface ListTechniciansSuccess {
  type: TechnicianActionType.LIST_TERMINAL_TECHNICIANS_SUCCESS;
  terminalTechnicians: TerminalTechnician[];
}

export interface ListTechniciansError {
  type: TechnicianActionType.LIST_TERMINAL_TECHNICIANS_ERROR;
  error: Error;
}

export function ListTerminalTechniciansRequest(filter?: ListTechniciansFilter): ListTechniciansRequest {
  return { filter, type: TechnicianActionType.LIST_TERMINAL_TECHNICIANS_REQUEST };
}

export function ListTerminalTechniciansSucceeded(technicians: TerminalTechnician[]): ListTechniciansSuccess {
  return {
    type: TechnicianActionType.LIST_TERMINAL_TECHNICIANS_SUCCESS,
    terminalTechnicians: technicians,
  };
}

export function ListTerminalTechniciansFailed(error: Error): ListTechniciansError {
  return {
    type: TechnicianActionType.LIST_TERMINAL_TECHNICIANS_ERROR,
    error,
  };
}

export function ListTerminalTechniciansReset(): ListTechniciansReset {
  return {
    type: TechnicianActionType.LIST_TERMINAL_TECHNICIANS_RESET,
  };
}

export type ListTechniciansActions =
  | ListTechniciansRequest
  | ListTechniciansSuccess
  | ListTechniciansError
  | ListTechniciansReset;
