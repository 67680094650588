// Local dependencies
import { UIException } from '../../../../redux/exceptions';

export class UsernameRequiredException extends UIException {
  code = 'UsernameRequiredException';
}

export class PasswordRequiredException extends UIException {
  code = 'PasswordRequiredException';
}

export class LabelRequiredException extends UIException {
  code = 'LabelRequiredException';
}

export class IdRequiredException extends UIException {
  code = 'IdRequiredException';
}

export class ValueRequiredException extends UIException {
  code = 'ValueRequiredException';
}

export class UserNameRequiredException extends UIException {
  code = 'userFirstNameRequiredFields';
}

export class UserLastNameRequiredException extends UIException {
  code = 'userLastNameRequiredFields';
}

export class UserBirthDayRequiredException extends UIException {
  code = 'userBirthDateRequiredFields';
}

export class UserIDRequiredException extends UIException {
  code = 'userIDNumberRequiredFields';
}

export class UserIDRequiredFieldsException extends UIException {
  code = 'requiredIDNumber';
}

export class UserPinRequiredFieldsException extends UIException {
  code = 'userINNRequiredFields';
}

export class UserPhoneRequiredFieldsException extends UIException {
  code = 'userPhoneNumberRequiredFields';
}

export class UserFrontImageRequiredException extends UIException {
  code = 'userFrontImageRequiredException';
}

export class UserBackImageRequiredException extends UIException {
  code = 'userBackImageRequiredException';
}

export class UserSelfieImageRequiredException extends UIException {
  code = 'userSelfieImageRequiredException';
}

export class UserPatronymicRequiredException extends UIException {
  code = 'userPatronymicRequiredException';
}

export class UserGenderRequiredException extends UIException {
  code = 'userGenderRequiredException';
}

export class PermissionDeniedException extends UIException {
  code = 'permissionDeniedToChangeField';
}

export class UserIdentificationStatusRequiredException extends UIException {
  code = 'userIdentificationStatusRequiredException';
}

export class UserDocumentIdentificationStatusRequiredException extends UIException {
  code = 'userDocumentIdentificationStatusRequiredException';
}

export class UserFaceIdentificationStatusRequiredException extends UIException {
  code = 'userFaceIdentificationStatusRequiredException';
}
