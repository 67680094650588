/* External dependencies */
import gql from 'graphql-tag';

export const cancelPaymentMutation = gql`
  mutation cancelPayment($input: CancelPaymentInput!) {
    cancelPayment(input: $input) {
      id
      status
      amount
      recipient
      service
      requestDate
      message
    }
  }
`;
