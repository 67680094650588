// External dependencies
import { filter, switchMap } from 'rxjs';

// Local dependencies
import { getClient } from '../../../../../clients/averspay';
import { TerminalTechnician } from '../../types';
import {
  ListTechniciansActions,
  ListTechniciansRequest,
  ListTerminalTechniciansFailed,
  ListTerminalTechniciansSucceeded,
  TechnicianActionType,
} from './actions';
import { listTechniciansQuery } from './queries';

export default function listTechniciansEpic(action$) {
  return action$.pipe(
    filter((action: ListTechniciansActions) => action.type === TechnicianActionType.LIST_TERMINAL_TECHNICIANS_REQUEST),
    switchMap((action: ListTechniciansRequest) =>
      listTechnicians(action).then(ListTerminalTechniciansSucceeded).catch(ListTerminalTechniciansFailed),
    ),
  );
}

export async function listTechnicians({ filter }: ListTechniciansRequest): Promise<TerminalTechnician[]> {
  const graphQLClient = await getClient();
  const input: any = {};

  if (filter) {
    input.filter = filter;
  }

  const {
    data: {
      listTechnicians: { users },
    },
  } = await graphQLClient.query({
    query: listTechniciansQuery,
    variables: {
      input,
    },
  });

  return users as TerminalTechnician[];
}
