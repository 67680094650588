/* Local dependencies */
import { CreateServiceEndpointFields, CreateServiceEndpointInput, ServiceEndpointInput } from '../../types';

export enum CreateServiceEndpointActionTypes {
  CREATE_SERVICE_ENDPOINT_REQUEST = 'CREATE_SERVICE_ENDPOINT_REQUEST',
  CREATE_SERVICE_ENDPOINT_SUCCESS = 'CREATE_SERVICE_ENDPOINT_SUCCESS',
  CREATE_SERVICE_ENDPOINT_ERROR = 'CREATE_SERVICE_ENDPOINT_ERROR',
  RESET_ERROR = 'RESET_ERROR',
  RESET_CREATE_SERVICE_ENDPOINT_STATE = 'RESET_CREATE_SERVICE_ENDPOINT_STATE',
  ADD_SERVICE_ENDPOINT_FIELDS = 'ADD_SERVICE_ENDPOINT_FIELDS',
  SELECT_DEALER = 'SELECT_DEALER',
  ADD_BACK_PATH = 'ADD_BACK_PATH',
}

export interface CreateServiceEndpointRequest {
  type: CreateServiceEndpointActionTypes.CREATE_SERVICE_ENDPOINT_REQUEST;
  serviceEndpoint: CreateServiceEndpointInput;
}

export interface CreateServiceEndpointSuccess {
  type: CreateServiceEndpointActionTypes.CREATE_SERVICE_ENDPOINT_SUCCESS;
  serviceEndpoint: CreateServiceEndpointInput;
}

export interface CreateServiceEndpointError {
  type: CreateServiceEndpointActionTypes.CREATE_SERVICE_ENDPOINT_ERROR;
  error: Error;
}

export interface ResetError {
  type: CreateServiceEndpointActionTypes.RESET_ERROR;
}

export interface ResetCreateServiceEndpointState {
  type: CreateServiceEndpointActionTypes.RESET_CREATE_SERVICE_ENDPOINT_STATE;
}

export interface AddServiceEndpointFields {
  type: CreateServiceEndpointActionTypes.ADD_SERVICE_ENDPOINT_FIELDS;
  fields: CreateServiceEndpointFields;
}

export interface SelectDealer {
  type: CreateServiceEndpointActionTypes.SELECT_DEALER;
  dealer: any;
}

export interface AddBackPath {
  type: CreateServiceEndpointActionTypes.ADD_BACK_PATH;
  backPath: string;
}

export type CreateServiceEndpointAction =
  | CreateServiceEndpointRequest
  | CreateServiceEndpointSuccess
  | CreateServiceEndpointError
  | ResetError
  | ResetCreateServiceEndpointState
  | AddServiceEndpointFields
  | SelectDealer
  | AddBackPath;

export function createServiceEndpointRequest(
  serviceEndpoint: CreateServiceEndpointInput,
): CreateServiceEndpointRequest {
  return {
    type: CreateServiceEndpointActionTypes.CREATE_SERVICE_ENDPOINT_REQUEST,
    serviceEndpoint,
  };
}

export function createServiceEndpointSucceeded(
  serviceEndpoint: CreateServiceEndpointInput,
): CreateServiceEndpointSuccess {
  return {
    type: CreateServiceEndpointActionTypes.CREATE_SERVICE_ENDPOINT_SUCCESS,
    serviceEndpoint,
  };
}

export function createServiceEndpointFailed(error: Error): CreateServiceEndpointError {
  return {
    type: CreateServiceEndpointActionTypes.CREATE_SERVICE_ENDPOINT_ERROR,
    error,
  };
}

export function resetError(): ResetError {
  return {
    type: CreateServiceEndpointActionTypes.RESET_ERROR,
  };
}

export function resetCreateServiceEndpointState(): ResetCreateServiceEndpointState {
  return {
    type: CreateServiceEndpointActionTypes.RESET_CREATE_SERVICE_ENDPOINT_STATE,
  };
}

export function addCreateServiceEndpointFields(fields: CreateServiceEndpointFields): AddServiceEndpointFields {
  return {
    type: CreateServiceEndpointActionTypes.ADD_SERVICE_ENDPOINT_FIELDS,
    fields,
  };
}

export function selectDealer(dealer: any): SelectDealer {
  return {
    type: CreateServiceEndpointActionTypes.SELECT_DEALER,
    dealer,
  };
}

export function addBackPath(backPath: string): AddBackPath {
  return {
    type: CreateServiceEndpointActionTypes.ADD_BACK_PATH,
    backPath,
  };
}
