/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/averspay';
import {
  CancelPaymentAction,
  CancelPaymentActionTypes,
  cancelPaymentFailed,
  cancelPaymentSucceeded,
  CancelPaymentSuccess,
} from './action';
import { cancelPaymentMutation } from './mutations';

export default function cancelPaymentEpic(action$) {
  return action$.pipe(
    filter((action: CancelPaymentAction) => action.type === CancelPaymentActionTypes.CANCEL_PAYMENT_REQUEST),
    switchMap((action: CancelPaymentAction) =>
      cancelPayment(action)
        .then(cancelPaymentSucceeded)
        .catch((error) => cancelPaymentFailed(error)),
    ),
  );
}

export async function cancelPayment(action): Promise<CancelPaymentSuccess> {
  const graphQLClient = await getClient();
  const { id } = action;

  const {
    data: { cancelPayment },
  } = await graphQLClient.mutate({
    mutation: cancelPaymentMutation,
    variables: {
      input: {
        id,
      },
    },
  });

  return cancelPayment;
}
