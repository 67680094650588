/* Local dependencies */
import { CreateService, DynamicFeeType, UpdateServiceFields, RequiredServiceFieldInput } from '../../types';

export enum CreateServiceActionTypes {
  ADD_DYNAMIC_FEE_VALUE = 'ADD_DYNAMIC_FEE_VALUE',
  ADD_ROW_INPUTS = 'ADD_ROW_INPUTS',
  ADD_REQUIREDFIELDS_VALUE = 'ADD_REQUIREDFIELDS_VALUE',
  CREATE_SERVICE_REQUEST = 'CREATE_SERVICE_REQUEST',
  CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS',
  CREATE_SERVICE_ERROR = 'CREATE_SERVICE_ERROR',
  CREATE_SERVICE_REQUIREDFIELDS_ADD_INPUT = 'CREATE_SERVICE_REQUIREDFIELDS_ADD_INPUT',
  CREATE_SERVICE_REQUIREDFIELDS_REMOVE_INPUT = 'CREATE_SERVICE_REQUIREDFIELDS_REMOVE_INPUT',
  RESET_ERROR = 'RESET_ERROR',
  SELECT_CURRENCY = 'SELECT_CURRENCY',
  RESET_CREATE_SERVICE_STATE = 'RESET_CREATE_SERVICE_STATE',
  REMOVE_ROW_INPUTS = 'REMOVE_ROW_INPUTS',
  RESET_DYNAMIC_FLATFEEPERCENT_VALUE = 'RESET_DYNAMIC_FLATFEEPERCENT_VALUE',
  RESET_ALL_DYNAMIC_INPUTS = 'RESET_ALL_DYNAMIC_INPUTS',
  RESET_FEE_VALUES = 'RESET_FEE_VALUES',
  RESET_KEYBOARD_TYPE = 'RESET_KEYBOARD_TYPE',
  SELECT_FEE_TYPE = 'SELECT_FEE_TYPE',
  SELECT_KEYBOARD_TYPE = 'SELECT_KEYBOARD_TYPE',
  SELECT_DYNAMIC_FEE_TYPE = 'SELECT_DYNAMIC_FEE_TYPE',
  UPDATE_CREATE_SERVICE_FIELDS = 'UPDATE_CREATE_SERVICE_FIELDS',
  SET_PARENT_ID = 'SET_PARENT_ID',
  RESET_MAX_AMOUNT_VALUE = 'RESET_MAX_AMOUNT_VALUE',
  RESET_MIN_AMOUNT_VALUE = 'RESET_MIN_AMOUNT_VALUE',
  RESET_FIXED_COMMISSION = 'RESET_FIXED_COMMISSION',
}

export interface CreateServiceRequest {
  type: CreateServiceActionTypes.CREATE_SERVICE_REQUEST;
  service: CreateService;
}

export interface CreateServiceSuccess {
  type: CreateServiceActionTypes.CREATE_SERVICE_SUCCESS;
  service: CreateService;
}

export interface CreateServiceError {
  type: CreateServiceActionTypes.CREATE_SERVICE_ERROR;
  error: Error;
}

export interface CreateServiceRequiredFieldsAdd {
  type: CreateServiceActionTypes.CREATE_SERVICE_REQUIREDFIELDS_ADD_INPUT;
}

export interface ResetError {
  type: CreateServiceActionTypes.RESET_ERROR;
}

export interface CreateServiceRequiredFieldsRemove {
  type: CreateServiceActionTypes.CREATE_SERVICE_REQUIREDFIELDS_REMOVE_INPUT;
  index: number;
}

export interface ResetCreateServiceState {
  type: CreateServiceActionTypes.RESET_CREATE_SERVICE_STATE;
}
export interface UpdateCreateServiceFields {
  type: CreateServiceActionTypes.UPDATE_CREATE_SERVICE_FIELDS;
  updates: UpdateServiceFields;
}

export interface SetCreateServiceParentId {
  type: CreateServiceActionTypes.SET_PARENT_ID;
  parentId: string;
}

export interface SelectFeesType {
  type: CreateServiceActionTypes.SELECT_FEE_TYPE;
  fee: any;
}

export interface ResetFeesValues {
  type: CreateServiceActionTypes.RESET_FEE_VALUES;
}

export interface ResetFixedCommission {
  type: CreateServiceActionTypes.RESET_FIXED_COMMISSION;
  values: any;
}

export interface AddFeeValues {
  type: CreateServiceActionTypes.ADD_DYNAMIC_FEE_VALUE;
  values: any;
}

export interface AddRequiredFieldsValues {
  type: CreateServiceActionTypes.ADD_REQUIREDFIELDS_VALUE;
  value: RequiredServiceFieldInput;
  index: number;
}

export interface SelectDynamicFeesType {
  type: CreateServiceActionTypes.SELECT_DYNAMIC_FEE_TYPE;
  feeType: DynamicFeeType;
  index: number;
}

export interface AddRowInputs {
  type: CreateServiceActionTypes.ADD_ROW_INPUTS;
}

export interface RemoveRowInputs {
  type: CreateServiceActionTypes.REMOVE_ROW_INPUTS;
  index: number;
}

export interface ResetAllDynamicInputs {
  type: CreateServiceActionTypes.RESET_ALL_DYNAMIC_INPUTS;
}

export interface ResetDynamicFlatFeePercentValue {
  type: CreateServiceActionTypes.RESET_DYNAMIC_FLATFEEPERCENT_VALUE;
  index: number;
  value: any;
}

export interface ResetMaxAmountValue {
  type: CreateServiceActionTypes.RESET_MAX_AMOUNT_VALUE;
}

export interface ResetMinAmountValue {
  type: CreateServiceActionTypes.RESET_MIN_AMOUNT_VALUE;
}

export type CreateServiceAction =
  | AddFeeValues
  | AddRowInputs
  | AddRequiredFieldsValues
  | CreateServiceRequest
  | CreateServiceSuccess
  | CreateServiceError
  | CreateServiceRequiredFieldsAdd
  | CreateServiceRequiredFieldsRemove
  | RemoveRowInputs
  | ResetAllDynamicInputs
  | ResetCreateServiceState
  | ResetFeesValues
  | ResetMaxAmountValue
  | ResetMinAmountValue
  | SelectFeesType
  | SelectDynamicFeesType
  | UpdateCreateServiceFields
  | SetCreateServiceParentId
  | ResetFixedCommission
  | ResetDynamicFlatFeePercentValue
  | ResetError;

export function createServiceRequest(service: CreateService): CreateServiceRequest {
  const newRequriedFields = service?.requiredFields?.map((el, index) => {
    return {
      ...el,
      orderNumber: index,
      prefix: el.prefix?.trim(),
    };
  });

  const newService = { ...service, requiredFields: newRequriedFields };

  return {
    type: CreateServiceActionTypes.CREATE_SERVICE_REQUEST,
    service: newService,
  };
}

export function createServiceSucceeded(service: CreateService): CreateServiceSuccess {
  return {
    type: CreateServiceActionTypes.CREATE_SERVICE_SUCCESS,
    service,
  };
}

export function createServiceFailed(error: Error): CreateServiceError {
  return {
    type: CreateServiceActionTypes.CREATE_SERVICE_ERROR,
    error,
  };
}

export function createServiceRequiredFieldsInputAdd(): CreateServiceRequiredFieldsAdd {
  return {
    type: CreateServiceActionTypes.CREATE_SERVICE_REQUIREDFIELDS_ADD_INPUT,
  };
}

export function resetError(): ResetError {
  return {
    type: CreateServiceActionTypes.RESET_ERROR,
  };
}

export function createServiceRequiredFieldsInputRemove(index: number): CreateServiceRequiredFieldsRemove {
  return {
    type: CreateServiceActionTypes.CREATE_SERVICE_REQUIREDFIELDS_REMOVE_INPUT,
    index,
  };
}

export function resetCreateServiceState(): ResetCreateServiceState {
  return {
    type: CreateServiceActionTypes.RESET_CREATE_SERVICE_STATE,
  };
}

export function updateCreateServiceFields(updates: UpdateServiceFields): UpdateCreateServiceFields {
  return {
    type: CreateServiceActionTypes.UPDATE_CREATE_SERVICE_FIELDS,
    updates,
  };
}

export function setCreateServiceParentId(parentId: string): SetCreateServiceParentId {
  return {
    type: CreateServiceActionTypes.SET_PARENT_ID,
    parentId,
  };
}

export function selectFeesType(fee: any): SelectFeesType {
  return {
    type: CreateServiceActionTypes.SELECT_FEE_TYPE,
    fee,
  };
}

export function resetFeesValues(): ResetFeesValues {
  return {
    type: CreateServiceActionTypes.RESET_FEE_VALUES,
  };
}

export function resetFixedCommission(values: any): ResetFixedCommission {
  return {
    type: CreateServiceActionTypes.RESET_FIXED_COMMISSION,
    values,
  };
}

export function setDynamicFeeValue(values: any): AddFeeValues {
  return {
    type: CreateServiceActionTypes.ADD_DYNAMIC_FEE_VALUE,
    values,
  };
}

export function setRequiredFieldsValue(value: RequiredServiceFieldInput, index: number): AddRequiredFieldsValues {
  return {
    type: CreateServiceActionTypes.ADD_REQUIREDFIELDS_VALUE,
    value,
    index,
  };
}

export function selectDynamicFeesType(feeType: DynamicFeeType, index: number): SelectDynamicFeesType {
  return {
    type: CreateServiceActionTypes.SELECT_DYNAMIC_FEE_TYPE,
    feeType,
    index,
  };
}

export function addRowInputs(): AddRowInputs {
  return {
    type: CreateServiceActionTypes.ADD_ROW_INPUTS,
  };
}

export function removeRowInputs(index: number): RemoveRowInputs {
  return {
    type: CreateServiceActionTypes.REMOVE_ROW_INPUTS,
    index,
  };
}

export function resetAllDynamicInputs(): ResetAllDynamicInputs {
  return {
    type: CreateServiceActionTypes.RESET_ALL_DYNAMIC_INPUTS,
  };
}

export function resetDynamicFlatFeePercentValue(index: number, value: any): ResetDynamicFlatFeePercentValue {
  return {
    type: CreateServiceActionTypes.RESET_DYNAMIC_FLATFEEPERCENT_VALUE,
    index,
    value,
  };
}

export function clearMaxAmount(): ResetMaxAmountValue {
  return {
    type: CreateServiceActionTypes.RESET_MAX_AMOUNT_VALUE,
  };
}

export function clearMinAmount(): ResetMinAmountValue {
  return {
    type: CreateServiceActionTypes.RESET_MIN_AMOUNT_VALUE,
  };
}
