// Local dependencies
import { CardType, ServiceFieldsInput, ServicePaymentPayloadFields } from '../types';

export enum CreateAnOrderActionTypes {
  CREATE_AN_ORDER_SUCCESS = 'CREATE_AN_ORDER_SUCCESS',
  CREATE_AN_ORDER_ERROR = 'CREATE_AN_ORDER_ERROR',
  UPDATE_CREATE_AN_ORDER_FIELDS = 'UPDATE_CREATE_AN_ORDER_FIELDS',
  ADD_PAYMENT_PAYLOAD_FIELDS = 'ADD_PAYMENT_PAYLOAD_FIELDS',
  ADD_UI_INPUT_FIELDS = 'ADD_UI_INPUT_FIELDS',
  RESET_CREATE_AN_ORDER = 'RESET_CREATE_AN_ORDER',
  RESET_IS_CLICK_BUTTON = 'RESET_IS_CLICK_BUTTON',
  ADD_MIN_AMOUNT_CURRENCY_FIELDS = 'ADD_MIN_AMOUNT_CURRENCY_FIELDS',
}

export type CreateAnOrderAction =
  | AddPaymentPayloadFields
  | AddMinAmountCurrencyFields
  | AddUiInputFields
  | CreateAnOrderSuccess
  | CreateAnOrderError
  | UpdateCreateAnOrderFields
  | ResetCreateAnOrder
  | ResetIsClickButton
  
export interface AddPaymentPayloadFields {
  type: CreateAnOrderActionTypes.ADD_PAYMENT_PAYLOAD_FIELDS;
  fields: ServiceFieldsInput;
}
export interface AddUiInputFields {
  type: CreateAnOrderActionTypes.ADD_UI_INPUT_FIELDS;
  inputFields: any;
}

export interface CreateAnOrderSuccess {
  type: CreateAnOrderActionTypes.CREATE_AN_ORDER_SUCCESS;
}

export interface CreateAnOrderError {
  type: CreateAnOrderActionTypes.CREATE_AN_ORDER_ERROR;
  error: Error;
}

export interface UpdateCreateAnOrderFields {
  type: CreateAnOrderActionTypes.UPDATE_CREATE_AN_ORDER_FIELDS;
  updates: ServicePaymentPayloadFields;
  helpersObj?: any;
}

export interface ResetIsClickButton {
  type: CreateAnOrderActionTypes.RESET_IS_CLICK_BUTTON;
}

export interface ResetCreateAnOrder {
  type: CreateAnOrderActionTypes.RESET_CREATE_AN_ORDER;
}


export interface AddMinAmountCurrencyFields {
  type: CreateAnOrderActionTypes.ADD_MIN_AMOUNT_CURRENCY_FIELDS;
}

export function addPaymentPayloadFileds(fields: ServiceFieldsInput): AddPaymentPayloadFields {
  return {
    type: CreateAnOrderActionTypes.ADD_PAYMENT_PAYLOAD_FIELDS,
    fields,
  };
}

export function addUiInputFileds(inputFields: any[]): AddUiInputFields {
  return {
    type: CreateAnOrderActionTypes.ADD_UI_INPUT_FIELDS,
    inputFields,
  };
}

export function createAnOrderSucceeded(): CreateAnOrderSuccess {
  return {
    type: CreateAnOrderActionTypes.CREATE_AN_ORDER_SUCCESS,
  };
}

export function createAnOrderFailed(error: Error): CreateAnOrderError {
  return {
    type: CreateAnOrderActionTypes.CREATE_AN_ORDER_ERROR,
    error,
  };
}

export function updateCreateAnOrderFields(
  updates: ServicePaymentPayloadFields,
  helpersObj?: any,
): UpdateCreateAnOrderFields {
  return {
    type: CreateAnOrderActionTypes.UPDATE_CREATE_AN_ORDER_FIELDS,
    updates,
    helpersObj,
  };
}

export function resetCreateAnOrder(): ResetCreateAnOrder {
  return {
    type: CreateAnOrderActionTypes.RESET_CREATE_AN_ORDER,
  };
}

export function addMinAmountCurrencyFields(): AddMinAmountCurrencyFields {
  return {
    type: CreateAnOrderActionTypes.ADD_MIN_AMOUNT_CURRENCY_FIELDS,
  };
}
