/* External dependencies */
import gql from 'graphql-tag';

export const createServiceEndpointMutation = gql`
  mutation createServiceEndpoint($input: CreateServiceEndpointInput!) {
    createServiceEndpoint(input: $input) {
      account {
        id
      }
      # auth {
      #   username
      #   password
      # }
      format
      ip
      method
      name
      # port
      protocol
    }
  }
`;
