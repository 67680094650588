// Internal dependencies
import { UIException } from '../../../../../redux/exceptions';

export class NameRequiredException extends UIException {
  code = 'TerminalNameRequiredException';
}

export class AddressRequiredException extends UIException {
  code = 'TerminalAddressRequiredException';
}
