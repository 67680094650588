/* Local dependencies */
import { SelectedServiceFeeState, ServiceFee, listServiceFeesInput } from './types';

export enum ListServiceFeesActionTypes {
  LIST_SERVICE_FEES_ERROR = 'LIST_SERVICE_FEES_ERROR',
  LIST_SERVICE_FEES_ERROR_RESET = 'LIST_SERVICE_FEES_ERROR_RESET',
  LIST_SERVICE_FEES_REQUEST = 'LIST_SERVICE_FEES_REQUEST',
  LIST_SERVICE_FEES_RESET = 'LIST_SERVICE_FEES_RESET',
  LIST_SERVICE_FEES_SUCCESS = 'LIST_SERVICE_FEES_SUCCESS',
  LIST_SERVICE_FEES_SET_SELECTED = 'LIST_SERVICE_FEES_SET_SELECTED',
  LIST_SERVICE_FEES_SET_SELECT_ALL = 'LIST_SERVICE_FEES_SET_SELECT_ALL',
}

export interface ListServiceFeesFilter {
  dealerId?: string;
  serviceId?: string;
  terminalId?: string;
}

export interface ListServiceFeesFilterLabels {
  dealer?: string;
  service?: string;
  terminal?: string;
}

export interface ListServiceFees {
  type: ListServiceFeesActionTypes.LIST_SERVICE_FEES_REQUEST;
  filter?: ListServiceFeesFilter;
  filterLabels?: ListServiceFeesFilterLabels;
  from?: number;
  size?: number;
  currentPage?: number;
}

export interface ListServiceFeesSuccess {
  type: ListServiceFeesActionTypes.LIST_SERVICE_FEES_SUCCESS;
  serviceFees: ServiceFee[];
  total: number;
}

export interface ListServiceFeesError {
  type: ListServiceFeesActionTypes.LIST_SERVICE_FEES_ERROR;
  error: Error;
}

export interface ListServiceFeesErrorReset {
  type: ListServiceFeesActionTypes.LIST_SERVICE_FEES_ERROR_RESET;
}

export interface ListServiceFeesSetSelected {
  type: ListServiceFeesActionTypes.LIST_SERVICE_FEES_SET_SELECTED;
  selectedFee: SelectedServiceFeeState;
}

export interface ListServiceFeesSetSelectAll {
  type: ListServiceFeesActionTypes.LIST_SERVICE_FEES_SET_SELECT_ALL;
  selectedFees: SelectedServiceFeeState[];
}

export interface ListServiceFeesReset {
  type: ListServiceFeesActionTypes.LIST_SERVICE_FEES_RESET;
}

export type ListServiceFeesAction =
  | ListServiceFees
  | ListServiceFeesSuccess
  | ListServiceFeesSetSelected
  | ListServiceFeesSetSelectAll
  | ListServiceFeesError
  | ListServiceFeesReset
  | ListServiceFeesErrorReset;

export function listServiceFees({
  currentPage = 1,
  filter,
  filterLabels,
  from = 0,
  size = 20,
}: listServiceFeesInput): ListServiceFees {
  return {
    type: ListServiceFeesActionTypes.LIST_SERVICE_FEES_REQUEST,
    currentPage,
    from,
    size,
    filter,
    filterLabels,
  };
}

export function listServiceFeesSucceeded(total, serviceFees): ListServiceFeesSuccess {
  return {
    type: ListServiceFeesActionTypes.LIST_SERVICE_FEES_SUCCESS,
    serviceFees,
    total,
  };
}

export function listServiceFeesFailed(error: Error): ListServiceFeesError {
  return {
    type: ListServiceFeesActionTypes.LIST_SERVICE_FEES_ERROR,
    error,
  };
}

export function listServiceFeesReset(): ListServiceFeesReset {
  return {
    type: ListServiceFeesActionTypes.LIST_SERVICE_FEES_RESET,
  };
}

export function listServiceFeesErrorReset(): ListServiceFeesErrorReset {
  return {
    type: ListServiceFeesActionTypes.LIST_SERVICE_FEES_ERROR_RESET,
  };
}

export function listServiceFeesSetSelected(selectedFee: SelectedServiceFeeState): ListServiceFeesSetSelected {
  return {
    type: ListServiceFeesActionTypes.LIST_SERVICE_FEES_SET_SELECTED,
    selectedFee,
  };
}

export function listServiceFeesSetSelectAll(selectedFees: SelectedServiceFeeState[]): ListServiceFeesSetSelectAll {
  return {
    type: ListServiceFeesActionTypes.LIST_SERVICE_FEES_SET_SELECT_ALL,
    selectedFees,
  };
}
