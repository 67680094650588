/* Local dependencies */
import { Account } from './types';

export enum ListAccountsActionTypes {
  LIST_ACCOUNTS_REQUEST = 'LIST_ACCOUNTS_REQUEST',
  LIST_ACCOUNTS_SUCCESS = 'LIST_ACCOUNTS_SUCCESS',
  LIST_ACCOUNTS_ERROR = 'LIST_ACCOUNTS_ERROR',
  RESET_SELECTED_ACCOUNT = 'RESET_SELECTED_ACCOUNT',
  SELECT_ACCOUNT = 'SELECT_ACCOUNT',
  SORT_ACCOUNT_BY = 'SORT_ACCOUNT_BY',
  RESET_ERROR = 'RESET_ERROR',
}

export enum AccountsType {
  CORPORATE = 'CORPORATE',
  PERSONAL = 'PERSONAL',
  ROOT = 'ROOT',
}

export interface ListAccountsFilter {
  type: AccountsType;
}

export interface ListAccounts {
  type: ListAccountsActionTypes.LIST_ACCOUNTS_REQUEST;
  currentPage?: number;
  filter?: ListAccountsFilter;
  searchString?: string;
}

export interface ListAccountsSuccess {
  type: ListAccountsActionTypes.LIST_ACCOUNTS_SUCCESS;
  accounts: Account[];
  total: number;
}

export interface ListAccountsError {
  type: ListAccountsActionTypes.LIST_ACCOUNTS_ERROR;
  error: Error;
}

export interface ResetListAccountsError {
  type: ListAccountsActionTypes.RESET_ERROR;
}

export interface ListAccountsSort {
  type: ListAccountsActionTypes.SORT_ACCOUNT_BY;
  field: string;
}

export interface SelectAccount {
  type: ListAccountsActionTypes.SELECT_ACCOUNT;
  account: Account;
}

export interface ResetSelectedAccount {
  type: ListAccountsActionTypes.RESET_SELECTED_ACCOUNT;
}

export const DEFAULT_ACCOUNT_TYPE: ListAccountsFilter = {
  type: AccountsType.PERSONAL,
};

export type ListAccountsAction =
  | ListAccounts
  | ListAccountsSuccess
  | ListAccountsError
  | SelectAccount
  | ResetSelectedAccount
  | ResetListAccountsError;

export function listAccounts(
  searchString: string = '',
  currentPage: number = 1,
  filter: ListAccountsFilter = DEFAULT_ACCOUNT_TYPE,
): ListAccounts {
  return {
    type: ListAccountsActionTypes.LIST_ACCOUNTS_REQUEST,
    searchString,
    currentPage,
    filter,
  };
}

export function listAccountsSucceeded(total, accounts): ListAccountsSuccess {
  return {
    type: ListAccountsActionTypes.LIST_ACCOUNTS_SUCCESS,
    accounts,
    total,
  };
}

export function listAccountsFailed(error: Error): ListAccountsError {
  return {
    type: ListAccountsActionTypes.LIST_ACCOUNTS_ERROR,
    error,
  };
}

export function sortAccountsBy(field: string) {
  return {
    type: ListAccountsActionTypes.SORT_ACCOUNT_BY,
    field,
  };
}

export function selectAccount(account: Account): SelectAccount {
  return {
    type: ListAccountsActionTypes.SELECT_ACCOUNT,
    account,
  };
}

export function resetSelectedAccount() {
  return {
    type: ListAccountsActionTypes.RESET_SELECTED_ACCOUNT,
  };
}

export function resetListAccountsError() {
  return {
    type: ListAccountsActionTypes.RESET_ERROR,
  };
}
