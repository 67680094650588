// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { RequiredServiceFieldInput } from '../../types';
import { UpdateServiceState } from '../../updateService/redux/reducer';
import {
  EmptyRequiredException,
  IdRequiredException,
  KeyboardTypeRequiredException,
  LabelRequiredException,
  LabelRequiredExceptionInEnglish,
  ValueRequiredException,
} from './exceptions';
import { CreateServiceState } from './reducer';

export function validateRequiredFieldsInputs(
  state: CreateServiceState | UpdateServiceState,
  action: RequiredServiceFieldInput,
) {
  const {
    fieldId,
    inputMask,
    label_en,
    label_ky,
    label_ru,
    maxLength,
    minLength,
    prefix,
    value,
    keyboardType,
    hasSpaceAfterPrefix,
  } = action.value;

  const change: any = {
    isFormChanged: {
      $set: true,
    },
  };

  if (action.value.hasOwnProperty('label_ky')) {
    let isErrorLabelKy = new EmptyRequiredException();

    if (!label_ky) {
      isErrorLabelKy = new LabelRequiredException();
    } else {
      state.service?.requiredFields?.map((item, index) => {
        if (index === action.index) {
          item.valueError = new EmptyRequiredException();
        }
      });
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          requiredFields: {
            $set: update(state.service?.requiredFields, {
              [action.index]: {
                label_ky: {
                  $set: action.value.label_ky,
                },
                value: {
                  $set: null,
                },
                labelKyError: {
                  $set: isErrorLabelKy,
                },
              },
            }),
          },
        }),
      },
    });
  }

  if (action.value.hasOwnProperty('label_ru')) {
    let isErrorLabelRu = new EmptyRequiredException();

    if (!label_ru) {
      isErrorLabelRu = new LabelRequiredException();
    } else {
      state.service?.requiredFields?.map((item, index) => {
        if (index === action.index) {
          item.valueError = new EmptyRequiredException();
        }
      });
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          requiredFields: {
            $set: update(state.service?.requiredFields, {
              [action.index]: {
                label_ru: {
                  $set: action.value.label_ru,
                },
                value: {
                  $set: null,
                },
                labelRuError: {
                  $set: isErrorLabelRu,
                },
              },
            }),
          },
        }),
      },
    });
  }

  if (action.value.hasOwnProperty('label_en')) {
    let isErrorLabelEn = new EmptyRequiredException();
    let newText = label_en.replace(/[^a-z0-9_-\s]+/i, '');

    if (!label_en) {
      isErrorLabelEn = new LabelRequiredExceptionInEnglish();
    } else {
      state.service?.requiredFields?.map((item, index) => {
        if (index === action.index) {
          item.valueError = new EmptyRequiredException();
        }
      });
    }
    if (!newText) {
      isErrorLabelEn = new LabelRequiredExceptionInEnglish();
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          requiredFields: {
            $set: update(state.service?.requiredFields, {
              [action.index]: {
                label_en: {
                  $set: newText,
                },
                value: {
                  $set: null,
                },
                labelEnError: {
                  $set: isErrorLabelEn,
                },
              },
            }),
          },
        }),
      },
    });
  }

  if (action.value.hasOwnProperty('fieldId')) {
    let isErrorFieldId = new EmptyRequiredException();

    if (!fieldId) {
      isErrorFieldId = new IdRequiredException();
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          requiredFields: {
            $set: update(state.service?.requiredFields, {
              [action.index]: {
                fieldId: {
                  $set: action.value.fieldId,
                },
                fieldIdError: {
                  $set: isErrorFieldId,
                },
              },
            }),
          },
        }),
      },
    });
  }

  if (action.value.hasOwnProperty('value')) {
    let isErrorValue = new EmptyRequiredException();

    if (!value) {
      isErrorValue = new ValueRequiredException();
    } else {
      state.service?.requiredFields?.map((item, index) => {
        if (index === action.index) {
          item.labelKyError = new EmptyRequiredException();
          item.labelRuError = new EmptyRequiredException();
          item.labelEnError = new EmptyRequiredException();
        }
      });
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          requiredFields: {
            $set: update(state.service?.requiredFields, {
              [action.index]: {
                value: {
                  $set: action.value.value,
                },
                valueError: {
                  $set: isErrorValue,
                },
                label_ru: {
                  $set: '',
                },
                label_ky: {
                  $set: '',
                },
                label_en: {
                  $set: '',
                },
                minLength: {
                  $set: null,
                },
                maxLength: {
                  $set: null,
                },
                inputMask: {
                  $set: '',
                },
                prefix: {
                  $set: '',
                },
              },
            }),
          },
        }),
      },
    });
  }

  if (action.value.hasOwnProperty('keyboardType')) {
    let isErrorKeyBoardType = new EmptyRequiredException();

    if (!keyboardType) {
      isErrorKeyBoardType = new KeyboardTypeRequiredException();
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          requiredFields: {
            $set: update(state.service?.requiredFields, {
              [action.index]: {
                keyboardType: {
                  $set: action.value.keyboardType,
                },
                keyboardTypeError: {
                  $set: isErrorKeyBoardType,
                },
              },
            }),
          },
        }),
      },
    });
  }

  if (action.value.hasOwnProperty('minLength')) {
    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          requiredFields: {
            $set: update(state.service?.requiredFields, {
              [action.index]: {
                minLength: {
                  $set: action.value.minLength,
                },
              },
            }),
          },
        }),
      },
    });
  }

  if (action.value.hasOwnProperty('maxLength')) {
    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          requiredFields: {
            $set: update(state.service?.requiredFields, {
              [action.index]: {
                maxLength: {
                  $set: action.value.maxLength,
                },
              },
            }),
          },
        }),
      },
    });
  }

  if (action.value.hasOwnProperty('inputMask')) {
    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          requiredFields: {
            $set: update(state.service?.requiredFields, {
              [action.index]: {
                inputMask: {
                  $set: action.value.inputMask,
                },
              },
            }),
          },
        }),
      },
    });
  }

  if (action.value.hasOwnProperty('prefix')) {
    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          requiredFields: {
            $set: update(state.service?.requiredFields, {
              [action.index]: {
                prefix: {
                  $set: action.value.prefix,
                },
              },
            }),
          },
        }),
      },
    });
  }

  if (action.value.hasOwnProperty('hasSpaceAfterPrefix')) {
    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          requiredFields: {
            $set: update(state.service?.requiredFields, {
              [action.index]: {
                hasSpaceAfterPrefix: {
                  $set: action.value.hasSpaceAfterPrefix,
                },
              },
            }),
          },
        }),
      },
    });
  }

  return state;
}
