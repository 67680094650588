/* External dependencies */
import { debounceTime, filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/averspay';
import {
  ListServiceEndpointsAction,
  ListServiceEndpointsActionTypes,
  ListServiceEndpointsSuccess,
  ListServiceEndpoints,
  listServiceEndpointsSucceeded,
  listServiceEndpointsFailed,
} from './actions';
import { listServiceEndpointsQuery } from './queries';

export default function listServiceEndpointsEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListServiceEndpointsAction) =>
        action.type === ListServiceEndpointsActionTypes.LIST_SERVICE_ENDPOINTS_REQUEST,
    ),
    debounceTime(500),
    switchMap((action: ListServiceEndpoints) => {
      return listServiceEndpoints(action, state$.value?.serviceEndpoints?.size).catch(listServiceEndpointsFailed);
    }),
  );
}

export async function listServiceEndpoints(
  { from, searchString }: ListServiceEndpoints,
  size: number,
): Promise<ListServiceEndpointsSuccess> {
  const graphQLClient = await  getClient();

  const {
    data: {
      listServiceEndpoints: { serviceEndpoints, total },
    },
  } = await graphQLClient.query({
    query: listServiceEndpointsQuery,
    variables: {
      input: {
        from,
        query: searchString,
        size,
      },
    },
  });

  return listServiceEndpointsSucceeded(total, serviceEndpoints);
}
