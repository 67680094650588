/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { CancelPaymentAction, CancelPaymentActionTypes } from './action';

export interface CancelPaymentsState {
  error?: Error;
  isSuccess: boolean;
  loading?: boolean;
}

export const initialCancelPaymentState: CancelPaymentsState = {
  isSuccess: false,
};

export default function cancelPaymentReducer(state = initialCancelPaymentState, action: CancelPaymentAction) {
  switch (action.type) {
    case CancelPaymentActionTypes.CANCEL_PAYMENT_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case CancelPaymentActionTypes.CANCEL_PAYMENT_SUCCESS:
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
      });

    case CancelPaymentActionTypes.CANCEL_PAYMENT_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case CancelPaymentActionTypes.RESET_CANCEL_PAYMENT_STATE:
      return update(state, {
        isSuccess: { $set: initialCancelPaymentState.isSuccess },
      });

    case CancelPaymentActionTypes.RESET_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    default:
      return state;
  }
}
