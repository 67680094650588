// External dependencies
import { filter, map } from 'rxjs/operators';

// Local dependencies
import { KeyboardType } from '../../../services/types';
import { updateServiceAddRequiredField } from '../../../services/updateService/redux/action';
import { CardType } from '../types';
import { CreateAnOrderAction, CreateAnOrderActionTypes, UpdateCreateAnOrderFields } from './actions';

export function addRequiredFieldsEpic(actions$, state$) {
  return actions$.pipe(
    filter((action: CreateAnOrderAction) => {
      if (
        action.type === CreateAnOrderActionTypes.UPDATE_CREATE_AN_ORDER_FIELDS &&
        action?.updates?.hasOwnProperty('cardType')
      ) {
        return true;
      }
    }),

    map((action: UpdateCreateAnOrderFields) => {
      if (action?.updates?.cardType === CardType.RUS) {
        return updateServiceAddRequiredField([
          {
            fieldId: 'minAmount',
            inputMask: null,
            keyboardType: KeyboardType.NUMBER,
            label_en: 'Minimum amount',
            label_ky: 'Мин. төлөм',
            label_ru: 'Мин. оплата',
            minLength: null,
            maxLength: null,
            prefix: null,
            value: 2000,
          },
          {
            fieldId: 'currency',
            inputMask: null,
            keyboardType: KeyboardType.TEXT,
            label_en: 'Currency',
            label_ky: 'Валюта',
            label_ru: 'Валюта',
            minLength: null,
            maxLength: null,
            prefix: null,
            value: 'RUB',
          },
        ]);
      }

      return { type: null };
    }),
  );
}
