/* External dependencies */
import gql from 'graphql-tag';

export const updateCategoryMutation = gql`
  mutation updateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      id
      name_en
      name_ky
      name_ru
    }
  }
`;
