/* Local dependencies */
import { DistributionFee, ListDistributionFeesFilterInput, SelectedDistributionFeesState } from '../../types';

export enum ListDistributionFeesActionTypes {
  LIST_DISTRIBUTION_FEES_REQUEST = 'LIST_DISTRIBUTION_FEES_REQUEST',
  LIST_DISTRIBUTION_FEES_SUCCESS = 'LIST_DISTRIBUTION_FEES_SUCCESS',
  LIST_DISTRIBUTION_FEES_ERROR = 'LIST_DISTRIBUTION_FEES_ERROR',
  DISTRIBUTION_FEES_RESET = 'DISTRIBUTION_FEES_RESET',
  LIST_DISTRIBUTION_FEES_RESET_ERROR = 'LIST_DISTRIBUTION_FEES_RESET_ERROR',
  LIST_DISTRIBUTION_FEES_SET_SELECTED = 'LIST_DISTRIBUTION_FEES_SET_SELECTED',
  LIST_DISTRIBUTION_FEES_SET_SELECT_ALL = 'LIST_DISTRIBUTION_FEES_SET_SELECT_ALL',
}

export const DEFAULT_DISTRIBUTION_FEES_FILTER: ListDistributionFeesFilterInput = {
  dealerId: '',
  serviceId: '',
  terminalId: '',
};

export interface ListDistributionFees {
  type: ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_REQUEST;
  filter?: ListDistributionFeesFilterInput;
}

export interface ListDistributionFeesSuccess {
  type: ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_SUCCESS;
  distributionFees: DistributionFee[];
  total: number;
}

export interface ListDistributionFeesError {
  type: ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_ERROR;
  error: Error;
}

export interface ListDistributionFeesResetError {
  type: ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_RESET_ERROR;
}

export interface ListDistributionFeesReset {
  type: ListDistributionFeesActionTypes.DISTRIBUTION_FEES_RESET;
}

export interface ListDistributionFeesSetSelected {
  type: ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_SET_SELECTED;
  selectedFee: SelectedDistributionFeesState;
}

export interface ListDistributionFeesSetSelectAll {
  type: ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_SET_SELECT_ALL;
  selectedDistributionFees: SelectedDistributionFeesState[];
}

export type ListDistributionFeesAction =
  | ListDistributionFees
  | ListDistributionFeesSuccess
  | ListDistributionFeesError
  | ListDistributionFeesReset
  | ListDistributionFeesResetError
  | ListDistributionFeesSetSelected
  | ListDistributionFeesSetSelectAll;

export function listDistributionFees(filter?: ListDistributionFeesFilterInput): ListDistributionFees {
  return {
    type: ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_REQUEST,
    filter,
  };
}

export function listDistributionFeesSucceeded(
  total: number,
  distributionFees: DistributionFee[],
): ListDistributionFeesSuccess {
  return {
    type: ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_SUCCESS,
    distributionFees,
    total,
  };
}

export function listDistributionFeesFailed(error: Error): ListDistributionFeesError {
  return {
    type: ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_ERROR,
    error,
  };
}

export function listDistributionFeesReset(): ListDistributionFeesReset {
  return {
    type: ListDistributionFeesActionTypes.DISTRIBUTION_FEES_RESET,
  };
}

export function listDistributionFeesErrorReset(): ListDistributionFeesResetError {
  return {
    type: ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_RESET_ERROR,
  };
}

export function listDistributionFeesSetSelected(
  selectedFee: SelectedDistributionFeesState,
): ListDistributionFeesSetSelected {
  return {
    type: ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_SET_SELECTED,
    selectedFee,
  };
}

export function listDistributionFeesSetSelectAll(
  selectedDistributionFees: SelectedDistributionFeesState[],
): ListDistributionFeesSetSelectAll {
  return {
    type: ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_SET_SELECT_ALL,
    selectedDistributionFees,
  };
}
