/* External dependencies */
import gql from 'graphql-tag';

export const getProfileQuery = gql`
  query getProfile($input: UserProfileInput!) {
    getProfile(input: $input) {
      id
      lastName
      firstName
      birthDate
      accounts {
        id
      }
      identificationStatus
      joinDate
      phone
      groupName
    }
  }
`;
