/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/averspay';
import {
  BlockServiceAction,
  BlockServiceActionTypes,
  BlockServiceSuccess,
  blockServiceError,
  blockServiceSuccessed,
} from './actions';
import { blockServiceMutation } from './mutation';

export default function blockServiceEpic(action$) {
  return action$.pipe(
    filter(
      (action: BlockServiceAction) =>
        action.type === BlockServiceActionTypes.BLOCK_SERVICE_REQUEST,
    ),
    switchMap((action: BlockServiceAction) =>
      blockService(action)
        .then(blockServiceSuccessed)
        .catch((error) => blockServiceError(error)),
    ),
  );
}

export async function blockService(action): Promise<BlockServiceSuccess> {
  const graphQLClient = await getClient();
  const { id } = action;

  const {
    data: { blockService },
  } = await graphQLClient.mutate({
    mutation: blockServiceMutation,
    variables: {
      input: {
        id,
      },
    },
  });

  return blockService;
}
