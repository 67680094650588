/* Local dependencies */
import { UIException } from '../../../../redux/exceptions';

export class CreateAnOrderAmountRequiredException extends UIException {
  code = 'CreateAnOrderAmountRequiredException';
}

export class CreateAnOrderEmailRequiredException extends UIException {
  code = 'CreateAnOrderEmailRequiredException';
}

export class CreateAnOrderServiceIdRequiredException extends UIException {
  code = 'CreateAnOrderServiceIdRequiredException';
}

export class CreateAnOrderFieldsRequiredException extends UIException {
  constructor(arg) {
    super();
    this.code = `CreateAnOrder${arg}RequiredException`;
  }
}

export class CreateAnOrderValidRequiredException extends UIException {
  code = 'CreateAnOrderValidRequiredException';
}

export class CreateAnOrderValidLengthRequiredException extends UIException {
  code = 'CreateAnOrderValidLengthRequiredException';
}
