/* External dependencies */
import gql from 'graphql-tag';

export const getTransactionsQueueMetricsQuery = gql`
  query getTransactionsQueueMetrics {
    getTransactionsQueueMetrics {
      dlqInProgressMessageCount
      dlqPendingMessageCount
      sqsInProgressMessageCount
      sqsPendingMessageCount
    }
  }
`;
