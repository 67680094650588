/* External dependencies */
import update from 'immutability-helper';

/*Local dependencies */
import { DeleteServiceFeesActionTypes, DeleteServiceFeesActions } from './actions';
import { DeleteServiceFeesResponse, SelectedServiceFees } from './types';

export interface DeleteServiceFeesStates {
  error?: Error;
  isConfirmationPopupDeleteServiceFees: boolean;
  isSuccess?: boolean;
  loading: boolean;
  payload?: DeleteServiceFeesResponse;
  selectedServiceFees: SelectedServiceFees[];
}

export const initialStates: DeleteServiceFeesStates = {
  loading: false,
  selectedServiceFees: [],
  isConfirmationPopupDeleteServiceFees: false,
};

export default function DeleteServiceFeesReducer(state = initialStates, action: DeleteServiceFeesActions) {
  switch (action.type) {
    case DeleteServiceFeesActionTypes.DELETE_SERVICE_FEES_REQUEST:
      return update(state, {
        $unset: ['error', 'payload'],
        loading: { $set: true },
        selectedServiceFees: { $set: action.selectedServiceFees },
      });

    case DeleteServiceFeesActionTypes.DELETE_SERVICE_FEES_SUCCESS:
      return update(state, {
        payload: { $set: action.payload },
        loading: { $set: false },
        isSuccess: { $set: true },
        isConfirmationPopupDeleteServiceFees: { $set: false },
      });

    case DeleteServiceFeesActionTypes.DELETE_SERVICE_FEES_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
        isSuccess: { $set: false },
        isConfirmationPopupDeleteServiceFees: { $set: false },
      });

    case DeleteServiceFeesActionTypes.DELETE_SERVICE_FEES_ERROR_RESET:
      return update(state, {
        $unset: ['error'],
      });

    case DeleteServiceFeesActionTypes.DELETE_SERVICE_FEES_RESET:
      return update(state, { $set: initialStates });

    case DeleteServiceFeesActionTypes.CONFIRMATION_SERVICE_FEES_POPUP:
      return update(state, {
        isConfirmationPopupDeleteServiceFees: { $set: action.state },
      });

    default:
      return initialStates;
  }
}
