// Local dependencies
import { FeesTypes } from '../../services/types';
import { MoneyInput, ServiceFeeStepInput } from '../types';

export enum ServiceFeesActionTypes {
  ADD_DYNAMIC_FEE = 'ADD_DYNAMIC_FEE',
  ADD_DYNAMIC_FEE_LIST = 'ADD_DYNAMIC_FEE_LIST',
  CHANGE_DYNAMIC_FEE = 'CHANGE_DYNAMIC_FEE',
  CHANGE_FLAT_FEE = 'CHANGE_FLAT_FEE',
  CHANGE_FLAT_FEE_PERCENT = 'CHANGE_FLAT_FEE_PERCENT',
  REMOVE_DYNAMIC_FEE = 'REMOVE_DYNAMIC_FEE',
  SELECT_SERVICE_FEE_TYPE = 'SELECT_SERVICE_FEE_TYPE',
  VALIDATE_ALL_FIELDS = 'VALIDATE_ALL_FIELDS',
  RESET_SERVICE_FEES = 'RESET_SERVICE_FEES',
}

export enum ReducerType {
  UPPER_FEE = 'UPPER_FEE',
  LOWER_FEE = 'LOWER_FEE',
}

export interface ResetServiceFees {
  type: ServiceFeesActionTypes.RESET_SERVICE_FEES;
  reducerType: ReducerType;
}

export interface SelectServiceType {
  type: ServiceFeesActionTypes.SELECT_SERVICE_FEE_TYPE;
  reducerType: ReducerType;
  serviceType: FeesTypes;
}

export interface RemoveDynamicFee {
  type: ServiceFeesActionTypes.REMOVE_DYNAMIC_FEE;
  reducerType: ReducerType;
  index: number;
}

export interface ValidateAllFields {
  type: ServiceFeesActionTypes.VALIDATE_ALL_FIELDS;
  reducerType: ReducerType;
}

export interface ChangeDynamicFee {
  type: ServiceFeesActionTypes.CHANGE_DYNAMIC_FEE;
  reducerType: ReducerType;
  index: number;
  fees: [string, any];
}

export interface ChangeFlatFee {
  type: ServiceFeesActionTypes.CHANGE_FLAT_FEE;
  reducerType: ReducerType;
  flatFee: MoneyInput;
}

export interface ChangeFlatFeePercent {
  type: ServiceFeesActionTypes.CHANGE_FLAT_FEE_PERCENT;
  reducerType: ReducerType;
  flatFeePercent: number | string;
}

export interface AddDynamicFee {
  type: ServiceFeesActionTypes.ADD_DYNAMIC_FEE;
  reducerType: ReducerType;
}

export interface AddDynamicFeeList {
  type: ServiceFeesActionTypes.ADD_DYNAMIC_FEE_LIST;
  list: ServiceFeeStepInput[];
  reducerType: ReducerType;
}

export function resetServiceFee(reducerType: ReducerType) {
  return {
    type: ServiceFeesActionTypes.RESET_SERVICE_FEES,
    reducerType,
  };
}

export function addDynamicFee(reducerType: ReducerType): AddDynamicFee {
  return {
    type: ServiceFeesActionTypes.ADD_DYNAMIC_FEE,
    reducerType,
  };
}

export function AddDynamicFeeList(list: ServiceFeeStepInput[], reducerType: ReducerType): AddDynamicFeeList {
  return {
    type: ServiceFeesActionTypes.ADD_DYNAMIC_FEE_LIST,
    list,
    reducerType,
  };
}

export function removeDynamicFee(index: number, reducerType: ReducerType): RemoveDynamicFee {
  return {
    type: ServiceFeesActionTypes.REMOVE_DYNAMIC_FEE,
    reducerType,
    index,
  };
}

export function validateAllFields(reducerType: ReducerType): ValidateAllFields {
  return {
    type: ServiceFeesActionTypes.VALIDATE_ALL_FIELDS,
    reducerType,
  };
}

export function changeDynamicFee(
  { index, fees }: Partial<ChangeDynamicFee>,
  reducerType: ReducerType,
): ChangeDynamicFee {
  return {
    type: ServiceFeesActionTypes.CHANGE_DYNAMIC_FEE,
    reducerType,
    fees,
    index,
  };
}

export function changeFlatFeePercent(flatFeePercent: number | string, reducerType: ReducerType): ChangeFlatFeePercent {
  return {
    type: ServiceFeesActionTypes.CHANGE_FLAT_FEE_PERCENT,
    reducerType,
    flatFeePercent,
  };
}

export function changeFlatFee(flatFee: MoneyInput, reducerType: ReducerType): ChangeFlatFee {
  return {
    type: ServiceFeesActionTypes.CHANGE_FLAT_FEE,
    reducerType,
    flatFee,
  };
}

export function selectServiceType(type: FeesTypes, reducerType: ReducerType): SelectServiceType {
  return {
    type: ServiceFeesActionTypes.SELECT_SERVICE_FEE_TYPE,
    reducerType,
    serviceType: type,
  };
}

export type ServiceFeesActions =
  | AddDynamicFee
  | ChangeDynamicFee
  | ChangeFlatFee
  | ChangeFlatFeePercent
  | RemoveDynamicFee
  | SelectServiceType
  | ValidateAllFields
  | AddDynamicFeeList
  | ResetServiceFees;
