/* External dependencies */
import gql from 'graphql-tag';

export const setDistributionFeesMutation = gql`
  mutation setDistributionFees($input: [SetDistributionFeesInput!]!) {
    setDistributionFees(input: $input) {
      id
    }
  }
`;
