// Local dependencies
import { getClient } from '../../../../clients/averspay';
import { cleanPayload } from '../../../common/helpers';
import { User } from '../../userTypes';
import {
  updateUserForAdminMutation,
  updateUserForComplianceManagerMutation,
  updateUserForManagerMutation,
  updateUserForOperatorMutation,
} from './mutations';

export async function updateUserForOperator(action): Promise<User> {
  const graphQLClient = await getClient();
  const { updateUserInput } = action;

  const {
    id,
    status,
    identificationDocument,
    identificationStatus,
    firstName,
    lastName,
    patronymic,
    birthDate,
    gender,
  } = updateUserInput;

  delete identificationDocument?.actionHistory;

  const {
    //@ts-ignore
    data: { updateUserForOperator },
  } = await graphQLClient.mutate({
    mutation: updateUserForOperatorMutation,
    variables: {
      input: cleanPayload({
        id,
        status,
        identificationDocument,
        identificationStatus,
        firstName,
        lastName,
        patronymic,
        birthDate,
        gender,
      }),
    },
  });

  return updateUserForOperator as User;
}

export async function updateUserForAdmin(action): Promise<User> {
  const graphQLClient = await getClient();
  const { updateUserInput } = action;

  const {
    id,
    groupName,
    status,
    personalData,
    identificationDocument,
    identificationStatus,
    firstName,
    lastName,
    patronymic,
    birthDate,
    gender,
  } = updateUserInput;
  delete identificationDocument?.actionHistory;

  const {
    //@ts-ignore
    data: { updateUser },
  } = await graphQLClient.mutate({
    mutation: updateUserForAdminMutation,
    variables: {
      input: cleanPayload({
        id,
        groupName,
        status,
        personalData,
        identificationDocument,
        identificationStatus,
        firstName,
        lastName,
        patronymic,
        birthDate,
        gender,
      }),
    },
  });

  return updateUser as User;
}

export async function updateUserForManager(action): Promise<User> {
  const graphQLClient = await getClient();
  const { updateUserInput } = action;

  const {
    id,
    status,
    identificationDocument,
    identificationStatus,
    firstName,
    lastName,
    patronymic,
    birthDate,
    gender,
  } = updateUserInput;

  delete identificationDocument?.actionHistory;

  const {
    //@ts-ignore
    data: { updateUserForManager },
  } = await graphQLClient.mutate({
    mutation: updateUserForManagerMutation,
    variables: {
      input: cleanPayload({
        id,
        status,
        identificationDocument,
        identificationStatus,
        firstName,
        lastName,
        patronymic,
        birthDate,
        gender,
      }),
    },
  });

  return updateUserForManager as User;
}

export async function updateUserForComplianceManager(action): Promise<User> {
  const graphQLClient = await getClient();
  const { updateUserInput } = action;

  const {
    id,
    status,
    identificationDocument,
    identificationStatus,
    firstName,
    lastName,
    patronymic,
    birthDate,
    gender,
  } = updateUserInput;

  delete identificationDocument?.actionHistory;

  const {
    //@ts-ignore
    data: { updateUserForComplianceManager },
  } = await graphQLClient.mutate({
    mutation: updateUserForComplianceManagerMutation,
    variables: {
      input: cleanPayload({
        id,
        status,
        identificationDocument,
        identificationStatus,
        firstName,
        lastName,
        patronymic,
        birthDate,
        gender,
      }),
    },
  });

  return updateUserForComplianceManager as User;
}
