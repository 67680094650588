/* Local dependencies */
import { UIException } from '../../../redux/exceptions';

export class AmountRequiredException extends UIException {
  code = 'AmountRequiredException';
}

export class DestinationAccountRequiredException extends UIException {
  code = 'DestinationAccountRequiredException';
}
export class SourceAccountRequiredException extends UIException {
  code = 'SourceAccountRequiredException';
}
