// Order matters. Will appear in the same order.
export enum UserRole {
  AVERSPAY_USER = 'User',
  ACCOUNTANT = 'Accountant',
  ADMIN = 'Admin',
  DEALER = 'Dealer',
  MANAGER = 'Manager',
  OPERATOR = 'Operator',
  USER = 'User',
  COMPLIANCE_MANAGER = 'ComplianceManager',
}

const roles = [
  UserRole.AVERSPAY_USER,
  UserRole.ACCOUNTANT,
  UserRole.ADMIN,
  UserRole.DEALER,
  UserRole.MANAGER,
  UserRole.OPERATOR,
  UserRole.USER,
  UserRole.COMPLIANCE_MANAGER,
];

export default roles;

export function extractRoleFromARN(arn: string): UserRole {
  // ARN of an IAM role in the following form
  // `arn:aws:iam::368080513008:role/Operator`.
  const roleRE = new RegExp(`^arn:[^:]+:iam::\\d+:role/.*?(${roles.join('|')})$`);
  const match = arn.match(roleRE);

  if (!match) {
    throw new Error('Could not determine the role of the user.');
  }

  return match[1] as UserRole;
}
