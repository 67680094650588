/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import {
  AccountIdRequiredException,
  IpRequiredException,
  ServiceNameFieldRequiredException,
  FormatRequiredException,
  MethodRequiredException,
  ProtocolRequiredException,
  PortRequiredException,
  UsernameRequiredException,
  PasswordRequiredException,
} from '../../createService/redux/exceptions';
import { CreateServiceEndpointFields } from '../../types';
import { CreateServiceEndpointState } from './reducer';

export function validateServiceEndpoint(state: CreateServiceEndpointState, fields: CreateServiceEndpointFields) {
  const { accountId, username, password, format, ip, method, name, port, protocol } = fields;

  const change: any = {
    isFormChanged: {
      $set: true,
    },
  };

  if (fields.hasOwnProperty('accountId')) {
    if (!accountId) {
      change.accountIdError = {
        $set: new AccountIdRequiredException(),
      };
    } else {
      change['$unset'] = ['accountIdError'];
    }

    return update(state, {
      ...change,
      serviceEndpoint: {
        $set: update(state.serviceEndpoint, {
          account: {
            $set: update(state.serviceEndpoint.account, {
              id: { $set: accountId },
            }),
          },
        }),
      },
    });
  }

  if (fields.hasOwnProperty('username')) {
    if (!username) {
      change.usernameError = {
        $set: new UsernameRequiredException(),
      };
    } else {
      change['$unset'] = ['usernameError'];
    }

    return update(state, {
      ...change,
      serviceEndpoint: {
        $set: update(state.serviceEndpoint, {
          auth: {
            $set: update(state.serviceEndpoint.auth, {
              username: { $set: username },
            }),
          },
        }),
      },
    });
  }

  if (fields.hasOwnProperty('password')) {
    if (!password) {
      change.passwordError = {
        $set: new PasswordRequiredException(),
      };
    } else {
      change['$unset'] = ['passwordError'];
    }

    return update(state, {
      ...change,
      serviceEndpoint: {
        $set: update(state.serviceEndpoint, {
          auth: {
            $set: update(state.serviceEndpoint.auth, {
              password: { $set: password },
            }),
          },
        }),
      },
    });
  }

  if (fields.hasOwnProperty('format')) {
    if (!format) {
      change.formatError = {
        $set: new FormatRequiredException(),
      };
    } else {
      change['$unset'] = ['formatError'];
    }

    return update(state, {
      ...change,
      serviceEndpoint: {
        $set: update(state.serviceEndpoint, {
          format: { $set: format },
        }),
      },
    });
  }

  if (fields.hasOwnProperty('method')) {
    if (!method) {
      change.methodError = {
        $set: new MethodRequiredException(),
      };
    } else {
      change['$unset'] = ['methodError'];
    }

    return update(state, {
      ...change,
      serviceEndpoint: {
        $set: update(state.serviceEndpoint, {
          method: { $set: method },
        }),
      },
    });
  }

  if (fields.hasOwnProperty('ip')) {
    if (!ip) {
      change.ipError = {
        $set: new IpRequiredException(),
      };
    } else {
      change['$unset'] = ['ipError'];
    }

    return update(state, {
      ...change,
      serviceEndpoint: {
        $set: update(state.serviceEndpoint, {
          ip: { $set: ip },
        }),
      },
    });
  }

  if (fields.hasOwnProperty('name')) {
    if (!name) {
      change.nameError = {
        $set: new ServiceNameFieldRequiredException(),
      };
    } else {
      change['$unset'] = ['nameError'];
    }

    return update(state, {
      ...change,
      serviceEndpoint: {
        $set: update(state.serviceEndpoint, {
          name: { $set: name },
        }),
      },
    });
  }

  if (fields.hasOwnProperty('port')) {
    return update(state, {
      ...change,
      serviceEndpoint: {
        $set: update(state.serviceEndpoint, {
          port: { $set: port },
        }),
      },
    });
  }

  if (fields.hasOwnProperty('protocol')) {
    if (!protocol) {
      change.protocolError = {
        $set: new ProtocolRequiredException(),
      };
    } else {
      change['$unset'] = ['protocolError'];
    }

    return update(state, {
      ...change,
      serviceEndpoint: {
        $set: update(state.serviceEndpoint, {
          protocol: { $set: protocol },
        }),
      },
    });
  }

  return state;
}
