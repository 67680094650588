/* External dependencies */
import gql from 'graphql-tag';

export const getAccountsBalanceQuery = gql`
  query getAccountsBalance {
    getAccountsBalance {
      amount
      createdDate
    }
  }
`;
