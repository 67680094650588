import { Money } from '../../services/types';

export enum CancelPaymentActionTypes {
  CANCEL_PAYMENT_REQUEST = 'CANCEL_PAYMENT_REQUEST',
  CANCEL_PAYMENT_SUCCESS = 'CANCEL_PAYMENT_SUCCESS',
  CANCEL_PAYMENT_ERROR = 'CANCEL_PAYMENT_ERROR',
  RESET_CANCEL_PAYMENT_STATE = 'RESET_CANCEL_PAYMENT_STATE',
  RESET_ERROR = 'RESET_ERROR',
}

export interface CancelPaymentRequest {
  type: CancelPaymentActionTypes.CANCEL_PAYMENT_REQUEST;
  id: string;
}

export interface CancelPaymentSuccess {
  type: CancelPaymentActionTypes.CANCEL_PAYMENT_SUCCESS;
}

export interface CancelPaymentError {
  type: CancelPaymentActionTypes.CANCEL_PAYMENT_ERROR;
  error: Error;
}

export interface ResetCancelPaymentState {
  type: CancelPaymentActionTypes.RESET_CANCEL_PAYMENT_STATE;
}

export interface ResetCancelPaymentError {
  type: CancelPaymentActionTypes.RESET_ERROR;
}

export type CancelPaymentAction =
  | CancelPaymentRequest
  | CancelPaymentSuccess
  | CancelPaymentError
  | ResetCancelPaymentState
  | ResetCancelPaymentError;

export function cancelPayment(id: string): CancelPaymentRequest {
  return {
    type: CancelPaymentActionTypes.CANCEL_PAYMENT_REQUEST,
    id,
  };
}

export function cancelPaymentSucceeded(): CancelPaymentSuccess {
  return {
    type: CancelPaymentActionTypes.CANCEL_PAYMENT_SUCCESS,
  };
}

export function cancelPaymentFailed(error: Error): CancelPaymentError {
  return {
    type: CancelPaymentActionTypes.CANCEL_PAYMENT_ERROR,
    error,
  };
}

export function resetPayment(): ResetCancelPaymentState {
  return {
    type: CancelPaymentActionTypes.RESET_CANCEL_PAYMENT_STATE,
  };
}

export function resetError(): ResetCancelPaymentError {
  return {
    type: CancelPaymentActionTypes.RESET_ERROR,
  };
}
