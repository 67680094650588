/* Local dependencies */
import { UIException } from '../../../redux/exceptions';

export class ServiceRequiredException extends UIException {
  code = 'ServiceRequiredException';
}

export class RecipientInvalidException extends UIException {
  code = 'RecipientInvalidException';
}
