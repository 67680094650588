/* External dependencies */
import update from 'immutability-helper';

/*Local dependencies */
import { ServiceFee } from '../../listServiceFees/types';
import { GetServiceFeesAction, GetServiceFeesActionTypes } from './actions';

export interface GetServiceFeesStates {
  error?: Error;
  loading: boolean;
  serviceFee: ServiceFee;
}

export const initialStates: GetServiceFeesStates = {
  loading: false,
  serviceFee: null,
};

export default function GetServiceFeesReducer(state = initialStates, action: GetServiceFeesAction) {
  switch (action.type) {
    case GetServiceFeesActionTypes.GET_SERVICE_FEES_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case GetServiceFeesActionTypes.GET_SERVICE_FEES_SUCCESS:
      return update(state, {
        loading: { $set: false },
        serviceFee: { $set: action.serviceFee },
      });

    case GetServiceFeesActionTypes.GET_SERVICE_FEES_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case GetServiceFeesActionTypes.GET_SERVICE_FEES_ERROR_RESET:
      return update(state, {
        $unset: ['error'],
      });

    case GetServiceFeesActionTypes.GET_SERVICE_FEES_RESET:
      return initialStates;

    default:
      return state;
  }
}
