// Local dependencies
import { DistributionFeesState } from './reducer';

export function validateDistributionFees(state: DistributionFeesState): DistributionFeesState {
  const { errors, distributionFees } = state;
  let validationPassed = true;

  const newErrors = {
    ...errors,
  };

  Object.keys(distributionFees).forEach((item) => {
    if (!distributionFees[item]) {
      newErrors[item] = 'EnterValidValue';
      validationPassed = false;
    } else {
      newErrors[item] = null;
    }
  });

  return { ...state, errors: { ...newErrors }, distributionFees: { ...distributionFees }, validationPassed };
}
