// External depedencies
import update from 'immutability-helper';
import { validateServiceFields } from '../../createService/redux/validate';

// Local dependencies
import { Category, UpdateCategory } from '../../types';
import { UpdateCategoryActions, UpdateCategoryActionType } from './action';

export interface UpdateCategoryState {
  error?: Error;
  loading: boolean;
  isSuccess: boolean;
  nameRuError?: Error;
  nameKyError?: Error;
  nameEnError?: Error;
  statusError?: Error;
  parentIdError?: Error;
  isFormChanged?: boolean;
  shouldShowConfirmationPopup?: boolean;
  service?: UpdateCategory | Category;
  popularityScoreError?: Error;
}

const updateCategoryInitialState: UpdateCategoryState = {
  loading: false,
  isSuccess: false,
  shouldShowConfirmationPopup: false,
};

export default function updateCategoryReducer(state = updateCategoryInitialState, action: UpdateCategoryActions) {
  switch (action.type) {
    case UpdateCategoryActionType.UPDATE_CATEGORY_REQUEST: {
      return update(state, {
        loading: { $set: true },
      });
    }

    case UpdateCategoryActionType.UPDATE_CATEGORY_SUCCESS: {
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
        service: { $set: action.category },
        isFormChanged: { $set: false },
        shouldShowConfirmationPopup: { $set: true },
      });
    }

    case UpdateCategoryActionType.UPDATE_CATEGORY_ERROR: {
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
        shouldShowConfirmationPopup: { $set: true },
      });
    }

    case UpdateCategoryActionType.UPDATE_CATEGORY_RESET: {
      return update(state, { $set: updateCategoryInitialState });
    }

    case UpdateCategoryActionType.UPDATE_CATEGORY_FIELDS:
      return validateServiceFields(state, action.updates);

    case UpdateCategoryActionType.GET_CATEGORY_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        isFormChanged: { $set: false },
      });

    case UpdateCategoryActionType.GET_CATEGORY_SUCCESS:
      return update(state, {
        loading: { $set: false },
        service: { $set: action.category },
      });

    case UpdateCategoryActionType.GET_CATEGORY_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case UpdateCategoryActionType.SHOW_CONFIRMATION_POPUP:
      return update(state, {
        shouldShowConfirmationPopup: { $set: true },
      });

    case UpdateCategoryActionType.HIDE_CONFIRMATION_POPUP:
      return update(state, {
        shouldShowConfirmationPopup: { $set: false },
      });

    default: {
      return state;
    }
  }
}
