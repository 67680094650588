/* Local dependencies */
import { Currency } from '../../accounts/listAccounts/types';

export interface CreateAnOrder {
  PaymentId: string;
  CardType: CardType;
  RedirectUrl: string;
  Amount: number;
  Email: string;
  Currency: Currency;
  ServiceId: string;
}

export interface CreateAnOrderFields {
  PaymentId?: string;
  CardType?: CardType;
  RedirectUrl?: string;
  Amount?: number;
  Email?: string;
  Currency?: string;
  ServiceId?: string;
}

export enum CardType {
  VISA = 'VISA',
  RUS = 'RUS',
  SBP = 'SBP',
}

export interface ServicePaymentPayload {
  cardType?: CardType;
  currency?: Currency;
  email: string;
  minAmount?: number;
  note?: string;
  transactionId: string;
  serviceId: string;
}

export interface ServiceFieldsInput {
  [key: string]: string | number;
}

export interface ServicePaymentPayloadFields {
  cardType?: string;
  email?: string;
  note?: string;
  transactionId?: string;
  totalWithoutComission?: string;
}
