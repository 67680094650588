/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/averspay';
import {
  RewirePaymentAction,
  RewirePaymentActionTypes,
  rewirePaymentSucceeded,
  rewirePaymentError,
  RewirePaymentSuccess,
} from './actions';
import { rewirePaymentMutation } from './mutations';

export default function rewirePaymentEpic(action$) {
  return action$.pipe(
    filter((action: RewirePaymentAction) => action.type === RewirePaymentActionTypes.REWIRE_PAYMENT_REQUEST),
    switchMap((action: RewirePaymentAction) =>
      rewirePayment(action)
        .then(rewirePaymentSucceeded)
        .catch((error) => rewirePaymentError(error)),
    ),
  );
}

export async function rewirePayment(action): Promise<RewirePaymentSuccess> {
  const graphQLClient = await getClient();
  const { id, newRecipient } = action;

  const {
    data: { rewirePayment },
  } = await graphQLClient.mutate({
    mutation: rewirePaymentMutation,
    variables: {
      input: {
        id,
        newRecipient,
      },
    },
  });

  return rewirePayment;
}
