// Local dependencies
import { AccountsBalance } from '../types';

export enum GetBalanceActionType {
  GET_ACCOUNT_BALANCE_ERROR = 'GET_ACCOUNT_BALANCE_ERROR',
  GET_ACCOUNT_BALANCE_ERROR_RESET = 'GET_ACCOUNT_BALANCE_ERROR_RESET',
  GET_ACCOUNT_BALANCE_REQUEST = 'GET_ACCOUNT_BALANCE_REQUEST',
  GET_ACCOUNT_BALANCE_RESET = 'GET_ACCOUNT_BALANCE_RESET',
  GET_ACCOUNT_BALANCE_SUCCESS = 'GET_ACCOUNT_BALANCE_SUCCESS',
}

export interface GetAccountBalanceRequest {
  type: GetBalanceActionType.GET_ACCOUNT_BALANCE_REQUEST;
}

export interface GetAccountBalanceSuccess {
  type: GetBalanceActionType.GET_ACCOUNT_BALANCE_SUCCESS;
  data: AccountsBalance;
}

export interface GetAccountBalanceError {
  type: GetBalanceActionType.GET_ACCOUNT_BALANCE_ERROR;
  error: Error;
}

export interface GetAccountBalanceReset {
  type: GetBalanceActionType.GET_ACCOUNT_BALANCE_RESET;
}

export interface GetAccountBalanceErrorReset {
  type: GetBalanceActionType.GET_ACCOUNT_BALANCE_ERROR_RESET;
}

export function getAccountsBalanceRequest(): GetAccountBalanceRequest {
  return {
    type: GetBalanceActionType.GET_ACCOUNT_BALANCE_REQUEST,
  };
}

export function getAccountsBalanceSuccess(data: AccountsBalance): GetAccountBalanceSuccess {
  return {
    type: GetBalanceActionType.GET_ACCOUNT_BALANCE_SUCCESS,
    data,
  };
}

export function getAccountsBalanceError(error: Error): GetAccountBalanceError {
  return {
    type: GetBalanceActionType.GET_ACCOUNT_BALANCE_ERROR,
    error,
  };
}

export function getAccountsBalanceReset(): GetAccountBalanceReset {
  return {
    type: GetBalanceActionType.GET_ACCOUNT_BALANCE_RESET,
  };
}

export function getAccountsBalanceErrorReset(): GetAccountBalanceErrorReset {
  return {
    type: GetBalanceActionType.GET_ACCOUNT_BALANCE_ERROR_RESET,
  };
}

export type GetBalanceActions =
  | GetAccountBalanceRequest
  | GetAccountBalanceSuccess
  | GetAccountBalanceError
  | GetAccountBalanceReset
  | GetAccountBalanceErrorReset;
