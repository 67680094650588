/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { ListServiceEndpoints, ServiceEndpoint } from '../types';
import { ListServiceEndpointsActionTypes, ListServiceEndpointsAction } from './actions';

export interface ServiceEndpointsState {
  error?: Error;
  loading?: boolean;
  from?: number;
  searchString?: string;
  serviceEndpoints: ServiceEndpoint[];
  size?: number;
  total: number;
}

const size: number = 20;

export const initialServiceEndpointsState: ServiceEndpointsState = {
  serviceEndpoints: [],
  loading: false,
  searchString: '',
  size,
  total: 0,
};

export default function serviceEndpointsReducer(
  state = initialServiceEndpointsState,
  action: ListServiceEndpointsAction,
) {
  switch (action.type) {
    case ListServiceEndpointsActionTypes.LIST_SERVICE_ENDPOINTS_REQUEST:
      const { from, searchString, size } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        searchString: { $set: searchString },
        from: { $set: from },
        size: { $set: size },
      });

    case ListServiceEndpointsActionTypes.LIST_SERVICE_ENDPOINTS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        serviceEndpoints: { $set: action.serviceEndpoints },
        total: { $set: action.total },
      });

    case ListServiceEndpointsActionTypes.LIST_SERVICE_ENDPOINTS_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case ListServiceEndpointsActionTypes.RESET_LIST_SERVICE_ENDPOINTS_STATE:
      return update(state, { $set: initialServiceEndpointsState });

    default:
      return state;
  }
}
