/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import {
  RewirePaymentAction,
  RewirePaymentActionTypes,
  UpdatePaymentField,
} from './actions';
import { validateRewireFields } from './validateRewireField';

export interface RewirePaymentState {
  error?: Error;
  isRewirePaymentOperation?: boolean;
  isRewirementSucceeded: boolean;
  loading?: boolean;
  recipientError?: Error;
  payment: UpdatePaymentField;
  serviceError?: Error;
}

const initialRewirePaymentState: RewirePaymentState = {
  isRewirementSucceeded: false,
  payment: {
    newRecipient: null
  },
};

export default function rewirePaymentReducer(
  state = initialRewirePaymentState,
  action: RewirePaymentAction,
) {
  switch (action.type) {
    case RewirePaymentActionTypes.REWIRE_PAYMENT_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case RewirePaymentActionTypes.REWIRE_PAYMENT_SUCCESS:
      return update(state, {
        loading: { $set: false },
        isRewirementSucceeded: { $set: true },
      });

    case RewirePaymentActionTypes.REWIRE_PAYMENT_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case RewirePaymentActionTypes.UPDATE_REWIRE_FIELDS:
      return validateRewireFields(state, action.updates);

    case RewirePaymentActionTypes.IS_REWIRE_PAYMENT:
      return update(state, {
        isRewirePaymentOperation: { $set: true },
        loading: { $set: false },
      });

    case RewirePaymentActionTypes.RESET_REWIRE_PAYMENT_STATE:
      return update(state, { $set: initialRewirePaymentState });

    default:
      return state;
  }
}
