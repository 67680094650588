/*External dependencies*/
import { filter, switchMap } from 'rxjs/operators';

/*Local dependencies*/
import { getClient } from '../../../../clients/averspay';
import { DeleteDistributionFeesResponse } from '../../types';
import {
  DeleteDistributionFees,
  DeleteDistributionFeesActionTypes,
  DeleteDistributionFeesActions,
  deleteDistributionFeesFailed,
  deleteDistributionFeesSuccess,
} from './actions';
import { deleteDistributionFeesMutation } from './mutation';

export default function deleteDistributionFeesEpic(action$) {
  return action$.pipe(
    filter(
      (action: DeleteDistributionFeesActions) =>
        action.type === DeleteDistributionFeesActionTypes.DELETE_DISTRIBUTION_FEES_REQUEST,
    ),
    switchMap((action: DeleteDistributionFees) =>
      deleteDistributionFees(action).then(deleteDistributionFeesSuccess).catch(deleteDistributionFeesFailed),
    ),
  );
}

export async function deleteDistributionFees({
  selectedDistributionFees,
}: DeleteDistributionFees): Promise<DeleteDistributionFeesResponse> {
  const graphQLClient = await getClient();

  const {
    data: {
      deleteDistributionFees: { statusMessage },
    },
  } = await graphQLClient.mutate({
    mutation: deleteDistributionFeesMutation,
    variables: {
      input: selectedDistributionFees,
    },
  });

  return statusMessage;
}
