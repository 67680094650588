/* External dependencies */
import { debounceTime, filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/averspay';
import {
  ListAccounts,
  ListAccountsAction,
  ListAccountsSuccess,
  ListAccountsActionTypes,
  listAccountsFailed,
  listAccountsSucceeded,
} from './actions';
import { listAccountsQuery } from './queries';

export default function listAccountsEpic(action$, state$) {
  return action$.pipe(
    filter((action: ListAccountsAction) => action.type === ListAccountsActionTypes.LIST_ACCOUNTS_REQUEST),
    debounceTime(500),
    switchMap((action: ListAccounts) =>
      listAccounts(action, state$.value.accounts.size).catch((error) => listAccountsFailed(error)),
    ),
  );
}

export async function listAccounts(
  { currentPage, searchString, filter }: ListAccounts,
  size: number,
): Promise<ListAccountsSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listAccounts: { total, accounts },
    },
  } = await graphQLClient.query({
    query: listAccountsQuery,
    variables: {
      input: {
        filter,
        from: (currentPage - 1) * size,
        query: searchString,
        size,
      },
    },
  });

  return listAccountsSucceeded(total, accounts);
}
