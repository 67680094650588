/* External dependencies */
import gql from 'graphql-tag';

export const rewirePaymentMutation = gql`
  mutation rewirePayment($input: RewirePaymentInput!) {
    rewirePayment(input: $input) {
      id
      money
      payer
      paymentDate
      recipient
      requestDate
      status
      service
      transactionDate
    }
  }
`;
