/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { BlockAccountAction, BlockAccountActionTypes } from './actions';

export interface BlockAccountState {
  error?: Error;
  isBlockAccountSuccess: boolean;
  loading?: boolean;
}

export const initialBlockAccountState: BlockAccountState = {
  isBlockAccountSuccess: false,
};

export default function blockAccountReducer(
  state = initialBlockAccountState,
  action: BlockAccountAction,
) {
  switch (action.type) {
    case BlockAccountActionTypes.BLOCK_ACCOUNT_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case BlockAccountActionTypes.BLOCK_ACCOUNT_SUCCESS:
      return update(state, {
        loading: { $set: false },
        isBlockAccountSuccess: { $set: true },
      });

    case BlockAccountActionTypes.BLOCK_ACCOUNT_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case BlockAccountActionTypes.RESET_BLOCK_ACCOUNT_STATE:
      return update(state, {
        $set: initialBlockAccountState,
      });

    default:
      return state;
  }
}
