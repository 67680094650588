/* External dependencies */
import gql from 'graphql-tag';

export const getServiceFeesQuery = gql`
  query getServiceFees($input: GetServiceFeeInput!) {
    getServiceFees(input: $input) {
      id
      serviceId
      entityType
      lower {
        flatFee {
          amount
          currency
        }
        flatFeePercent
        dynamicFeeSteps {
          fee {
            flatFee {
              amount
              currency
            }
            flatFeePercent
          }
          from {
            amount
            currency
          }
        }
      }
      upper {
        flatFee {
          amount
          currency
        }
        flatFeePercent
        dynamicFeeSteps {
          fee {
            flatFee {
              amount
              currency
            }
            flatFeePercent
          }
          from {
            amount
            currency
          }
        }
      }
    }
  }
`;
