/*External dependencies*/
import { filter, switchMap } from 'rxjs/operators';

/*Local dependencies*/
import { getClient } from '../../../clients/averspay';
import {
  DeleteServiceFees,
  DeleteServiceFeesActionTypes,
  DeleteServiceFeesActions,
  deleteServiceFeesFailed,
  deleteServiceFeesSuccess,
} from './actions';
import { deleteServiceFeesMutation } from './mutation';
import { DeleteServiceFeesResponse } from './types';

export default function deleteServiceFeesEpic(action$) {
  return action$.pipe(
    filter(
      (action: DeleteServiceFeesActions) => action.type === DeleteServiceFeesActionTypes.DELETE_SERVICE_FEES_REQUEST,
    ),
    switchMap((action: DeleteServiceFees) =>
      deleteServiceFees(action).then(deleteServiceFeesSuccess).catch(deleteServiceFeesFailed),
    ),
  );
}

export async function deleteServiceFees({
  selectedServiceFees,
}: DeleteServiceFees): Promise<DeleteServiceFeesResponse> {
  const graphQLClient = await getClient();

  const { data: response } = await graphQLClient.mutate({
    mutation: deleteServiceFeesMutation,
    variables: {
      input: selectedServiceFees,
    },
  });

  return response;
}
