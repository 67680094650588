// External dependencies
import { filter, switchMap } from 'rxjs';

// Local dependencies
import { getClient } from '../../../../clients/averspay';
import { cleanPayload } from '../../../common/helpers';
import { Category } from '../../types';
import {
  getCategoryFailed,
  GetCategoryRequest,
  getCategorySucceeded,
  UpdateCategoryActions,
  UpdateCategoryActionType,
  updateCategoryFailed,
  UpdateCategoryRequest,
  updateCategorySucceeded,
} from './action';
import { updateCategoryMutation } from './mutation';
import { getCategoryQuery } from './queries';

export function updateCategoryEpic(action$) {
  return action$.pipe(
    filter((action: UpdateCategoryActions) => action.type === UpdateCategoryActionType.UPDATE_CATEGORY_REQUEST),
    switchMap((action: UpdateCategoryRequest) =>
      updateCategory(action)
        .then(updateCategorySucceeded)
        .catch((error) => updateCategoryFailed(error)),
    ),
  );
}

export async function updateCategory({ category }: UpdateCategoryRequest): Promise<Category> {
  const graphQLClient = await getClient();

  const input = Object.keys(category).reduce((input, key) => {
    if (category.hasOwnProperty(key) && typeof category[key] !== 'undefined') {
      input[key] = category[key];
    }

    return input;
  }, {});

  const {
    data: { updateCategory },
  } = await graphQLClient.mutate({
    mutation: updateCategoryMutation,
    variables: {
      input: cleanPayload(input),
    },
  });

  return updateCategory as Category;
}

export function getCategoryEpic(action$) {
  return action$.pipe(
    filter((action: UpdateCategoryActions) => action.type === UpdateCategoryActionType.GET_CATEGORY_REQUEST),
    switchMap((action: GetCategoryRequest) =>
      getCategory(action)
        .then(getCategorySucceeded)
        .catch((error: Error) => getCategoryFailed(error)),
    ),
  );
}

export async function getCategory(action): Promise<Category> {
  const graphQLClient = await getClient();
  const { id } = action;

  const {
    data: { getCategory },
  } = await graphQLClient.query({
    query: getCategoryQuery,
    variables: { input: { id } },
  });

  return getCategory as Category;
}
