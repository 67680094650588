/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { createSlug } from '../../../common/helpers';
import { CreateCategoryState } from '../../createCategory/redux/reducer';
import {
  LogoRequiredException,
  NameRequiredException,
  NameRequiredExceptionInEnglish,
  ParentIdRequiredException,
} from '../../createService/redux/exceptions';
import { UpdateCategoryFields } from '../../types';
import { UpdateCategoryState } from '../../updateCategory/redux/reducer';

export function validateCategoryFields(
  state: UpdateCategoryState | CreateCategoryState,
  updates: UpdateCategoryFields,
) {
  const { name_en, name_ru, name_ky, logo, parentId } = updates;

  const change: any = {
    isFormChanged: {
      $set: true,
    },
  };

  if (updates.hasOwnProperty('parentId')) {
    if (!parentId) {
      change.parentIdError = {
        $set: new ParentIdRequiredException(),
      };
    } else {
      change['$unset'] = ['parentIdError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          parentId: { $set: parentId },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('name_ru')) {
    if (!name_ru) {
      change.nameRuError = {
        $set: new NameRequiredException(),
      };
    } else {
      change['$unset'] = ['nameRuError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          name_ru: { $set: name_ru.replace(/\s+/g, ' ') },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('name_ky')) {
    if (!name_ky) {
      change.nameKyError = {
        $set: new NameRequiredException(),
      };
    } else {
      change['$unset'] = ['nameKyError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          name_ky: { $set: name_ky.replace(/\s+/g, ' ') },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('name_en')) {
    let slug = createSlug(name_en);
    const newText = name_en.replace(/[^a-zA-Z0-9=\-_$&()\s]+/gi, '');

    if (!name_en) {
      change.nameEnError = {
        $set: new NameRequiredExceptionInEnglish(),
      };
    } else {
      change['$unset'] = ['nameEnError'];
    }

    if (!newText) {
      change.nameEnError = {
        $set: new NameRequiredExceptionInEnglish(),
      };
    } else {
      slug = createSlug(name_en);
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          name_en: { $set: newText },
          slug: { $set: slug },
        }),
      },
    });
  }

  if (updates.hasOwnProperty('logo')) {
    if (!logo) {
      change.logoError = {
        $set: new LogoRequiredException(),
      };
    } else {
      change['$unset'] = ['logoError'];
    }

    return update(state, {
      ...change,
      service: {
        $set: update(state.service, {
          logo: {
            $set: update(state.service.logo, {
              url: { $set: logo },
            }),
          },
        }),
      },
    });
  }

  return state;
}
