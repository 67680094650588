/* External dependencies */
import update from 'immutability-helper';

/*Local dependencies */
import { getId } from '../../../serviceFees/helper';
import { DistributionFee, ListDistributionFeesFilterInput, SelectedDistributionFeesState } from '../../types';
import {
  DEFAULT_DISTRIBUTION_FEES_FILTER,
  ListDistributionFeesAction,
  ListDistributionFeesActionTypes,
} from './actions';

export interface ListDistributionFeesStates {
  currentPage: number;
  error?: Error;
  filter?: ListDistributionFeesFilterInput;
  loading?: boolean;
  distributionFees: DistributionFee[];
  selectedDistributionFees: SelectedDistributionFeesState[];
  size: number;
  total: number;
}

const defaultSize: number = 20;

export const initialListDistributionFeesStates: ListDistributionFeesStates = {
  currentPage: 1,
  filter: DEFAULT_DISTRIBUTION_FEES_FILTER,
  loading: false,
  distributionFees: [],
  selectedDistributionFees: [],
  total: 0,
  size: defaultSize,
};

export default function listDistributionFeesReducer(
  state = initialListDistributionFeesStates,
  action: ListDistributionFeesAction,
) {
  switch (action.type) {
    case ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_REQUEST:
      const { filter } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        filter: { $set: filter },
        selectedDistributionFees: { $set: [] },
      });

    case ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_SUCCESS:
      return update(state, {
        loading: { $set: false },
        total: { $set: action.total },
        distributionFees: { $set: action.distributionFees },
      });

    case ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_ERROR:
      const { error } = action;

      return update(state, {
        loading: { $set: false },
        error: { $set: error },
      });

    case ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_RESET_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    case ListDistributionFeesActionTypes.DISTRIBUTION_FEES_RESET:
      return update(state, { $set: initialListDistributionFeesStates });

    case ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_SET_SELECTED:
      const { feeId, serviceId } = action.selectedFee;
      const id = getId({ id: feeId, serviceId });

      const updatedList = state.selectedDistributionFees.filter((item) => item.id !== id);

      if (updatedList.length === state.selectedDistributionFees.length) {
        updatedList.push({ id, feeId: feeId, serviceId });
      }

      return update(state, {
        selectedDistributionFees: { $set: updatedList },
      });

    case ListDistributionFeesActionTypes.LIST_DISTRIBUTION_FEES_SET_SELECT_ALL:
      const selectedFees = action.selectedDistributionFees.map(({ feeId, serviceId }) => ({
        id: getId({ id: feeId, serviceId }),
        feeId,
        serviceId,
      }));

      return update(state, {
        selectedDistributionFees: { $set: selectedFees },
      });

    default:
      return state;
  }
}
