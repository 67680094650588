// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { DistributionFees } from '../../../fees/distributionFees/types';
import { GetDistributionFeeInput } from '../../types';
import { DistributionFeesActions, GetDistributionFeesActionTypes } from './actions';

export interface DistributionFeesState {
  distributionFees: DistributionFees;
  error?: Error;
  input: GetDistributionFeeInput | null;
  isSuccess: boolean;
  loading: boolean;
}

export const distributionFeesInitialState: DistributionFeesState = {
  distributionFees: null,
  loading: false,
  isSuccess: false,
  input: null,
};

export default function GetServiceDistributionFeesReducer(
  state = distributionFeesInitialState,
  action: DistributionFeesActions,
) {
  switch (action.type) {
    case GetDistributionFeesActionTypes.GET_DISTRIBUTION_FEES_REQUEST:
      return update(state, {
        $unset: ['error'],
        distributionFees: { $set: null },
        input: { $set: action.input },
        isSuccess: { $set: false },
        loading: { $set: true },
      });

    case GetDistributionFeesActionTypes.GET_DISTRIBUTION_FEES_SUCCESS:
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
        distributionFees: { $set: action.payload },
      });

    case GetDistributionFeesActionTypes.GET_DISTRIBUTION_FEES_FAILURE:
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: false },
        error: { $set: action.error },
      });

    case GetDistributionFeesActionTypes.RESET_DISTRIBUTION_FEE_FAILURE:
      return update(state, {
        $unset: ['error'],
        isSuccess: { $set: false },
      });

    case GetDistributionFeesActionTypes.RESET_DISTRIBUTION_FEE:
      return distributionFeesInitialState;

    default:
      return state;
  }
}
