/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { CreateServiceEndpointInput } from '../../types';
import { CreateServiceEndpointActionTypes, CreateServiceEndpointAction } from './actions';
import { validateServiceEndpoint } from './validate';

export interface CreateServiceEndpointState {
  serviceEndpoint: CreateServiceEndpointInput;
  accountIdError?: Error;
  usernameError?: Error;
  passwordError?: Error;
  formatError?: Error;
  ipError?: Error;
  methodError?: Error;
  nameError?: Error;
  portError?: Error;
  protocolError?: Error;
  isSuccess?: boolean;
  loading?: boolean;
  error?: Error;
  shouldShowConfirmationPopup?: boolean;
  selectedDealer?: any;
  backPath?: string;
}

export const initialCreateServiceEndpointState: CreateServiceEndpointState = {
  serviceEndpoint: {
    account: {
      id: '',
    },
    auth: {
      username: '',
      password: '',
    },
    format: '',
    ip: '',
    method: '',
    name: '',
    port: null,
    protocol: '',
  },
  isSuccess: false,
  shouldShowConfirmationPopup: false,
};

export default function createServiceEndpointReducer(
  state = initialCreateServiceEndpointState,
  action: CreateServiceEndpointAction,
) {
  switch (action.type) {
    case CreateServiceEndpointActionTypes.CREATE_SERVICE_ENDPOINT_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case CreateServiceEndpointActionTypes.CREATE_SERVICE_ENDPOINT_SUCCESS:
      return update(state, {
        isSuccess: { $set: true },
        loading: { $set: false },
        serviceEndpoint: { $set: action.serviceEndpoint },
        shouldShowConfirmationPopup: { $set: true },
      });

    case CreateServiceEndpointActionTypes.CREATE_SERVICE_ENDPOINT_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
        shouldShowConfirmationPopup: { $set: true },
      });

    case CreateServiceEndpointActionTypes.RESET_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    case CreateServiceEndpointActionTypes.RESET_CREATE_SERVICE_ENDPOINT_STATE:
      return update(state, { $set: initialCreateServiceEndpointState });

    case CreateServiceEndpointActionTypes.ADD_SERVICE_ENDPOINT_FIELDS:
      return validateServiceEndpoint(state, action.fields);

    case CreateServiceEndpointActionTypes.SELECT_DEALER:
      return update(state, {
        selectedDealer: { $set: action.dealer },
        $unset: ['accountIdError'],
      });

    case CreateServiceEndpointActionTypes.ADD_BACK_PATH:
      return update(state, {
        backPath: { $set: action.backPath },
      });

    default:
      return state;
  }
}
