/* External dependencies */
import gql from 'graphql-tag';

export const getDistributionFeeQuery = gql`
  query getDistributionFee($input: GetDistributionFeeInput!) {
    getDistributionFee(input: $input) {
      id
      serviceId
      clientFeePercentage
      serviceRewardPercentage
      entityType
    }
  }
`;
