// External depedencies
import { filter, switchMap } from 'rxjs';

// Local depedencies
import { getClient } from '../../../../clients/averspay';
import { Category } from '../../types';
import {
  CreateCategoryAction,
  CreateCategoryActionTypes,
  CreateCategoryRequest,
  createCategoryFailed,
  createCategorySucceeded,
} from './action';
import { createCategoryMutation } from './mutation';

export default function createCategoryEpic(action$) {
  return action$.pipe(
    filter((action: CreateCategoryAction) => action.type === CreateCategoryActionTypes.CREATE_CATEGORY_REQUEST),
    switchMap((action: CreateCategoryRequest) =>
      createCategory(action)
        .then(createCategorySucceeded)
        .catch((error) => createCategoryFailed(error)),
    ),
  );
}

export async function createCategory(action: CreateCategoryRequest): Promise<Category> {
  const graphQLClient = await getClient();
  const { category } = action;

  const {
    data: { createCategory },
  } = await graphQLClient.mutate({
    mutation: createCategoryMutation,
    variables: {
      input: category,
    },
  });

  return createCategory as Category;
}
