// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { Account } from '../../accounts/listAccounts/types';
import { User } from '../userTypes';
import { GetProfileAction, GetProfileActionTypes } from './actions';

export interface GetProfileState {
  error?: Error;
  loading: boolean;
  selectedAccounts?: Account[];
  user?: User;
}

export const initialGetProfileState: GetProfileState = {
  loading: false,
};

export default function getProfileReducer(state = initialGetProfileState, action: GetProfileAction) {
  switch (action.type) {
    case GetProfileActionTypes.GET_PROFILE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case GetProfileActionTypes.GET_PROFILE_SUCCESS:
      return update(state, {
        user: { $set: action.user },
        loading: { $set: false },
      });

    case GetProfileActionTypes.GET_PROFILE_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case GetProfileActionTypes.SELECTED_ACCOUNT:
      return update(state, {
        selectedAccounts: { $set: [action.account] },
      });

    default:
      return state;
  }
}
