// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { User } from '../userTypes';
import {
  DEFAULT_USER_FILTER,
  ListUsersAction,
  ListUsersActionTypes,
  ListUsersFilter,
  UserSelectionType,
} from './actions';

export interface UsersState {
  currentPage: number;
  error?: Error;
  loading: boolean;
  isConfirmationPopupOpen: boolean;
  users: User[];
  searchString: string;
  filter?: ListUsersFilter;
  selectedUsers: Array<any>;
  size: number;
  total: number;
}

const size: number = 20;

export const initialUsersState: UsersState = {
  currentPage: 1,
  filter: DEFAULT_USER_FILTER,
  isConfirmationPopupOpen: false,
  loading: false,
  users: [],
  searchString: '',
  selectedUsers: [],
  size,
  total: 0,
};

export default function usersReducer(state = initialUsersState, action: ListUsersAction) {
  switch (action.type) {
    case ListUsersActionTypes.LIST_USERS_REQUEST:
    case ListUsersActionTypes.LIST_USERS_REQUEST_MANAGER:
      const { currentPage, searchString, filter, size } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        currentPage: { $set: currentPage },
        selectedUsers: { $set: [] },
        searchString: { $set: searchString },
        filter: { $set: filter },
        size: { $set: size },
      });

    case ListUsersActionTypes.LIST_USERS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        users: { $set: action.users },
        total: { $set: action.total },
      });

    case ListUsersActionTypes.LIST_USERS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case ListUsersActionTypes.RESET_LIST_USERS:
      return update(state, { $set: initialUsersState });

    case ListUsersActionTypes.SELECT_USER:
      if (action.selectionType === UserSelectionType.SINGLE) {
        return update(state, {
          selectedUsers: { $set: [action.user] },
        });
      }

      const selectedUsers = state.selectedUsers.filter((item) => {
        return item.id !== action.user.id;
      });

      if (selectedUsers.length < state.selectedUsers.length) {
        return update(state, {
          selectedUsers: { $set: selectedUsers },
        });
      }

      return update(state, {
        selectedUsers: { $push: [action.user] },
      });

    case ListUsersActionTypes.RESET_SELECTED_USER:
      return update(state, {
        selectedUsers: { $set: initialUsersState.selectedUsers },
      });

    case ListUsersActionTypes.RESET_LIST_USERS_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    case ListUsersActionTypes.OPEN_IS_POPUP:
      return update(state, {
        isConfirmationPopupOpen: { $set: action.value },
        loading: { $set: false },
      });

    case ListUsersActionTypes.RESET_USERS_ERROR:
      return update(state, { $unset: ['error'] });

    default:
      return state;
  }
}
