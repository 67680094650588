// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { GetServiceRecipientAction, GetServiceRecipientActionTypes } from './actions';
import { ServiceRecipientInput } from './types';

export interface GetServiceRecipientState {
  isSuccess: boolean;
  error?: Error;
  serviceRecipientData: ServiceRecipientInput;
  loading: boolean;
}

export const GetServiceRecipientInitialState: GetServiceRecipientState = {
  isSuccess: false,
  serviceRecipientData: null,
  loading: false,
};

export default function getServiceRecipientReducer(
  state = GetServiceRecipientInitialState,
  action: GetServiceRecipientAction,
) {
  switch (action.type) {
    case GetServiceRecipientActionTypes.GET_SERVICE_RECIPIENT_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case GetServiceRecipientActionTypes.GET_SERVICE_RECIPIENT_SUCCESS:
      return update(state, {
        isSuccess: { $set: true },
        loading: { $set: false },
        serviceRecipientData: { $set: action.serviceRecipientData },
      });

    case GetServiceRecipientActionTypes.GET_SERVICE_RECIPIENT_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case GetServiceRecipientActionTypes.RESET_GET_SERVICE_RECIPIENT:
      return update(state, {
        $set: GetServiceRecipientInitialState,
      });

    default:
      return state;
  }
}
