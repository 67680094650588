/* Local dependencies */
import { FeeEntity } from './serviceFees/types';
import { ReducerType } from './redux/actions';

export interface MoneyInput {
  amount: number | string;
  currency: Currency;
}

export enum Currency {
  KGS = 'KGS',
  RUB = 'RUB',
  USD = 'USD',
}

export interface ServiceFeeStepInput {
  from: MoneyInput;
  fee: ServiceStepFees;
}

interface ServiceStepFees {
  flatFee?: MoneyInput;
  flatFeePercent?: number;
}

export interface FeeProps {
  reducerType: ReducerType;
  reducerName: string;
}

export const entities = [FeeEntity.DEALER, FeeEntity.TERMINAL, FeeEntity.SERVICE];
