// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { Currency } from '../../../services/types';
import { ServicePaymentPayload } from '../types';
import { CreateAnOrderAction, CreateAnOrderActionTypes } from './actions';
import { validateCreateAnOrderFields } from './validateCreateAnOrder';

export interface CreateAnOrderState {
  error?: Error;
  servicePaymentPayload: ServicePaymentPayload;
  isSuccess: boolean;
  loading: boolean;
  inputFields?: any[];
  isClickButton?: boolean;
  totalWithoutComission?: string | number;
  isCheckCard?: boolean;
}

export const CreateAnOrderInitialState: CreateAnOrderState = {
  servicePaymentPayload: {
    email: '',
    note: '',
    transactionId: '',
    serviceId: '',
    cardType: null,
  },
  isSuccess: false,
  loading: false,
  isClickButton: false,
  isCheckCard: false,
};

export default function createAnOrderReducer(state = CreateAnOrderInitialState, action: CreateAnOrderAction) {
  switch (action.type) {
    case CreateAnOrderActionTypes.ADD_PAYMENT_PAYLOAD_FIELDS:
      return update(state, {
        servicePaymentPayload: { $merge: action.fields },
      });

    case CreateAnOrderActionTypes.ADD_UI_INPUT_FIELDS:
      return update(state, {
        inputFields: { $set: action.inputFields },
      });

    case CreateAnOrderActionTypes.CREATE_AN_ORDER_SUCCESS:
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
      });

    case CreateAnOrderActionTypes.CREATE_AN_ORDER_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case CreateAnOrderActionTypes.UPDATE_CREATE_AN_ORDER_FIELDS:
      return validateCreateAnOrderFields(state, action);

    case CreateAnOrderActionTypes.RESET_IS_CLICK_BUTTON:
      return update(state, {
        isClickButton: { $set: false },
        $unset: ['error'],
      });

    case CreateAnOrderActionTypes.RESET_CREATE_AN_ORDER:
      return update(state, {
        $set: CreateAnOrderInitialState,
      });

    case CreateAnOrderActionTypes.ADD_MIN_AMOUNT_CURRENCY_FIELDS:
      return update(state, {
        servicePaymentPayload: {
          $set: update(state.servicePaymentPayload, {
            currency: { $set: Currency.RUB },
            minAmount: { $set: 2000 },
          }),
        },
      });

    default:
      return state;
  }
}
