// External depedencies
import update from 'immutability-helper';

// Local dependencies
import { TerminalTechnician, Terminal } from '../../types';
import { UpdateTerminalActions, UpdateTerminalActionType } from './action';
import { validateTerminalFields } from './validation';

export interface UpdateTerminalState {
  error?: Error;
  loading: boolean;
  isSuccess: boolean;
  addressError?: Error;
  nameError?: Error;
  isFormChanged?: boolean;
  shouldShowConfirmationPopup?: boolean;
  terminal?: Terminal;
}

const updateTerminalInitialState: UpdateTerminalState = {
  loading: false,
  isSuccess: false,
  shouldShowConfirmationPopup: false,
};

export default function updateTerminalReducer(state = updateTerminalInitialState, action: UpdateTerminalActions) {
  switch (action.type) {
    case UpdateTerminalActionType.UPDATE_TERMINAL_REQUEST:
      return update(state, {
        loading: { $set: true },
        shouldShowConfirmationPopup: { $set: false },
      });

    case UpdateTerminalActionType.UPDATE_TERMINAL_SUCCESS:
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
        terminal: { $set: action.terminal },
        isFormChanged: { $set: false },
      });

    case UpdateTerminalActionType.UPDATE_TERMINAL_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case UpdateTerminalActionType.UPDATE_TERMINAL_FIELDS:
      return validateTerminalFields(state, action.updates);

    case UpdateTerminalActionType.GET_TERMINAL_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        isFormChanged: { $set: false },
      });

    case UpdateTerminalActionType.GET_TERMINAL_SUCCESS:
      return update(state, {
        loading: { $set: false },
        terminal: { $set: action.terminal },
      });

    case UpdateTerminalActionType.GET_TERMINAL_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case UpdateTerminalActionType.SHOW_CONFIRMATION_POPUP:
      return update(state, {
        shouldShowConfirmationPopup: { $set: true },
      });

    case UpdateTerminalActionType.HIDE_CONFIRMATION_POPUP:
      return update(state, {
        shouldShowConfirmationPopup: { $set: false },
      });

    case UpdateTerminalActionType.UPDATE_TERMINAL_RESET_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    case UpdateTerminalActionType.UPDATE_TERMINAL_RESET:
      return update(state, { $set: updateTerminalInitialState });

    default: {
      return state;
    }
  }
}
