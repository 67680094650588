/* Local dependencies */

import { returnNameService } from '../../services/helper';
import { Currency, KeyboardType } from '../../services/types';
import { CreateAnOrderAction, addPaymentPayloadFileds, addUiInputFileds } from './redux/actions';
import { CardType } from './types';

export function isMoneyValue(name: string) {
  return name === 'total' || name === 'amount';
}

export enum ValidationMessages {
  NOT_VALID = 'NOT_VALID',
  VALID = 'VALID',
}

export function validateEmail(email: string) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailPattern.test(email);
}

export function validateForNumberType(key: string, value: any, helpersObj: any) {
  const minLength = helpersObj?.minLength;
  const maxLength = helpersObj?.maxLength;

  const stringValue = value.toString().replace(/[^0-9]/g, '');

  if (helpersObj?.keyboardType === 'MONEY') {
    if (minLength && +value < minLength) {
      return false;
    }
    if (maxLength && +value > maxLength) {
      return false;
    }
  } else {
    if (minLength && stringValue.length < minLength) {
      return false;
    }

    if (maxLength && stringValue.length > maxLength) {
      return false;
    }
  }

  return true;
}

export function calculateFeePercent(cardType: CardType, currency: Currency): number {
  const fees = {
    common: {
      [CardType.VISA]: 2,
      [CardType.RUS]: 2.4,
      [CardType.SBP]: 1.2,
    },
    russia: {
      [CardType.VISA]: 3.7,
      [CardType.RUS]: 4.1,
      [CardType.SBP]: 2.9,
    },
    crypto: {
      [CardType.VISA]: 2.15,
      [CardType.RUS]: 2.55,
      [CardType.SBP]: 1.9,
    },
  };

  let feeCategory = 'common';
  /* Банковские карты РФ */
  if (currency === Currency.RUB) {
    feeCategory = 'russia';
  } /* ORIENT or MegaCrypto */
  // else if (serviceId === 'cryptoService') {
  //   feeCategory = 'crypto';
  // }

  const feePercentage = fees[feeCategory][cardType];

  return feePercentage;
}

export function calculateTotalWithFee(amount: number, percent: number) {
  const percentValue = (percent / 100) * amount;
  const total = amount + percentValue;

  return total;
}

export const errorMessages = {
  'An unexpected error has occurred.': 'errorOccurred',
  InternalServerException: 'internalServerException',
  'An invalid amount is provided. Amount must be greater than 0.': 'amountMustBeGreater',
  'An invalid currency is provided.': 'invalidCurrency',
  'An unrecognizable card type is provided.': 'unrecognizableCardType',
  'Missing required query string parameters': 'missingStringParameters',
  'Payment is invalid or has already been processed.': 'paymentIsInvalid',
  'Email is required.': 'emailIsRequired',
  'A positive amount must be provided.': 'positiveAmount',
  'Internal Server Error': 'internalServerException',
  'Insufficient funds': 'insufficientFunds',
  'Network Error': 'errorOccurred',
  'Service provider is unavailable.': 'serviceProviderIsUnavailable.',
  'Service is temporarily unavailable.': 'serviceIsTemporarilyUnavailable',
  'User does not exist.': 'userDoesNotExist',
};

export enum PaymentStep {
  SERVICES = 'SERVICES',
  SELECT_TYPE = 'SELECT_TYPE',
  FILL_THE_FORM = 'FILL_THE_FORM',
  CHECK_REQUISIT = 'CHECK_REQUISIT',
}

export function getFields(
  requiredFields: any[],
  cardType: CardType,
  language: string,
  dispatch: (data: CreateAnOrderAction) => void,
) {
  if (cardType !== CardType.VISA) {
    requiredFields = addSenderData(requiredFields);
  }

  const arrayOfUiInputs = [];

  for (let item of requiredFields) {
    const localeName = returnNameService(language, item);
    const inputKeyboardType =
      item?.keyboardType === KeyboardType.NUMBER ||
      item?.keyboardType === KeyboardType.MONEY ||
      item?.keyboardType === KeyboardType.PHONE
        ? 'number'
        : 'string';

    const inputValueType = inputKeyboardType === 'number' ? null : '';
    let inpValue = item?.value ? item?.value : inputValueType;
    if (item?.fieldId === 'currency') {
      inpValue = Currency.RUB;
    } else if (item?.fieldId === 'minAmount') {
      inpValue = 2000;
    }
    const isHidden =
      item?.value ||
      item?.fieldId === 'transactionId' ||
      item?.fieldId === 'serviceId' ||
      item?.fieldId === 'cardType' ||
      item?.fieldId === 'currency' ||
      item?.fieldId === 'minAmount'
        ? true
        : false;
    const field = { [item?.fieldId]: inpValue };

    const inputMaskWithoutSpaceAndDash = item?.inputMask?.replace(/[\s-]/g, '');

    const allowSpace = item?.allowSpace || false;

    const uiField = {
      inputLabel: localeName,
      inputMask: item?.inputMask,
      inputName: item?.fieldId,
      inputType: inputKeyboardType,
      inputValue: inpValue,
      isHidden: isHidden,
      keyboardType: item?.keyboardType,
      minLength: item?.minLength ? item?.minLength : inputMaskWithoutSpaceAndDash?.length,
      maxLength: item?.maxLength,
      placeholder: localeName,
      prefix: item?.prefix,
      required: true,
      disabled: item.fieldId === 'minAmount' || (item.fieldId === 'currency' && true),
      allowSpace,
    };

    arrayOfUiInputs.push(uiField);

    dispatch(addPaymentPayloadFileds(field));
  }

  if (arrayOfUiInputs.length) {
    dispatch(addUiInputFileds(arrayOfUiInputs));
  }
}

function addSenderData(requiredFields) {
  return [
    ...requiredFields,
    {
      fieldId: 'sender_first_name',
      inputMask: null,
      keyboardType: KeyboardType.TEXT,
      label_en: 'Sender name',
      label_ky: 'Жөнөтүүчүнүн аты',
      label_ru: 'Имя отправителя',
      minLength: 2,
      maxLength: null,
      prefix: null,
      value: null,
    },
    {
      fieldId: 'sender_last_name',
      inputMask: null,
      keyboardType: KeyboardType.TEXT,
      label_en: 'Sender surname',
      label_ky: 'Жөнөтүүчүнүн фамилиясы',
      label_ru: 'Фамилия отправителя',
      minLength: 2,
      maxLength: null,
      prefix: null,
      value: null,
      allowSpace: true,
    },
    {
      fieldId: 'sender_middle_name',
      inputMask: null,
      keyboardType: KeyboardType.TEXT,
      label_en: 'Sender middle name',
      label_ky: 'Жөнөтүүчүнүн атасынын аты',
      label_ru: 'Отчество отправителя',
      minLength: null,
      maxLength: null,
      prefix: null,
      value: null,
    },
    {
      fieldId: 'sender_pin',
      inputMask: null,
      keyboardType: KeyboardType.TEXT,
      label_en: "Sender's passport details",
      label_ky: 'Жөнөтүүчүнүн паспортунун реквизиттери',
      label_ru: 'Паспортные данные отправителя',
      minLength: null,
      maxLength: null,
      prefix: null,
      value: null,
    },
  ];
}

export const inputStyleText = {
  className: 'input',
  focusbg: 'none',
  focusBorderColor: 'none',
  bg: 'transparent',
  h: '40px',
  textSize: 'textForButtonMobile',
  textWeight: '400',
  border: '0',
  p: '0',
  textAlign: { sm: 'right' },
  style: { cursor: 'auto' },
};

export const labelStyleText = {
  textSize: 'infoText',
  d: 'block',
  textColor: '#9EA5B1',
};
