// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { ServiceFeesActionTypes, SetServiceFeesActions } from './actions';

const setServiceFeesInitialState = {
  serviceFees: {
    id: null,
    upper: null,
    lower: null,
    serviceId: null,
  },
  loading: false,
  error: null,
  isSuccess: false,
};

export default function setServiceFeesReducer(state = setServiceFeesInitialState, action: SetServiceFeesActions) {
  switch (action.type) {
    case ServiceFeesActionTypes.CREATE_SERVICE_FEES_REQUEST: {
      return update(state, {
        loading: { $set: true },
      });
    }

    case ServiceFeesActionTypes.CREATE_SERVICE_FEES_SUCCESS: {
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
        serviceFees: { $set: action.result },
      });
    }

    case ServiceFeesActionTypes.CREATE_SERVICE_FEES_FAILURE: {
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    }

    case ServiceFeesActionTypes.CHANGE_SERVICE_FEES_FIELDS: {
      const [propertyName, propertyValue] = action.data;

      return update(state, {
        serviceFees: {
          [propertyName]: { $set: propertyValue },
        },
      });
    }

    case ServiceFeesActionTypes.RESET_SERVICE_FEES: {
      return setServiceFeesInitialState;
    }

    case ServiceFeesActionTypes.RESET_SERVICE_FEES_ERROR: {
      return update(state, { $unset: ['error'] });
    }

    default: {
      return state;
    }
  }
}
