/* External dependencies */
import gql from 'graphql-tag';

export const listTechniciansQuery = gql`
  query listTechnicians($input: ListPublicUsersInput!) {
    listTechnicians(input: $input) {
      total
      users {
        devicesCount
        firstName
        id
        lastName
        phone
        role
      }
    }
  }
`;
