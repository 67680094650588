// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { Category, CreateCategory } from '../../types';
import { CreateCategoryAction, CreateCategoryActionTypes } from './action';
import { validateCategoryFields } from './validate';

export interface CreateCategoryState {
  service?: Category | CreateCategory;
  isSuccess: boolean;
  error?: Error;
  loading: boolean;
  nameEnError?: Error;
  nameRuError?: Error;
  nameKyError?: Error;
  parentIdError?: Error;
  logoError?: Error;
  isConfirmationPopupOpen?: boolean;
}

const CreateCategoryInitialState: CreateCategoryState = {
  service: {
    logo: {
      url: '',
    },
    name_en: '',
    name_ky: '',
    name_ru: '',
    slug: '',
    parentId: '',
  },
  isConfirmationPopupOpen: false,
  isSuccess: false,
  loading: false,
};

export default function createCategoryReducer(state = CreateCategoryInitialState, action: CreateCategoryAction) {
  switch (action.type) {
    case CreateCategoryActionTypes.CREATE_CATEGORY_REQUEST: {
      return update(state, {
        loading: { $set: true },
      });
    }

    case CreateCategoryActionTypes.CREATE_CATEGORY_SUCCESS: {
      return update(state, {
        loading: { $set: false },
        service: { $set: action.category },
        isSuccess: { $set: true },
      });
    }

    case CreateCategoryActionTypes.CREATE_CATEGORY_ERROR: {
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    }

    case CreateCategoryActionTypes.UPDATE_CREATE_CATEGORY_FIELDS:
      return validateCategoryFields(state, action.updates);

    case CreateCategoryActionTypes.RESET_CREATE_CATEGORY: {
      return CreateCategoryInitialState;
    }

    case CreateCategoryActionTypes.IS_CONFIRMATION_POPUP_OPEN_CATEGORY: {
      return update(state, {
        isConfirmationPopupOpen: { $set: action.value },
      });
    }

    default: {
      return state;
    }
  }
}
