/* Local dependencies */
import { AccountsType, ListAccountsFilter } from '../listAccounts/actions';
import { Account } from '../listAccounts/types';

export enum ListRootAccountsActionTypes {
  LIST_ROOT_ACCOUNTS_REQUEST = 'LIST_ROOT_ACCOUNTS_REQUEST',
  LIST_ROOT_ACCOUNTS_SUCCESS = 'LIST_ROOT_ACCOUNTS_SUCCESS',
  LIST_ROOT_ACCOUNTS_ERROR = 'LIST_ROOT_ACCOUNTS_ERROR',
  SELECT_ROOT_ACCOUNT = 'SELECT_ROOT_ACCOUNT',
  RESET_ROOT_ACCOUNTS_STATE = 'RESET_ROOT_ACCOUNTS_STATE',
  RESET_ERROR = 'RESET_ERROR',
}

export interface ListRootAccounts {
  type: ListRootAccountsActionTypes.LIST_ROOT_ACCOUNTS_REQUEST;
  currentPage?: number;
  filter?: ListAccountsFilter;
  searchString?: string;
}

export interface ListRootAccountsSuccess {
  type: ListRootAccountsActionTypes.LIST_ROOT_ACCOUNTS_SUCCESS;
  rootAccounts: Account[];
  total: number;
}

export interface ListRootAccountsError {
  type: ListRootAccountsActionTypes.LIST_ROOT_ACCOUNTS_ERROR;
  error: Error;
}

export interface SelectRootAccount {
  type: ListRootAccountsActionTypes.SELECT_ROOT_ACCOUNT;
  selectedRootAccount;
}

export interface ResetError {
  type: ListRootAccountsActionTypes.RESET_ERROR;
}

export interface ResetRootAccountsState {
  type: ListRootAccountsActionTypes.RESET_ROOT_ACCOUNTS_STATE;
}

export const ROOT_ACCOUNTS_FILTER: ListAccountsFilter = { type: AccountsType.ROOT };

export type ListRootAccountsAction =
  | ListRootAccounts
  | ListRootAccountsSuccess
  | ListRootAccountsError
  | SelectRootAccount
  | ResetRootAccountsState
  | ResetError;

export function listRootAccounts(
  searchString: string = '',
  currentPage: number = 1,
  filter: ListAccountsFilter = ROOT_ACCOUNTS_FILTER,
): ListRootAccounts {
  return {
    type: ListRootAccountsActionTypes.LIST_ROOT_ACCOUNTS_REQUEST,
    searchString,
    currentPage,
    filter,
  };
}

export function listRootAccountsSucceeded(total: number, rootAccounts: Account[]): ListRootAccountsSuccess {
  return {
    type: ListRootAccountsActionTypes.LIST_ROOT_ACCOUNTS_SUCCESS,
    rootAccounts,
    total,
  };
}

export function listRootAccountsFailed(error: Error): ListRootAccountsError {
  return {
    type: ListRootAccountsActionTypes.LIST_ROOT_ACCOUNTS_ERROR,
    error,
  };
}

export function selectRootAccount(selectedRootAccount): SelectRootAccount {
  return {
    type: ListRootAccountsActionTypes.SELECT_ROOT_ACCOUNT,
    selectedRootAccount,
  };
}

export function resetRootAccountsState() {
  return {
    type: ListRootAccountsActionTypes.RESET_ROOT_ACCOUNTS_STATE,
  };
}

export function resetError() {
  return {
    type: ListRootAccountsActionTypes.RESET_ERROR,
  };
}
