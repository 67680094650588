// Local dependencies
import { Category, UpdateCategory, UpdateCategoryFields } from '../../types';

export enum UpdateCategoryActionType {
  UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST',
  UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR',
  UPDATE_CATEGORY_FIELDS = 'UPDATE_CATEGORY_FIELDS',
  UPDATE_CATEGORY_RESET = 'UPDATE_CATEGORY_RESET',
  GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST',
  GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS',
  GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR',
  SHOW_CONFIRMATION_POPUP = 'SHOW_CONFIRMATION_POPUP',
  HIDE_CONFIRMATION_POPUP = 'HIDE_CONFIRMATION_POPUP',
}

export interface GetCategoryId {
  id: string;
}

export interface GetCategoryRequest {
  type: UpdateCategoryActionType.GET_CATEGORY_REQUEST;
  id: string;
}

export interface GetCategorySuccess {
  type: UpdateCategoryActionType.GET_CATEGORY_SUCCESS;
  category: Category;
}

export interface GetCategoryError {
  type: UpdateCategoryActionType.GET_CATEGORY_ERROR;
  error: Error;
}

export interface UpdateCategoryRequest {
  type: UpdateCategoryActionType.UPDATE_CATEGORY_REQUEST;
  category: UpdateCategory;
}

export interface UpdateCategorySuccess {
  type: UpdateCategoryActionType.UPDATE_CATEGORY_SUCCESS;
  category: Category;
}

export interface UpdateCategoryError {
  type: UpdateCategoryActionType.UPDATE_CATEGORY_ERROR;
  error: Error;
}

export interface UpdateCategoryFieldsInput {
  type: UpdateCategoryActionType.UPDATE_CATEGORY_FIELDS;
  updates: UpdateCategoryFields;
}

export interface UpdateCategoryReset {
  type: UpdateCategoryActionType.UPDATE_CATEGORY_RESET;
}

export interface ShowConfirmationPopup {
  type: UpdateCategoryActionType.SHOW_CONFIRMATION_POPUP;
}

export interface HideConfirmationPopup {
  type: UpdateCategoryActionType.HIDE_CONFIRMATION_POPUP;
}

export type UpdateCategoryActions =
  | UpdateCategoryRequest
  | UpdateCategorySuccess
  | UpdateCategoryError
  | UpdateCategoryFieldsInput
  | UpdateCategoryReset
  | GetCategoryRequest
  | GetCategorySuccess
  | GetCategoryError
  | ShowConfirmationPopup
  | HideConfirmationPopup;

export function updateCategoryRequest(category: UpdateCategory): UpdateCategoryRequest {
  return {
    type: UpdateCategoryActionType.UPDATE_CATEGORY_REQUEST,
    category,
  };
}

export function updateCategorySucceeded(category: Category): UpdateCategorySuccess {
  return {
    type: UpdateCategoryActionType.UPDATE_CATEGORY_SUCCESS,
    category,
  };
}

export function updateCategoryFailed(error: Error): UpdateCategoryError {
  return {
    type: UpdateCategoryActionType.UPDATE_CATEGORY_ERROR,
    error,
  };
}

export function updateUpdateCategoryFields(updates: UpdateCategoryFields): UpdateCategoryFieldsInput {
  return {
    type: UpdateCategoryActionType.UPDATE_CATEGORY_FIELDS,
    updates,
  };
}

export function updateCategoryReset(): UpdateCategoryReset {
  return {
    type: UpdateCategoryActionType.UPDATE_CATEGORY_RESET,
  };
}

export function getCategory(id: string): GetCategoryRequest {
  return {
    type: UpdateCategoryActionType.GET_CATEGORY_REQUEST,
    id,
  };
}

export function getCategorySucceeded(category: Category): GetCategorySuccess {
  return {
    type: UpdateCategoryActionType.GET_CATEGORY_SUCCESS,
    category,
  };
}

export function getCategoryFailed(error: Error): GetCategoryError {
  return {
    type: UpdateCategoryActionType.GET_CATEGORY_ERROR,
    error,
  };
}

export function showConfirmationPopupCategory(): ShowConfirmationPopup {
  return {
    type: UpdateCategoryActionType.SHOW_CONFIRMATION_POPUP,
  };
}

export function hideConfirmationPopupCategory(): HideConfirmationPopup {
  return {
    type: UpdateCategoryActionType.HIDE_CONFIRMATION_POPUP,
  };
}
