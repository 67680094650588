/* External dependencies */
import gql from 'graphql-tag';

export const listDistributionFeesQuery = gql`
  query listDistributionFees($input: ListDistributionFeesInput!) {
    listDistributionFees(input: $input) {
      total
      distributionFees {
        clientFeePercentage
        entityType
        id
        idTitle
        serviceId
        serviceRewardPercentage
        serviceTitle {
          en
          ru
          ky
        }
      }
    }
  }
`;
