// Local dependencies
import { ServiceFeesState } from './reducer';

export function validateServiceFees(state: ServiceFeesState) {
  const { errors, ...stateWithoutErrors } = state;
  let validationPassed = true;

  Object.keys(stateWithoutErrors).forEach((item) => {
    if (stateWithoutErrors[item] !== null)
      switch (item) {
        case 'flatFee': {
          const { amount } = stateWithoutErrors[item];

          if (!amount) {
            errors.flatFee = 'InvalidAmount';
            validationPassed = false;
          }

          break;
        }

        case 'flatFeePercent': {
          if (!stateWithoutErrors[item]) {
            errors.flatFeePercent = 'InvalidAmount';
            validationPassed = false;
          }

          break;
        }

        case 'dynamicFeeSteps': {
          const dynamicFeeSteps = stateWithoutErrors[item];

          const dynamicFeeStepsErrors = dynamicFeeSteps?.map((item) => {
            const error = {
              from: null,
              fee: null,
            };

            if (!item?.from?.amount) {
              error.from = 'InvalidAmount';
              validationPassed = false;
            }

            if (!item?.fee || (!item.fee?.flatFee?.amount && !item?.fee?.flatFeePercent)) {
              error.fee = 'InvalidAmount';
              validationPassed = false;
            }

            return error;
          });

          errors.dynamicFeeSteps = dynamicFeeStepsErrors;

          break;
        }
      }
  });

  return { ...state, errors, validationPassed };
}
